import { HTTP } from "@ionic-native/http";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonModal,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import React, { useState } from "react";
import { AppId, MainUrl } from "../../AppConfig";
import DefaultToolbar from "../shared/DefaultToolbar";
import PPOBWizard from "../../components/PPOB/PPOBWizard";
import { rupiah } from "../../helpers/currency";

export default function TourPaymentChoosePayment({
  UserData,
  InquiryData,
  ProductLabel,
  failedAlert,
  setShowLoading,
  GoPayment,
}: {
  UserData: any;
  GoPayment: any;
  InquiryData?: any;
  ProductLabel?: string;
  failedAlert: any;
  setShowLoading: any;
}) {
  const SubmitOrder = () => {
    setShowLoading(true);
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
      "Content-Type": "application/json",
    };
    const MyData = {
      billingReferenceID: InquiryData.billingReferenceID || "",
      productCode: InquiryData.productCode || "",
      msisdn: InquiryData.customerMSISDN || "",
      CustomerID: InquiryData.customerID || "",
      acctoken: UserData.accessToken,
    };
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("json");
      HTTP.setRequestTimeout(180);
      HTTP.post(MainUrl + "/ppob/order", MyData, MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            alert("Periksa Koneksi anda");
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          SubmitOrderSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    } else {
      fetch(MainUrl + "/ppob/order", {
        method: "POST",
        body: JSON.stringify(MyData),
        headers: MyHeaders,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            failedAlert(r.statusText);

            return r.json();
          }
        })
        .then((res) => {
          SubmitOrderSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const SubmitOrderSuccess = async (res: any) => {
    if (res.StatusCode == 200 && res.Data) {
      GoPayment(res.Data);
    } else {
      failedAlert(res.ErrorMessage || `Order Gagal`);
    }
  };
  return (
    <IonPage>
      <IonHeader translucent>
        <DefaultToolbar
          title={ProductLabel}
          color="primary"
          backButtonRoute="/main/index"
        />
        <PPOBWizard WizardIndex={1}></PPOBWizard>
      </IonHeader>
      <IonContent fullscreen={true} className="ion-padding">
        <IonText>Rincian Transaksi {ProductLabel}</IonText>
        <IonGrid className="b-primary">
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">ID Billing</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.billing) || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">ID Pelanggan</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.customerID) || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">Nama Pelanggan</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.customerName) || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">No. Telepon</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.customerMSISDN) || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">Produk</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.productCode) || ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonText color="medium">Periode Pembayaran</IonText>
            </IonCol>
            <IonCol size="7">
              <IonText color="dark">
                : {(InquiryData && InquiryData.period) || ""}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonCard className="ion-no-margin ion-no-padding footerPrice">
          <IonGrid>
            <IonRow class="priceCollapse">
              <IonCol size="6">
                <IonText color="medium">Harga yang harus dibayar</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText>
                  <h6 className="ion-no-margin ion-no-padding">
                    {InquiryData && InquiryData.payment
                      ? rupiah(InquiryData.payment || 0)
                      : "Rp 0"}
                  </h6>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="text-transform-none"
                  size="large"
                  expand="block"
                  onClick={() => {
                    SubmitOrder();
                  }}
                >
                  Bayar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonFooter>
    </IonPage>
  );
}
