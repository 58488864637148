import { HTTP } from "@ionic-native/http";
import { IonButton, IonCol, IonLabel, IonRow, isPlatform } from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";

interface AirlineOrderAddOnCheckProps {}
interface OwnProps {
  setAddOnResult: any;
  AddOnResult: any;
  setBookingPaxData: any;
  UseLionOrBatik: any;
}
interface StateProps {
  AirlineOriginRoutes?: any;
  AirlineBookingDestinationDetail?: string;
  ABDB?: any;
  AOPD?: any;
  UserData?: any;
}
interface DispatchProps {}
interface AirlineOrderAddOnCheckProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const AirlineOrderAddOnCheck: React.FC<AirlineOrderAddOnCheckProps> = ({
  AirlineOriginRoutes,
  AirlineBookingDestinationDetail,
  AddOnResult,
  setAddOnResult,
  setBookingPaxData,
  UseLionOrBatik,
  AOPD,
  ABDB,
  // AOPB,
  UserData,
}) => {
  const [FetchProgress, setFetchProgress] = useState(false);

  const AOPDCheck = () => {
    let count = 0;
    AOPD.forEach((i) => {
      if (i.PaxFirstName !== "") {
        count = count + 1;
      }
    });
    if (count >= AOPD.length) {
      return true;
    } else {
      return false;
    }
  };
  const CreateBookingData = (v) => {
    let PaxDetailArray = new Array();
    AOPD.forEach((PaxDetail, indexItem) => {
      const PaxType =
        PaxDetail.PaxType === "Adult"
          ? 0
          : PaxDetail.PaxType === "Child"
          ? 1
          : PaxDetail.PaxType === "Infant"
          ? 2
          : 0;
      const AddonArray = new Array();
      if(v.baggage && v.baggage.length>0){
        v.baggage.forEach(bItem => {
          AddonArray.push({
            aoOrigin: bItem.origin,
            aoDestination: bItem.destination,
            baggageDetailString: null,
            baggageString: null,
            baggagePrice: null,
            meals: null,
            mealsDetail: null,
            mealsPrice: null,
            seat: null,
            seatPrice: null,
          });
        });
      }
      // if (
      //   ABDB &&
      //   ABDB.AirlineFlightDeparture &&
      //   ABDB.AirlineFlightDeparture.segment
      // ) {
      //     ABDB.AirlineFlightDeparture.segment.forEach((segment) => {
      //       AddonArray.push({
      //         aoOrigin: segment.flightDetail[0].fdOrigin,
      //         aoDestination: segment.flightDetail[0].fdDestination,
      //         baggageDetailString: null,
      //         baggageString: null,
      //         baggagePrice: null,
      //         meals: null,
      //         mealsDetail: null,
      //         mealsPrice: null,
      //         seat: null,
      //         seatPrice: null,
      //       });
      //     });
      // }
      // if (
      //   ABDB &&
      //   ABDB.AirlineFlightArrival &&
      //   ABDB.AirlineFlightArrival.segment
      // ) {
      //   if(PaxType < 2){
      //     ABDB.AirlineFlightArrival.segment.forEach((segment) => {
      //       AddonArray.push({
      //         aoOrigin: segment.flightDetail[0].fdOrigin,
      //         aoDestination: segment.flightDetail[0].fdDestination,
      //         baggageDetailString: null,
      //         baggageString: null,
      //         baggagePrice: null,
      //         meals: null,
      //         mealsDetail: null,
      //         mealsPrice: null,
      //         seat: null,
      //         seatPrice: null,
      //       });
      //     });
      //   }else{
      //     ABDB.AirlineFlightArrival.segment.forEach((segment) => {
      //       AddonArray.push({
      //         aoOrigin: segment.flightDetail[0].fdOrigin,
      //         aoDestination: segment.flightDetail[0].fdDestination,
      //       });
      //     });
      //   }

      // }
      // let PaxAddOnsDeparture =
      //   PaxType === 2
      //     ? { baggageDetailString: "", baggageString: "" }
      //     : {
      //         aoOrigin: ABDB.PreBookingData.SchDeparts[0].schOrigin,
      //         aoDestination: ABDB.PreBookingData.SchDeparts[0].schDestination,
      //         baggageDetailString:
      //           (AOBS[0][indexItem] && AOBS[0][indexItem].desc) || "",
      //         baggageString:
      //           (AOBS[0][indexItem] && AOBS[0][indexItem].code) || "",
      //         baggagePrice:
      //           (AOBS[0][indexItem] && AOBS[0][indexItem].fare) || 0,
      //         meals: null,
      //         mealsDetail: null,
      //         mealsPrice: null,
      //       };
      // let PaxAddOnsArrival = ABDB.PreBookingData.SchReturns
      //   ? PaxType === 2
      //     ? { baggageDetailString: "", baggageString: "" }
      //     : {
      //         aoOrigin: ABDB.PreBookingData.SchReturns[0].schOrigin,
      //         aoDestination: ABDB.PreBookingData.SchReturns[0].schDestination,
      //         baggageDetailString:
      //           (AOBS[1][indexItem] && AOBS[1][indexItem].desc) || "",
      //         baggageString:
      //           (AOBS[1][indexItem] && AOBS[1][indexItem].code) || "",
      //         baggagePrice:
      //           (AOBS[1][indexItem] && AOBS[1][indexItem].fare) || 0,
      //         meals: null,
      //         mealsDetail: null,
      //         mealsPrice: null,
      //       }
      //   : null;
      // let PaxAddOns = ABDB.PreBookingData.SchReturns
      //   ? [PaxAddOnsDeparture, PaxAddOnsArrival]
      //   : [PaxAddOnsDeparture];
      // // if (AOBS[0][indexItem]) {
      // //   PaxAddOns[0] = AOBS[0][indexItem]||'asd';
      // //   if (AOBS[1][indexItem]) {
      // //     PaxAddOns.push(AOBS[1][indexItem]||{});
      // //   }
      // // }
      const tempdata = {
        IDNumber: null,
        addOns: AddonArray,
        batikMilesNo: null,
        birthCountry: PaxDetail.PaxBirthCountry,
        birthDate: new Date(PaxDetail.PaxBirthDate).toISOString(),
        firstName: PaxDetail.PaxFirstName,
        gender: PaxDetail.PaxGender,
        lastName: PaxDetail.PaxLastName,
        nationality: PaxDetail.PaxNationality,
        parent: PaxDetail.PaxParent,
        doctype:
          !UseLionOrBatik || PaxDetail.PaxDoctype === ""
            ? null
            : PaxDetail.PaxDoctype,
        passportExpiredDate:
          PaxDetail.PaxPassportExpiredDate === ""
            ? null
            : PaxDetail.PaxPassportExpiredDate,
        passportIssuedCountry:
          PaxDetail.PaxPassportIssuedCountry === ""
            ? null
            : PaxDetail.PaxPassportIssuedCountry,
        passportIssuedDate:
          PaxDetail.PaxPassportIssuedDate === ""
            ? null
            : PaxDetail.PaxPassportIssuedDate,
        passportNumber:
          PaxDetail.PaxPassportNumber === ""
            ? null
            : PaxDetail.PaxPassportNumber,
        garudaFrequentFlyer:
          PaxDetail.PaxGarudaFrequentFlyer === ""
            ? null
            : PaxDetail.PaxGarudaFrequentFlyer,
        title: PaxDetail.PaxTitle,
        type: PaxType,
      };
      PaxDetailArray.push(tempdata);
    });
    setBookingPaxData(PaxDetailArray);
  };
  const CheckAddOnFetch = () => {
    setFetchProgress(true);
    //  setAddOnChecked(true);
    const AOOP = JSON.parse(localStorage.AirlineOrderOrderPerson);
    let PaxDetailArray = new Array();
    //  if (showLoading) {
    //    return;
    //  }
    //  setShowLoading(true);
    AOPD.forEach((PaxDetail) => {
      const PaxType =
        PaxDetail.PaxType === "Adult"
          ? 0
          : PaxDetail.PaxType === "Child"
          ? 1
          : PaxDetail.PaxType === "Infant"
          ? 2
          : 0;
      const tempdata = {
        IDNumber: null,
        addOns: null,
        batikMilesNo: "",
        birthCountry: PaxDetail.PaxBirthCountry,
        birthDate: new Date(PaxDetail.PaxBirthDate).toISOString(),
        firstName: PaxDetail.PaxFirstName,
        garudaFrequentFlyer: PaxDetail.PaxGarudaFrequentFlyer,
        gender: PaxDetail.PaxGender,
        lastName: PaxDetail.PaxLastName,
        nationality: PaxDetail.PaxNationality,
        parent: PaxDetail.PaxParent,
        doctype: PaxDetail.PaxDoctype,
        passportExpiredDate: PaxDetail.PaxPassportExpiredDate,
        passportIssuedCountry: PaxDetail.PaxPassportIssuedCountry,
        passportIssuedDate: PaxDetail.PaxPassportIssuedDate,
        passportNumber: PaxDetail.PaxPassportNumber,
        title: PaxDetail.PaxTitle,
        type: PaxType,
      };
      PaxDetailArray.push(tempdata);
    });
    var MyHeaders = {
      appid: AppId,
      "Content-Type": "application/json",
      RequestVerificationToken: UserData.requestVerificationToken,
    };
    var MyData = JSON.stringify({
      PaxDetail: PaxDetailArray,
      contactTitle: AOOP.OrderPersonTitel,
      contactRemainingPhoneNo: AOOP.OrderPersonPhoneNumber,
      contactCountryCodePhone: AOOP.OrderPersonNationCode,
      contactAreaCodePhone: null,
      contactFirstName: AOOP.OrderPersonFirstName,
      contactLastName: AOOP.OrderPersonLastName,
      contactEmail: AOOP.OrderPersonEmail,
      requestInsurance: false,
      XTKN: ABDB.PreBookingData.XTKN,
      accToken: UserData.accessToken,
    });
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("json");
      HTTP.post(MainUrl + "Airline/CheckAddOn", JSON.parse(MyData), MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            alert("Periksa Koneksi anda");
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          CheckAddOnSuccess(res);
        })
        .catch((err) => {
          //  failedAlert(JSON.stringify(err));
        });
    } else {
      fetch(MainUrl + "Airline/CheckAddOn", {
        method: "POST",
        headers: MyHeaders,
        body: MyData,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            //  failedAlert("Periksa Koneksi Anda");
          }
        })
        .then((res) => {
          CheckAddOnSuccess(res);
        })
        .catch((err) => {
          //  failedAlert(err);
        });
    }
  };
  const CheckAddOnSuccess = (res: any) => {
    setFetchProgress(false);
    if (res.StatusCode && res.StatusCode === 200 && res.Data) {
      setAddOnResult(res.Data);
      CreateBookingData(res.Data);
    } else {
      setAddOnResult(null);
    }
  };
  return (
    <IonButton
      expand="full"
      size="large"
      onClick={() => {
        if (AOPDCheck()) {
          CheckAddOnFetch();
        } else {
          alert("Lengkapi data penumpang terlebih dahulu");
        }
      }}
      disabled={FetchProgress}
      hidden={AddOnResult !== undefined}
      className="text-transform-none ion-margin"
      // disabled
    >
      {FetchProgress ? "Mohon tunggu" : "Lanjutkan"}
    </IonButton>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineOriginRoutes: state.airline.AirlineOriginRoutes,
    AirlineBookingDestinationDetail:
      state.airline.AirlineBookingDestinationDetail,
    ABDB: state.airline.AirlineBookingDataBundle,
    AOPD: state.airline.AirlineOrderPassengersData,
    UserData: selectors.getUserData(state),
  }),
  // mapDispatchToProps: {
  //   setAirlineAirport,
  // },
  component: React.memo(withRouter(AirlineOrderAddOnCheck)),
});
