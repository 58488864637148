import { IonAlert, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import { BluetoothSerial  } from '@awesome-cordova-plugins/bluetooth-serial';
import EscPosEncoder from "esc-pos-encoder-ionic";
import {commands} from "../../helpers/printer-commands";
import { stringDateHoursConvert } from "../../helpers/datetime";
import { rupiah } from "../../helpers/currency";

interface OwnProps {
  THD: any;
}
interface StateProps {
  pairedPrintDevice?: string;
}
interface DispatchProps {}
interface PPOBTransactionManageProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const PPOBTransactionManage: React.FC<PPOBTransactionManageProps> = ({
  history,
  THD,
  pairedPrintDevice
}) => {
  const [bluetoothEnabled, setbluetoothEnabled] = useState(false);
  const [pairedDeviceId, setpairedDeviceId] = useState<string>(pairedPrintDevice||"");
  const [loaded, setLoaded] = useState(false);
  const [pairedDevices, setPairedDevices] = useState<any>(undefined);
  const [isPrint, setIsPrint] = useState(false);

  const TurnOnBluetooth = () => {
    BluetoothSerial.enable().then(success => {
      isBluetoothEnable()
    }, error => {
      alert(false)
    })
  };
  const isBluetoothEnable = () => {
    BluetoothSerial.isEnabled().then(success => {
      setbluetoothEnabled(true)
      listPairedDevices()
    }, error => {
      setbluetoothEnabled(false)
      TurnOnBluetooth()
    });
  };

  const listPairedDevices = () => {
    BluetoothSerial.list().then(success => {
      setPairedDevices(success)
      setLoaded(true)
    }, error => {
      setbluetoothEnabled(false)
    })
  };

  // const printTest = () => {
  //   if(bluetoothEnabled){

  //     const encoder = new EscPosEncoder();
  //     const result = encoder.initialize();
      
  //     result
  //       .codepage('cp936')
  //       .align('center')
  //       .raw(commands.TEXT_FORMAT.TXT_4SQUARE)
  //       // .image(img, 300, 300, 'atkinson')
  //       // .line(imagePath)
  //       .raw(commands.TEXT_FORMAT.TXT_NORMAL)
  //       .newline()
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Billing Reff")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(THD.billingReferenceID || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("ID Pelanggan")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(THD.customerID || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Kode Produk")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(THD.productCode || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Nama Pelanggan")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(THD.customerName || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Tanggal Transaksi")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(stringDateHoursConvert(
  //         THD.transactionDate || ""
  //       ) || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Nomor Telp")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text(THD.customerMSISDN || "")
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Produk")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
  //       .newline()
  //       .text((THD.productGroup || "")("- ")(THD.productCode || ""))
  //       .raw(commands.TEXT_FORMAT.TXT_NORMAL)
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Total Tagihan")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
  //       .text(rupiah(THD.payment || "") || "")
  //       .raw(commands.TEXT_FORMAT.TXT_NORMAL)
  //       .newline()
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
  //       .text("Harga Bayar")
  //       .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
  //       .newline()
  //       .text("Trx 2|HSDI5 5000 berhasil ditambahkan. TrxID: 030646113")
  //       .newline()
  //       .newline()
  //       .size("small")
  //       .text("Tanggal Cetak : 13 Oktober 2022 14.45")
  //       .newline()
  //       .newline()
  //       .newline()
  
        
  //       BluetoothSerial.connect(pairedDeviceId).subscribe(success => {
  //         BluetoothSerial.write(result.encode()).then(success => {
  //           alert("Succesfully Print text: "+JSON.stringify(success))
  //           BluetoothSerial.disconnect()
  //         }, error => {
  //           alert("Error: Print to Device")
  //         })
  //       }, error => {
  //         alert("Error: Connecting to Device")
  //       })
  //   }
  // }
  useEffect(()=>{
    const success = ()=>{
      alert('success');
    }
    const failure = ()=>{
      alert('failure');
    }
    TurnOnBluetooth()

    if(isPrint){
      setIsPrint(false)
      const encoder = new EscPosEncoder();
      const result = encoder.initialize();
      
      result
        .codepage('cp936')
        .align('center')
        .raw(commands.TEXT_FORMAT.TXT_4SQUARE)
        // .image(img, 300, 300, 'atkinson')
        // .line(imagePath)
        .raw(commands.TEXT_FORMAT.TXT_NORMAL)
        .newline()
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Billing Reff")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(THD.billingReferenceID || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("ID Pelanggan")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(THD.customerID || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Kode Produk")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(THD.productCode || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Nama Pelanggan")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(THD.customerName || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Tanggal Transaksi")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(stringDateHoursConvert(
          THD.transactionDate || ""
        ) || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Nomor Telp")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text(THD.customerMSISDN || "")
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Produk")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
        .newline()
        .text((THD.productGroup || "")("- ")(THD.productCode || ""))
        .raw(commands.TEXT_FORMAT.TXT_NORMAL)
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Total Tagihan")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
        .text(rupiah(THD.payment || "") || "")
        .raw(commands.TEXT_FORMAT.TXT_NORMAL)
        .newline()
        .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
        .text("Harga Bayar")
        .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
        .newline()
        .text("Trx 2|HSDI5 5000 berhasil ditambahkan. TrxID: 030646113")
        .newline()
        .newline()
        .size("small")
        .text("Tanggal Cetak : 13 Oktober 2022 14.45")
        .newline()
        .newline()
        .newline()
  
        
        BluetoothSerial.connect(pairedDeviceId).subscribe(success => {
          BluetoothSerial.write(result.encode()).then(success => {
            alert("Succesfully Print text: "+JSON.stringify(success))
            BluetoothSerial.disconnect()
          }, error => {
            alert("Error: Print to Device")
          })
        }, error => {
          alert("Error: Connecting to Device")
        })
    }
  },[BluetoothSerial])
  return (
    <div
      hidden={
        THD.status === "success" ||
        (new Date(THD.transactionDate) === new Date() &&
          THD.status === "request")
          ? false
          : true
      }
    >
      <p>
        <br />
      </p>
      <IonText className="ion-margin">
        <small>
          <b>Atur Pesanan</b>
        </small>
      </IonText>
      <IonGrid className="ion-margin-top white-bg">
        <IonRow
          className="ion-align-items-center"
          hidden={
            new Date(THD.transactionDate) === new Date() &&
            THD.status === "request"
              ? false
              : true
          }
        >
          <IonCol>
            <IonText color="dark">
              <small>Lanjutkan pembayaran</small>
            </IonText>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <IonIcon
              icon={chevronForwardOutline}
              color="medium"
              size="large"
            ></IonIcon>
          </IonCol>
        </IonRow>
        <IonRow
          className="ion-align-items-center"
          hidden={THD.status !== "success"}
        >
          <IonCol>
            <IonText color="dark">
              <small>Cetak</small>
            </IonText>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <IonIcon
              icon={chevronForwardOutline}
              color="medium"
              size="large"
            ></IonIcon>
          </IonCol>
        </IonRow>
        <IonRow
          className="ion-align-items-center"
          hidden={THD.status !== "success"}
          onClick={() => setIsPrint(true)}
        >
          <IonCol>
            <IonText color="dark">
              <small>Cetak Kecil</small>
            </IonText>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <IonIcon
              icon={chevronForwardOutline}
              color="medium"
              size="large"
            ></IonIcon>
          </IonCol>
        </IonRow>
        {
            <IonAlert
              isOpen={!bluetoothEnabled}
              onDidDismiss={() => BluetoothSerial.enable()}
              header="Bluetooth belum aktif"
              message="Aktifkan bluetooth untuk terhubung dengan printer"
              buttons={["Baik"]}
            />
          }
          {/* {
            loaded &&
            <IonAlert
              isOpen={loaded}
              onDidDismiss={() => setLoaded(false)}
              header="Daftar Bluetooth"
              message={JSON.stringify(pairedDevices)}
              buttons={["OK"]}
            />
          } */}
      </IonGrid>
    </div>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    pairedPrintDevice: state.data.pairedPrintDevice,
  }),
  component: React.memo(withRouter(PPOBTransactionManage)),
});
