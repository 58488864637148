export const getHistoryTransactionStatusName = (
  status: string,
  type: string
) => {
  if (type.toLowerCase() === "tour") {
    return status; //tourStatusName(status.toLowerCase());
  } else if (type.toLowerCase() === "flightticket") {
    return status;
  } else if (type.toLowerCase() === "hotelbooking") {
    return status;
  } else if (type.toLowerCase() === "ordertopup") {
    return status;
  } else if (type.toLowerCase() === "ppobvoucher") {
    return status;
  } else {
    return "Tidak Diketahui";
  }
};
export const getHistoryTransactionStatusColor = (
  status: string,
  type: string
) => {
  if (type.toLowerCase() === "tour") {
    return tourStatusColor(status.toLowerCase());
  } else if (type.toLowerCase() === "flightticket") {
    return flightticketStatusColor(status.toLowerCase());
  } else if (type.toLowerCase() === "hotelbooking") {
    return hotelbookingStatusColor(status.toLowerCase());
  } else if (type.toLowerCase() === "ordertopup") {
    return ordertopupStatusColor(status.toLowerCase());
  } else if (type.toLowerCase() === "ppobvoucher") {
    return ppobvoucherStatusColor(status.toLowerCase());
  } else {
    return "danger";
  }
};
export const getHistoryTransactionCtaLabel = (status: string, type: string) => {
  if (type.toLowerCase() === "tour") {
    return tourCtaLabel(status.toLowerCase());
  } else if (type.toLowerCase() === "flightticket") {
    return tourCtaLabel(status.toLowerCase());
  } else {
    return "";
  }
  
};
export const getHistoryTransactionCtaTarget = (
  status: string,
  type: string
) => {
  if (type.toLowerCase() === "tour") {
    return tourCtaTarget(status.toLowerCase());
  } else if (type.toLowerCase() === "flightticket") {
    return tourCtaTarget(status.toLowerCase());
  } else {
    return "";
  }
};
export const getHistoryTransactionIcon = (type: string) => {
  if (type.toLowerCase() === "flightticket") {
    return "assets/img/Services/Airplane.svg";
  } else if (type.toLowerCase() === "bus") {
    return "assets/img/Services/Bus.svg";
  } else if (type.toLowerCase() === "hotelbooking") {
    return "assets/img/Services/Hotel.svg";
  } else if (
    type.toLowerCase() === "ordertopup" ||
    type.toLowerCase() === "ppobvoucher"
  ) {
    return "assets/img/Services/Phone.svg";
  } else if (type.toLowerCase() === "ship") {
    return "assets/img/Services/Ship.svg";
  } else if (type.toLowerCase() === "tour") {
    return "assets/img/Services/Tour.svg";
  } else {
    return "assets/icon/OthersIcon.svg";
  }
};

export const createTransactionDetailUrl = (PC: string, TID: string) => {
  if (PC.toLowerCase() === "flightticket") {
    return "/transactionHistoryDetailFlightticket/" + TID;
  } else if (PC.toLowerCase() === "bus") {
    return "/transactionHistoryDetailBus/" + TID;
  } else if (PC.toLowerCase() === "hotelbooking") {
    return "/transactionHistoryDetailHotel/" + TID;
  } else if (PC.toLowerCase() === "ordertopup") {
    return "/transactionHistoryDetailTopup/" + TID;
  } else if (PC.toLowerCase() === "ppobvoucher") {
    return "/transactionHistoryDetailPPOB/" + TID;
  } else if (PC.toLowerCase() === "ship") {
    return "/transactionHistoryDetailShip/" + TID;
  } else if (PC.toLowerCase() === "tour") {
    return "/transactionHistoryDetail/" + TID;
  } else {
    return null;
  }
};

// Tour Status Name
export const tourStatusName = (status: string) => {
  if (status === "booked") {
    return "Pesanan Baru";
  } else if (status === "processed") {
    return "Pesanan Diterima";
  } else if (status === "waitingpayment") {
    return "Menunggu Pembayaran";
  } else if (status === "dpreceived") {
    return "DP Diterima";
  } else if (status === "paid") {
    return "Lunas";
  } else if (status === "issued") {
    return "Issued ";
  } else if (status === "completed") {
    return "Pesanan Selesai";
  } else if (status === "cancelled") {
    return "Dibatalkan";
  } else if (status === "partnercancelled") {
    return "Dibatalkan Mitra";
  } else if (status === "consumercancelled") {
    return "Dibatalkan Konsumen";
  } else if (status === "timelimitcanceled") {
    return "Pesanan Kadaluarsa";
  } else if (status === "allotmentcanceled") {
    return "Dibatalkan Allotment";
  } else {
    return "Tidak Diketahui";
  }
};

// Tour Status Color
export const tourStatusColor = (status: string) => {
  if (status === "booked" || status === "sedang berlangsung") {
    return "warning";
  } else if (status === "processed") {
    return "warning";
  } else if (status === "waitingpayment") {
    return "warning";
  } else if (status === "dpreceived" || status === "berhasil") {
    return "success";
  } else if (status === "paid") {
    return "success";
  } else if (status === "issued") {
    return "success";
  } else if (status === "completed") {
    return "success";
  } else if (status === "cancelled") {
    return "danger";
  } else if (status === "partnercancelled") {
    return "danger";
  } else if (status === "consumercancelled") {
    return "danger";
  } else if (status === "timelimitcanceled") {
    return "danger";
  } else if (status === "allotmentcanceled" || status === "tidak berhasil") {
    return "danger";
  } else {
    return "danger";
  }
};
// Topup Status Color
export const ordertopupStatusColor = (status: string) => {
  if (
    status === "failed" ||
    status === "request_failed" ||
    status === "refund" ||
    status === "transaction_failed" ||
    status === "tidak berhasil"
  ) {
    return "danger";
  } else if (
    status === "pending" ||
    status === "request" ||
    status === "sedang berlangsung"
  ) {
    return "warning";
  } else if (status === "success" || status === "berhasil") {
    return "success";
  } else {
    return "dark";
  }
};
export const ppobvoucherStatusColor = (status: string) => {
  if (
    status === "failed" ||
    status === "error" ||
    status === "refund" ||
    status === "tidak berhasil"
  ) {
    return "danger";
  } else if (
    status === "pending" ||
    status === "request" ||
    status === "sedang berlangsung"
  ) {
    return "warning";
  } else if (status === "success" || status === "berhasil") {
    return "success";
  } else {
    return "dark";
  }
};
export const flightticketStatusColor = (status: string) => {
  if (status === "cancelled" || status === "tidak berhasil") {
    return "danger";
  } else if (
    status === "booked" ||
    status === "hold" ||
    status === "review_request" ||
    status === "processed" ||
    status === "sedang berlangsung"
  ) {
    return "warning";
  } else if (
    status === "issued" ||
    status === "ticketed" ||
    status === "berhasil"
  ) {
    return "success";
  } else {
    return "dark";
  }
};
export const hotelbookingStatusColor = (status: string) => {
  if (
    status === "notconfirmed" ||
    status === "timelimitcanceled" ||
    status === "tidak berhasil"
  ) {
    return "danger";
  } else if (
    status === "booked" ||
    status === "requestprocessed" ||
    status === "review_request" ||
    status === "processed" ||
    status === "sedang berlangsung"
  ) {
    return "warning";
  } else if (status === "issued" || status === "berhasil") {
    return "success";
  } else {
    return "dark";
  }
};
// Tour CTA Label
export const tourCtaLabel = (status: string) => {
  if (status === "booked") {
    return "Menunggu Konfirmasi";
  } else if (status === "processed") {
    return "Lanjutkan Pembayaran";
  } else if (status === "waitingpayment") {
    return "Lanjutkan Pembayaran";
  } else if (status === "dpreceived") {
    return "Lanjutkan Pelunasan";
  } else if (status === "paid") {
    return "Lihat E-Voucher ";
  } else if (status === "issued") {
    return "Lihat E-Voucher ";
  } else if (status === "completed") {
    return "Kirim Bukti Pembayaran";
  } else if (status === "cancelled") {
    return "Transaksi Kembali";
  } else if (status === "partnercancelled") {
    return "Transaksi Kembali";
  } else if (status === "consumercancelled") {
    return "Transaksi Kembali";
  } else if (status === "timelimitcanceled") {
    return "Transaksi Kembali";
  } else if (status === "allotmentcanceled") {
    return "Transaksi Kembali";
  } else {
    return "";
  }
};
// Tour CTA Target
export const tourCtaTarget = (status: string) => {
  if (status === "booked") {
    return "";
  } else if (status === "processed") {
    return "/tourPayment/regular";
  } else if (status === "waitingpayment") {
    return "/tourPayment/";
  } else if (status === "dpreceived") {
    return "/tourPayment";
  } else if (status === "paid") {
    return "/evoucher";
  } else if (status === "issued") {
    return "/evoucher";
  } else if (status === "completed") {
    return "/evoucher";
  } else if (status === "cancelled") {
    return "/main/index";
  } else if (status === "partnercancelled") {
    return "/main/index";
  } else if (status === "consumercancelled") {
    return "/main/index";
  } else if (status === "timelimitcanceled") {
    return "/main/index";
  } else if (status === "allotmentcanceled") {
    return "/main/index";
  } else {
    return "";
  }
};
