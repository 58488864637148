export const getProviderName = (number: string) => {
  if (
    number === "0811" ||
    number === "0812" ||
    number === "0813" ||
    number === "0821" ||
    number === "0822"
  ) {
    number = "SIMPATI";
  } else if (
    number === "0823" ||
    number === "0851" ||
    number === "0852" ||
    number === "0853"
  ) {
    number = "AS";
  } else if (
    number === "0814" ||
    number === "0855" ||
    number === "0856" ||
    number === "0857"
  ) {
    number = "IM3";
  } else if (number === "0815" || number === "0816" || number === "0858") {
    number = "MENTARI";
  } else if (
    number === "0817" ||
    number === "0818" ||
    number === "0819" ||
    number === "0859" ||
    number === "0877" ||
    number === "0878"
  ) {
    number = "XL";
  } else if (
    number === "0831" ||
    number === "0832" ||
    number === "0833" ||
    number === "0838"
  ) {
    number = "AXIS";
  } else if (
    number === "0895" ||
    number === "0896" ||
    number === "0897" ||
    number === "0898" ||
    number === "0899"
  ) {
    number = "THREE";
  } else if (
    number === "0881" ||
    number === "0882" ||
    number === "0883" ||
    number === "0884" ||
    number === "0885" ||
    number === "0886" ||
    number === "0887" ||
    number === "0888" ||
    number === "0889"
  ) {
    number = "SMARTFREN";
  } else {
    number = "notfound";
  }
  return number;
};
