import React, { useState } from "react";

import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonModal,
  IonContent,
  IonItem,
} from "@ionic/react";
import { calendarOutline, timerOutline } from "ionicons/icons";
import ModalTourDetail from "../components/ModalTourDetail";
import { ImageBasePath } from "../AppConfig";
import { stringDateConvert } from "../helpers/datetime";
import DefaultToolbar from "./shared/DefaultToolbar";

export default function Tourwizard2({
  WizardIndex,
  PaymentData,
}: {
  WizardIndex: number;
  PaymentData: any;
}) {
  const [showModalTourDetail, setShowModalTourDetail] = useState(false);
  const disableWizard = "ion-text-center col-disable";
  const activeWizard = "ion-text-center";
  console.log(PaymentData);

  if (PaymentData) {
    return (
      <div>
        {/* Wizard Header */}
        <IonGrid className="wizardHeader">
          <IonRow>
            <IonCol
              className={WizardIndex === 1 ? activeWizard : disableWizard}
            >
              <IonText color="light">
                <span>{WizardIndex === 1 ? "✓" : "1"} </span> Pesan
              </IonText>
            </IonCol>
            <IonCol size="1" className="ion-text-center">
              <IonText color="light">--</IonText>
            </IonCol>
            <IonCol
              className={WizardIndex === 2 ? activeWizard : disableWizard}
            >
              <IonText color="light">
                <span>{WizardIndex === 2 ? "✓" : "2"}</span> Bayar
              </IonText>
            </IonCol>
            <IonCol size="1" className="ion-text-center">
              <IonText color="light">--</IonText>
            </IonCol>
            <IonCol
              className={WizardIndex === 3 ? activeWizard : disableWizard}
            >
              <IonText color="light">
                <span>{WizardIndex === 3 ? "✓" : "3"}</span> Selesai
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-top">
            <IonCol>
              <IonCard className="product-card ion-no-margin">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonText>
                        {PaymentData.tourBookingDetailModels &&
                        PaymentData.tourBookingDetailModels.TourBookingStartDate
                          ? stringDateConvert(
                              PaymentData.tourBookingDetailModels
                                .TourBookingStartDate
                            )
                          : ""}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="3">
                      <img
                        src={
                          (ImageBasePath +
                            PaymentData.tourBookingProductModels &&
                            PaymentData.tourBookingProductModels.Image) ||
                          ""
                        }
                        alt=""
                      />
                    </IonCol>
                    <IonCol>
                      <IonText>
                        <p className="ion-no-margin">
                          <small>
                            <b>{PaymentData.productname || ""}</b>
                          </small>
                        </p>
                      </IonText>
                      <IonText>
                        <p className="ion-no-margin">
                          <small>
                            {PaymentData.tourBookingGuestList.length || "0"} Pax
                          </small>
                        </p>
                      </IonText>
                      <IonText>
                        <a
                          className="ion-no-margin"
                          onClick={() => {
                            setShowModalTourDetail(true);
                          }}
                        >
                          <small>Informasi Produk</small>
                        </a>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div>
                        <IonText color="medium">
                          <h6>
                            <IonIcon icon={calendarOutline}></IonIcon> Hanya
                            berlaku pada tanggal keberangkatan yang dipilih
                          </h6>
                        </IonText>
                        <IonText color="medium">
                          <h6>
                            <IonIcon icon={timerOutline}></IonIcon> Reservasi
                            diharuskan min. 7 hari sebelum hari keberangkatan
                          </h6>{" "}
                        </IonText>{" "}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal isOpen={showModalTourDetail}>
          <IonContent>
            <DefaultToolbar
              title="Informasi Produk"
              color="primary"
              backButtonRoute={()=>setShowModalTourDetail(false)}
            />
            <img
              src={
                (ImageBasePath +
                  PaymentData.tourBookingProductModels &&
                  PaymentData.tourBookingProductModels.Image) ||
                ""
              }
              width="100%"
              alt=""
            />

            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonText>
                  {PaymentData.productname || ""}
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonCol size="12">
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Berangkat</small>
                        </IonText>
                        <IonText>
                          <h6>{PaymentData.tourBookingDetailModels &&
                        PaymentData.tourBookingDetailModels.TourBookingStartDate
                          ? stringDateConvert(
                              PaymentData.tourBookingDetailModels
                                .TourBookingStartDate
                            )
                          : ""}</h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Durasi</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingProductModels.TourBookingDuration || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem hidden={PaymentData.tourBookingProductModels.TourBookingAirline?false:true}>
                      <div>
                        <IonText color="medium">
                          <small>Maskapai</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingProductModels.TourBookingAirline || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Lokasi</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingDetailModels.TourBookingCountry || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Tipe</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingProductModels.TourBookingType || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Pengalaman</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingProductModels.TourBookingExperience || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                    <IonItem>
                      <div>
                        <IonText color="medium">
                          <small>Pemandu</small>
                        </IonText>
                        <IonText>
                          <h6>
                            {PaymentData.tourBookingProductModels.TourBookingGuide || ""}
                          </h6>
                        </IonText>
                      </div>
                    </IonItem>
                  </IonCol>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </div>
    );
  } else {
    return <div></div>;
  }
}
