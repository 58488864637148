import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonLoading,
  IonModal,
  IonRippleEffect,
  IonRow,
  IonText,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  addCircle,
  briefcase,
  chevronDown,
  chevronForward,
  chevronUp,
  person,
  bed,
  duplicate,
} from "ionicons/icons";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AppId, MainUrl } from "../../AppConfig";
import {
  loadAirlineBookingDataBundleData,
  loadAirlineOrderPassengersData,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { rupiah } from "../../helpers/currency";
import loadingLottie from "../../Lotties/loading_4463.json";
import DefaultToolbar from "../shared/DefaultToolbar";
import { Tabs } from "antd";
import { Select } from "antd";
import { Collapse } from "antd";

const { Panel } = Collapse;
const { TabPane } = Tabs;
interface OwnProps {
  AddOnResult: any;
  setBookingPaxData: any;
  BookingPaxData: any;
  setAddOnTotalPrice: any;
  AddOnTotalPrice: number;
}
interface StateProps {
  ABDB?: any;
}
interface DispatchProps {
  loadAirlineBookingDataBundleData: typeof loadAirlineBookingDataBundleData;
  loadAirlineOrderPassengersData: typeof loadAirlineOrderPassengersData;
}

interface AirlineOrderMealProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const AirlineOrderMeal: React.FC<AirlineOrderMealProps> = ({
  AddOnResult,
  history,
  BookingPaxData,
  setBookingPaxData,
  setAddOnTotalPrice,
  ABDB,
  // UserData,
}) => {
  const [ModalBaggage, setModalBaggage] = useState(false);
  const [ModalAddOns, setModalAddOns] = useState(false);
  const [ModalSeats, setModalSeats] = useState(false);
  const [BaggageSelected, setBaggageSelected] = useState<any>([]);
  const [AddOnSelected, setAddOnSelected] = useState<any>([]);
  const [SeatSelected, setSeatSelected] = useState<any>([]);

  useEffect(()=>{
    if(BookingPaxData && BookingPaxData.length>0){
      const BookingPaxWrapper = new Array();
      const BaggagePaxWrapper = new Array();
      BookingPaxData.forEach((bpd, PaxIndex) => {
        const AddOnWrapper = new Array();
        const BaggageWrapper = new Array();
        bpd.addOns.forEach((ao) => {
          if(ao.meals){
            const Meals = new Array();
            ao.meals.forEach((m,i) => {
              const Meal ={
                code:ao.meals[i],
                fare:ao.mealsPrice[i],
                desc:ao.mealsDetail[i],
                currency:ao.mealsCurrency[i],
              }
              Meals.push(JSON.stringify(Meal));
            });
            AddOnWrapper.push(Meals);
          }
          if(ao.baggageString){
            const Baggage = JSON.stringify({
              code:ao.baggageString,
              fare:ao.baggagePrice,
              desc:ao.baggageDetailString,
              currency:ao.baggageCurrency,
            })
            BaggageWrapper.push(Baggage);
          }else{
            BaggageWrapper.push(null);
          }
        });
        BookingPaxWrapper.push(AddOnWrapper);
        BaggagePaxWrapper.push(BaggageWrapper);
        
        //set default value bagasi
        if(AddOnResult.IsHaveBaggage && BaggageSelected.length<1){
          AddOnResult.baggage.forEach((aob,AORBItem) => {
            let BI = aob.baggageInfos.filter((x) => x.fare == 0)
            if(BI.length > 0){
              let BPD = BookingPaxData;
              let B =
                BookingPaxData[PaxIndex].addOns[AORBItem];
                B.baggageDetailString = BI[0].desc;
                B.baggagePrice = BI[0].fare;
                B.baggageString = BI[0].code;
                B.baggageCurrency = BI[0].currency;
                BPD[PaxIndex].addOns[AORBItem] = B;
              setBookingPaxData([...BPD]);
            }
          })
        }
      });
      setAddOnSelected([...BookingPaxWrapper])
      setBaggageSelected([...BaggagePaxWrapper])
    }
    
  },[BookingPaxData]);
  if (AddOnResult && AddOnResult.IsHaveAddons) {
    return (
      <div className="ion-no-padding">
        <IonText class="ion-padding" color="dark">
          <small>Fasilitas Tambahan</small>
        </IonText>
        <IonCard
          className="ion-activatable ripple-parent ion-margin-bottom"
          onClick={() => setModalAddOns(true)}
          hidden={
            AddOnResult.IsHaveBaggage ? false : true
          }
        >
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonIcon
                    icon={briefcase}
                    className="ion-margin-end"
                  ></IonIcon>
                  <IonText>Bagasi & Add On</IonText>
                </IonCol>
                <IonCol className="ion-text-right">
                  <IonIcon icon={chevronForward} color="primary"></IonIcon>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonText color="success" hidden={BaggageSelected.length > 0 ? false:true}>
              <small>(Bagasi terisi. Klik untuk lihat dan ubah)</small>
            </IonText>
            <IonRippleEffect></IonRippleEffect>
          </IonCardContent>
        </IonCard>
        <IonCard
          className="ion-activatable ripple-parent ion-margin-bottom"
          onClick={() => setModalSeats(true)}
          hidden={
            AddOnResult.IsHaveSeat? false : true
          }
        >
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonIcon
                    icon={bed}
                    className="ion-margin-end"
                  ></IonIcon>
                  <IonText>Nomor Kursi</IonText>
                </IonCol>
                <IonCol className="ion-text-right">
                  <IonIcon icon={chevronForward} color="primary"></IonIcon>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonText color="success" hidden={SeatSelected.length > 0 ? false:true}>
            <small>(Kursi terisi. Klik untuk lihat dan ubah)</small>
          </IonText>
            <IonRippleEffect></IonRippleEffect>
          </IonCardContent>
        </IonCard>
        <IonCard
          className="ion-activatable ripple-parent ion-margin-bottom"
          onClick={() => setModalBaggage(true)}
          hidden={
            AddOnResult.IsHaveBaggage ? true : true
          }
        >
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonIcon
                    icon={duplicate}
                    className="ion-margin-end"
                  ></IonIcon>
                  <IonText>Data Add Ons</IonText>
                </IonCol>
                <IonCol className="ion-text-right">
                  <IonIcon icon={chevronForward} color="primary"></IonIcon>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* <IonText color="success">
            <small>(Bagasi terisi. Klik untuk lihat dan ubah)</small>
          </IonText> */}
            <IonRippleEffect></IonRippleEffect>
          </IonCardContent>
        </IonCard>
        {/* Modal Order Additional Facilities */}
        <IonModal isOpen={ModalAddOns} backdropDismiss={false}>
          <IonContent className="gray-bg">
            <DefaultToolbar
              title="Pilih Add Ons"
              color="primary"
              backButtonRoute={() => {
                setModalAddOns(false);
              }}
            />
            {/* <Lottie animationData={loadingLottie} hidden={AOPB ? true : false} /> */}
            {/* <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>Penerbangan Pergi</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid> */}
            {BookingPaxData
              ? BookingPaxData.filter(
                (obj) => obj.type == 0 || obj.type==1
              ).map((PaxItem, PaxIndex) => (
                  <div key={PaxIndex}>
                    <IonGrid className="white-bg ion-margin-bottom ion-margin-top">
                      <IonRow>
                        <IonCol size="1">
                          <IonIcon icon={person}></IonIcon>
                        </IonCol>
                        <IonCol>
                          <IonText>
                            {PaxItem.title + ". " + PaxItem.firstName}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {AddOnResult.baggage.map((AORB, AORBItem) => (
                      <IonGrid className="white-bg ion-margin-bottom" key={AORBItem}>
                        <IonRow>
                          <IonCol className="ion-align-self-center ion-text-center">
                            <img
                              src={`assets/img/Airlines/${
                                (ABDB &&
                                  ABDB.AirlineFlightDeparture &&
                                  ABDB.AirlineFlightDeparture.airlineID.toLowerCase()) ||
                                ""
                              }/${
                                (ABDB &&
                                  ABDB.AirlineFlightDeparture &&
                                  ABDB.AirlineFlightDeparture.airlineID.toLowerCase()) ||
                                ""
                              }.png`}
                              alt=""
                              width="80%"
                            />
                            <IonText>
                              {AORB.origin} - {AORB.destination}
                              
                            </IonText>
                          </IonCol>

                          <IonCol size="9" className="ion-align-self-center">
                            <Select
                              placeholder="Pilih Bagasi"
                              style={{ width: "100%", marginBottom: "8px" }}
                              defaultValue={BaggageSelected.length>0?BaggageSelected[PaxIndex][AORBItem]:''}
                              onChange={(i:string) => {
                                const BS = JSON.parse(i);
                                let BPD = BookingPaxData;
                                let B =
                                  BookingPaxData[PaxIndex].addOns[AORBItem];
                                  B.baggageDetailString = BS.desc;
                                  B.baggagePrice = BS.fare;
                                  B.baggageString = BS.code;
                                  B.baggageCurrency = BS.currency;
                                  BPD[PaxIndex].addOns[AORBItem] = B;
                                setBookingPaxData([...BPD]);
                              }}
                            >
                              {AddOnResult.IsHaveBaggage
                                ? AddOnResult.baggage[AORBItem].baggageInfos.map(
                                    (item, index) => (
                                      <Select.Option
                                        value={JSON.stringify(item)}
                                        label={item.desc}
                                        key={index}
                                      >
                                        <div className="demo-option-label-item">
                                          {item.desc + " "}
                                          <span role="img" aria-label="tess">
                                            {rupiah(item.fare)}
                                          </span>
                                        </div>
                                      </Select.Option>
                                    )
                                  )
                                : ""}
                                {
                                  // AddOnResult.baggage[AORBItem].baggageInfos.filter((x) => x.fare == 0)[0]
                                  
                                }
                            </Select>
                            <Select
                              mode="multiple"
                              placeholder="Pilih Add On"
                              defaultValue={AddOnSelected.length>0?AddOnSelected[PaxIndex][AORBItem]:[]}
                              onChange={(v) => {
                                let BPD = BookingPaxData;
                                let AO =
                                  BookingPaxData[PaxIndex].addOns[AORBItem];
                                AO.meals = new Array();
                                AO.mealsDetail = new Array();
                                AO.mealsPrice = new Array();
                                AO.mealsCurrency = new Array();
                                // setBookingPaxData();
                                if (v.length > 0) {
                                  v.forEach((i) => {
                                    AO.meals.push(JSON.parse(i).code || 0);
                                    AO.mealsDetail.push(
                                      JSON.parse(i).desc || 0
                                    );
                                    AO.mealsPrice.push(JSON.parse(i).fare || 0);
                                    AO.mealsCurrency.push(JSON.parse(i).currency || 0);
                                  });
                                } else {
                                  AO.meals = null;
                                  AO.mealsDetail = null;
                                  AO.mealsPrice = null;
                                  AO.mealsCurrency = null;
                                }
                                BPD[PaxIndex].addOns[AORBItem] = AO;
                                setBookingPaxData([...BPD])
                              }}
                              style={{ width: "100%", marginBottom: "8px" }}
                              optionLabelProp="label"
                            >
                              {AddOnResult.IsHaveMeal
                                ? AddOnResult.baggage[AORBItem].mealInfos.map(
                                    (item, mealItem) => (
                                      <Select.Option
                                        value={JSON.stringify(item)}
                                        label={item.desc}
                                        key={mealItem}
                                      >
                                        <div className="demo-option-label-item">
                                          {item.desc + " "}
                                          <span role="img" aria-label="tess">
                                            {rupiah(item.fare)}
                                          </span>
                                        </div>
                                      </Select.Option>
                                    )
                                  )
                                : ""}
                            </Select>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    ))}
                  </div>
                ))
              : ""}
          </IonContent>
          <IonFooter>
            <IonCard className="ion-no-margin ion-no-padding footerPrice">
              <IonGrid>
                <IonRow class="priceCollapse">
                  <IonCol size="6">
                    <IonText color="medium">Sub Total</IonText>
                  </IonCol>
                  <IonCol size="6" className="ion-text-right">
                    <IonText>
                      {BookingPaxData
                        ? rupiah(
                            BookingPaxData.reduce(
                              (s, v) =>
                                s +
                                v.addOns.reduce(
                                  (ss, vv) =>
                                    ss +
                                    (vv.mealsPrice
                                      ? vv.mealsPrice.reduce(
                                          (sss, vvv) => sss + vvv,
                                          0
                                        )
                                      : 0)+(vv.baggagePrice?vv.baggagePrice:0) + (vv.seatPrice?vv.seatPrice:0),
                                  0
                                ),
                              0
                            )
                          )
                        : "-"}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      className="text-transform-none"
                      size="large"
                      expand="block"
                      // onClick={() => Pay()}
                      onClick={() => setModalAddOns(false)}
                    >
                      Simpan
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonFooter>
        </IonModal>
        {/* Modal Order Selected Seat Facilities */}
        <IonModal isOpen={ModalSeats} backdropDismiss={false}>
          <IonContent className="gray-bg">
            <DefaultToolbar
              title="Pilih Add Kursi"
              color="primary"
              backButtonRoute={() => {
                setModalSeats(false);
              }}
            />
            {/* <Lottie animationData={loadingLottie} hidden={AOPB ? true : false} /> */}
            {/* <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>Penerbangan Pergi</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid> */}
            {BookingPaxData
              ? BookingPaxData.filter(
                (obj) => obj.type == 0 || obj.type==1
              ).map((PaxItem, PaxIndex) => (
                  <div key={PaxIndex}>
                    <IonGrid className="white-bg ion-margin-bottom ion-margin-top">
                      <IonRow>
                        <IonCol size="1">
                          <IonIcon icon={person}></IonIcon>
                        </IonCol>
                        <IonCol>
                          <IonText>
                            {PaxItem.title + ". " + PaxItem.firstName}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {AddOnResult.seatInfos.map((AOSI, AOSIItem) => (
                      <Collapse
                      expandIconPosition={"right"}
                      defaultActiveKey={[""]}
                      style={{ margin: "16px", borderRadius: "8px" }}
                      key={AOSIItem}
                    >
                      <Panel
                        header={
                          `${AOSI.origin}` +
                          `${" - "}` +
                          `${AOSI.destination}`
                        }
                        key="1"
                      >
                        <div className="bb-lightgray-1 ion-pb-8" key={AOSIItem}>
                          {AddOnResult && AddOnResult.seatInfos ? 
                          <div className="seat-pick">
                            <div className="seat-wrapper">
                              {
                              AddOnResult.seatInfos.filter((e) => e.origin == AOSI.origin && e.destination == AOSI.destination)
                              .map((item, index) => 
                                item.infos && item.infos.length > 0 ? 
                                item.infos.map((SM, SMIndex) =>
                                {
                                  let selectedSetPax = SeatSelected && SeatSelected != null && SeatSelected.length > 0 ? SeatSelected[PaxIndex].addOns.filter((e) => e.aoOrigin == item.origin && e.aoDestination == item.destination)[0].seat : ""
                                  let airlineID = ABDB.AirlineFlightDeparture.airlineID
                                  let postmultiplier = 22
                                  let widthmultiplier = 18
                                  let disable = false
                                  let top = (SM.Y * postmultiplier)
                                  let left = airlineID === "JTB" || airlineID === "JT" ? (SM.X * postmultiplier) : ((SM.X * postmultiplier))
                                  let SC = {'position':'absolute'}
                                  let label = ""
                                  let color = "primary"
                                  let designator = ""
                                  let isRender = true;
                                  if(SM.assignable === false || SM.isOpen === false){
                                    disable = true
                                    label = SM.seatType.includes("NS") ? "X" : SM.seatType;
                                    color = "medium"
                                    if (airlineID != "JTB" && airlineID != "JT") {
                                      top = SM.seatType.includes("NS") ? top - 100 : top ;
                                    }
                                  }
                                  if (SM.assignable === true && SM.isOpen === true) {
                                    designator = SM.seatDesignator;
                                    if (SM.seatType === "NSCS") {
                                        color = "success";
                                    } else {
                                        color = "primary";
                                    }
                                    top = airlineID === "JTB" || airlineID === "JT" ? top : top - 100;
                                    isRender = true;
                                  }
                                  if (SM.seatType.includes("NS") == false) {
                                    if (SM.seatType === "BH" || SM.seatType === "LR") {
                                        color = "danger";
                                        label = SM.seatText;
                                        if (airlineID === "JTB" || airlineID === "JT") {
                        
                                        } else if (airlineID === "QZ" && SM.seatType === "BH") {
                                            label = "";
                                            isRender = false;
                                        } else {
                                            left = Number.isInteger(parseInt(SM.seatText)) ? left + 198 : left;
                                            top = Number.isInteger(parseInt(SM.seatText)) ? top - 100 : top;
                                        }
                                    } else if (SM.seatType === "LV") {
                                        label = "Toilet";
                                        isRender = false;
                                    } else if (SM.seatType === "EX") {
                                        color = color + "success";
                                        label = "EXIT";
                                        isRender = false;
                                    }else {
                                        label = "";
                                        isRender = false;
                                    }
                                  }
                                  if(SM.seatDesignator == selectedSetPax){
                                    color="success"
                                  }
                                  if(SeatSelected){
                                    let tS = Array();
                                    SeatSelected.map((SS,SSIndex) => (
                                      SSIndex != PaxIndex ? 
                                      tS.push(SS.addOns.filter((e) => e.aoOrigin == item.origin && e.aoDestination == item.destination)[0].seat) 
                                      : ""
                                    ))
                                    if(tS.indexOf(SM.seatDesignator) > -1){
                                      color="warning"
                                      disable = true
                                    }
                                  }
                                  SC['marginTop']=top;
                                  SC['marginLeft']=left;
                                  SC['width']=(SM.width*widthmultiplier);
                                  SC['height']=(SM.height*widthmultiplier);
                                  return(
                                  <IonButton
                                    color={color}
                                    style={SC}
                                    size="small"
                                    hidden={
                                      isRender? false : true
                                    }
                                    disabled={
                                      disable? true : false
                                    }
                                    onClick = {() => {
                                      let BPD = BookingPaxData;
                                      let S = BookingPaxData[PaxIndex].addOns.filter((e) => e.aoOrigin == item.origin && e.aoDestination == item.destination)[0];
                                      if(selectedSetPax == null || selectedSetPax === ""){
                                        S.seat = SM.seatDesignator;
                                        S.seatPrice = SM.seatPrice;
                                        S.compartment = SM.compartment;
                                      }else{
                                        S.seat = null;
                                        S.seatPrice = null;
                                        S.compartment = null;
                                      }
                                      BPD[PaxIndex].addOns.filter((e) => e.aoOrigin == item.origin && e.aoDestination == item.destination)[0] = S;
                                      setSeatSelected([...BPD])
                                    }
                                    }
                                    key={SMIndex}
                                  >
                                    {label}
                                    {/* <IonIcon icon={checkmark}></IonIcon> */}
                                    {/* {<IonCheckbox onIonChange={checkboxClick($event)}></IonCheckbox>} */}
                                  </IonButton>)
                                }
                                ) : ""
                              )
                            }
                            </div>
                          </div>
                          : ""
                          }
                        </div>
                      </Panel>
                    </Collapse>
                    ))}
                  </div>
                ))
              : ""}
          </IonContent>
          <IonFooter>
            <IonCard className="ion-no-margin ion-no-padding footerPrice">
              <IonGrid>
                <IonRow class="priceCollapse">
                  <IonCol size="6">
                    <IonText color="medium">Sub Total</IonText>
                  </IonCol>
                  <IonCol size="6" className="ion-text-right">
                    <IonText>
                      {BookingPaxData
                        ? rupiah(
                            BookingPaxData.reduce(
                              (s, v) =>
                                s +
                                v.addOns.reduce(
                                  (ss, vv) =>
                                    ss +
                                    (vv.mealsPrice
                                      ? vv.mealsPrice.reduce(
                                          (sss, vvv) => sss + vvv,
                                          0
                                        )
                                      : 0)+(vv.baggagePrice?vv.baggagePrice:0)+ (vv.seatPrice?vv.seatPrice:0),
                                  0
                                ),
                              0
                            )
                          )
                        : "-"}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      className="text-transform-none"
                      size="large"
                      expand="block"
                      // onClick={() => Pay()}
                      onClick={() => setModalSeats(false)}
                    >
                      Simpan
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonFooter>
        </IonModal>
        {/* Modal Order Selected Baggage Facilities */}
        <IonModal isOpen={ModalBaggage} backdropDismiss={false}>
          <IonContent className="gray-bg">
            <DefaultToolbar
              title="Bagasi"
              color="primary"
              backButtonRoute={() => {
                setModalBaggage(false);
              }}
            />
            {/* <Lottie animationData={loadingLottie} hidden={AOPB ? true : false} /> */}
            {/* <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>Penerbangan Pergi</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid> */}
            {BookingPaxData
              ? BookingPaxData.filter(
                (obj) => obj.type == 0 || obj.type==1
              ).map((PaxItem, PaxIndex) => (
                  <div key={PaxIndex}>
                    <IonGrid className="white-bg ion-margin-bottom ion-margin-top">
                      <IonRow>
                        <IonCol size="1">
                          <IonIcon icon={person}></IonIcon>
                        </IonCol>
                        <IonCol>
                          <IonText>
                            {PaxItem.title + ". " + PaxItem.firstName}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {/* Atur List Bagasi Slider disini */}
                    <IonRow>
                      <IonCol class="ion-text-left">
                        <IonText color="medium">{PaxItem.title + ". " + PaxItem.firstName}</IonText>
                      </IonCol>
                      <IonCol color="medium" class="ion-text-right">
                        <IonText color="medium">5 Kg</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="4">
                        <IonCard
                          className="ion-activatable ripple-parent ion-margin-bottom"
                        >
                          <IonCardContent className="ion-text-center">
                              <IonRow>
                                <IonCol>
                                  <IonText color="dark">0 Kg</IonText>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonText>Rp 0</IonText>
                                </IonCol>
                              </IonRow>
                            {/* <IonText color="success">
                            <small>(Bagasi terisi. Klik untuk lihat dan ubah)</small>
                          </IonText> */}
                            <IonRippleEffect></IonRippleEffect>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                      <IonCol size="4">
                        <IonCard
                          className="ion-activatable ripple-parent ion-margin-bottom"
                        >
                          <IonCardContent>
                            <IonRow>
                              <IonCol className="ion-text-center">
                                <IonText color="dark">5 Kg</IonText>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonText className="ion-text-center">Rp 155.000</IonText>
                            </IonRow>
                            
                            {/* <IonText color="success">
                            <small>(Bagasi terisi. Klik untuk lihat dan ubah)</small>
                          </IonText> */}
                            <IonRippleEffect></IonRippleEffect>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </div>
                ))
              : ""}
          </IonContent>
          <IonFooter>
            <IonCard className="ion-no-margin ion-no-padding footerPrice">
              <IonGrid>
                <IonRow class="priceCollapse">
                  <IonCol size="6">
                    <IonText color="medium">Sub Total</IonText>
                  </IonCol>
                  <IonCol size="6" className="ion-text-right">
                    <IonText>
                      {BookingPaxData
                        ? rupiah(
                            BookingPaxData.reduce(
                              (s, v) =>
                                s +
                                v.addOns.reduce(
                                  (ss, vv) =>
                                    ss +
                                    (vv.mealsPrice
                                      ? vv.mealsPrice.reduce(
                                          (sss, vvv) => sss + vvv,
                                          0
                                        )
                                      : 0)+(vv.baggagePrice?vv.baggagePrice:0),
                                  0
                                ),
                              0
                            )
                          )
                        : "-"}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      className="text-transform-none"
                      size="large"
                      expand="block"
                      // onClick={() => Pay()}
                      onClick={() => setModalSeats(false)}
                    >
                      Simpan
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonFooter>
        </IonModal>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    ABDB: state.airline.AirlineBookingDataBundle,
    // AOPD: state.airline.AirlineOrderPassengersData,
    // UserData: selectors.getUserData(state),
  }),
  // mapDispatchToProps: {
  //   loadAirlineOrderPassengersData,
  //   loadAirlineBookingDataBundleData,
  // },
  component: React.memo(withRouter(AirlineOrderMeal)),
});
