import React, { useState } from "react";
import {
  IonBackButton,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonLoading,
  IonAlert,
  isPlatform,
} from "@ionic/react";
import "./ForgotPassword.scss";
import { setIsLoggedIn, setEmail } from "../../data/user/user.actions";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { HTTP } from "@ionic-native/http";
import { AppId, MainUrl } from "../../AppConfig";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmail: typeof setEmail;
}

interface ForgotPasswordProps extends OwnProps, DispatchProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setIsLoggedIn,
  history,
  setEmail: setEmailAction,
}) => {
  const [Email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!Email) {
      setEmailError(true);
      return;
    }

    setShowLoading(true);
    const MyData = new FormData();
    MyData.append("Email", Email);
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("multipart");
      HTTP.post(MainUrl + "Member/ForgotPassword", MyData, { appid: AppId })
        .then((res) => {
          if (res.status !== 200) {
            failedAlert("Periksa Koneksi Internet");
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          successResetPassword(res);
        })
        .catch((error) => {
          if (error && error.error) {
            failedAlert(error.error);
          }
        });
    } else {
      fetch(MainUrl + "Member/ForgotPassword", {
        method: "POST",
        body: MyData,
        headers: { appid: AppId },
      })
        // Check Connection
        .then((res) => {
          if (!res.ok) {
            failedAlert("Periksa Koneksi Internet");
          }
          return res.json();
        })
        .then((res) => {
          successResetPassword(res);
        });
    }
  };
  const successResetPassword = (res: any) => {
    if (res.RespStatus !== "OK") {
      failedAlert(res.Message || "Gagal Reset Password");
    } else {
      successAlert(
        "Cek Email " + Email + " untuk mendapatkan link reset password"
      );
      history.push("/login", { direction: "none" });
    }
  };
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  const successAlert = (successMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Sukses");
    setMessageAlert(successMessage);
    setShowAlert(true);
  };
  return (
    <IonPage id="forgotpassword-page">
      <IonToolbar className="center-title-toolbar">
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonTitle>RESET PASSWORD</IonTitle>
      </IonToolbar>
      <IonContent className="ion-padding">
        <form noValidate onSubmit={resetPassword}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">
                E-Mail
              </IonLabel>
              <IonInput
                name="Email"
                type="text"
                value={Email}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => setEmail(e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && EmailError && (
              <IonText color="danger">
                <p className="ion-padding-start">Wajib isi email</p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol size="12">
              <IonButton type="submit" size="large" expand="block">
                RESET PASSWORD
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
        <IonLoading isOpen={showLoading} message={"Mendaftar..."} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={headerAlert}
          message={messageAlert}
          buttons={["OK"]}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setEmail,
  },
  component: ForgotPassword,
});
