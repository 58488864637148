import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import { AppId, MainUrl } from '../../AppConfig';
// import { rupiah } from '../../helpers/currency';
// import { HTTP } from '@ionic-native/http';
import BusSearchForm from "../../components/BusSearch/BusSearchForm";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import {
  setAirlineBookingDataBundle,
  setAirlineFlightArrival,
  setAirlineFlightDeparture,
  setAirlineFlightJourney,
  setAirlineOrderPassengersBaggage,
  setAirlineOrderPassengersData,

  setAirlineBookingArrivalDate,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { AppId, MainUrl } from "../../AppConfig";
import { stringDateConvertDashSeparate } from "../../helpers/datetime";
import "./Search.scss";

interface OwnProps {}
interface StateProps {
  AirlineBooking: any;
  UserData: any;
}
interface DispatchProps {
  setAirlineFlightJourney: typeof setAirlineFlightJourney;
  setAirlineFlightDeparture: typeof setAirlineFlightDeparture;
  setAirlineFlightArrival: typeof setAirlineFlightArrival;
  setAirlineBookingDataBundle: typeof setAirlineBookingDataBundle;
  setAirlineOrderPassengersBaggage: typeof setAirlineOrderPassengersBaggage;
  setAirlineOrderPassengersData: typeof setAirlineOrderPassengersData;
  setAirlineBookingArrivalDate: typeof setAirlineBookingArrivalDate;
}
interface SearchProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Search: React.FC<SearchProps> = ({
  AirlineBooking,
  UserData,
  history,
  setAirlineFlightJourney,
  setAirlineFlightDeparture,
  setAirlineFlightArrival,
  setAirlineBookingDataBundle,
  setAirlineOrderPassengersBaggage,
  setAirlineOrderPassengersData,
  setAirlineBookingArrivalDate,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();

  const testAlert = (dataMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Data");
    setMessageAlert(dataMessage);
    setShowAlert(true);
  };
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };

  useIonViewDidEnter(() => {
    var datetomorrow = new Date();
    datetomorrow.setDate(datetomorrow.getDate() + 1);
    console.log(AirlineBooking.AirlineBookingArrivalDate);

    setAirlineBookingArrivalDate(datetomorrow);
    
		// setSearch("");
	});

  const BusBookingSubmit = () => {
    setAirlineFlightJourney(undefined);
    setAirlineFlightDeparture(undefined);
    setAirlineFlightArrival(undefined);
    setAirlineBookingDataBundle(undefined);
    setAirlineOrderPassengersBaggage(undefined);
    setAirlineOrderPassengersData(undefined);
    localStorage.removeItem("AirlineBaggageTotalPrice");
    localStorage.removeItem("AirlineBookingId");
    localStorage.removeItem("AirlineLastIdOrder");
    localStorage.removeItem("AirlineTransactionID");

    history.push("/SearchFirstBus");
  };
  // const AirlineBookingSubmitSuccess = async (res: any) => {
  //   if (
  //     (res.StatusCode == 200 &&
  //       res.Data &&
  //       res.Data.LowFareJourneyDepart &&
  //       res.Data.LowFareJourneyDepart.length > 0) ||
  //     (AirlineBooking.AirlineBookingTripType === "RoundTrip" &&
  //       res.StatusCode == 200 &&
  //       res.Data &&
  //       res.Data.LowFareJourneyReturn &&
  //       res.Data.LowFareJourneyReturn.length > 0)
  //   ) {
  //     await setAirlineFlightJourney({
  //       Departure: res.Data.LowFareJourneyDepart,
  //       Return: res.Data.LowFareJourneyReturn,
  //     });
  //     localStorage.removeItem("AirlineOrderBaggageSelected");
  //     localStorage.removeItem("AirlineOrderOrderPerson");
  //     setShowLoading(false);
  //     history.push("/airlineSearchFirstFlight");
  //   } else {
  //     failedAlert("Penerbangan tidak ditemukan");
  //   }
  // };
  return (
    <IonPage>
      <IonHeader translucent>
        <DefaultToolbar
          title={"Tiket Bus"}
          color="primary"
          backButtonRoute="/main/index"
        />
      </IonHeader>
      <IonContent fullscreen={true} class="pelniSearch">
        <IonGrid className="ion-padding">
          <BusSearchForm></BusSearchForm>
          <IonButton
            className="ion-margin-top text-transform-none"
            expand="block"
            size="large"
            // routerLink="/airlineSearchFirstFlight"
            onClick={() => BusBookingSubmit()}
          >
            Cari Tiket Bus
          </IonButton>
        </IonGrid>
        <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={headerAlert}
          message={messageAlert}
          buttons={["OK"]}
        />
      </IonContent>
    </IonPage>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineBooking: selectors.getAirlineBooking(state),
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {
    setAirlineFlightJourney,
    setAirlineFlightDeparture,
    setAirlineFlightArrival,
    setAirlineBookingDataBundle,
    setAirlineOrderPassengersBaggage,
    setAirlineOrderPassengersData,

    setAirlineBookingArrivalDate,
  },
  component: React.memo(withRouter(Search)),
});
