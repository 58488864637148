import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import { Input, Select, Tooltip } from "antd";
import { peopleCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import PPOBInquiry from "../../components/PPOB/PPOBInquiry";
import PPOBWizard from "../../components/PPOB/PPOBWizard";
import { connect } from "../../data/connect";
import { setHotelSearchResults } from "../../data/hotel/hotel.actions";
import * as selectors from "../../data/selectors";
import { getProductLabel, getProductName } from "../../helpers/PPOB";
const Option = Select.Option;
interface OwnProps {}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface FormProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Form: React.FC<FormProps> = ({ UserData, history }) => {
  const parameters: any = useParams();
  const [ParameterData, setParameterData] = useState<any>({
    customerID: null,
    msisdn: "",
    price: null,
    product: "",
    productType: "",
    provider: "",
    sequence: 1,
    accToken: UserData.accessToken,
  });
  const [Product, setProduct] = useState("");
  const [MSISDN, setMSISDN] = useState("");
  const [IDPelanggan, setIDPelanggan] = useState("");
  const [Products, setProducts] = useState<any>(undefined);
  const [InquiryData, setInquiryData] = useState<any>(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  useIonViewWillEnter(() => {
    if (!getProductName(parameters.transtype)) {
      history.replace("/");
    }
  });
  useIonViewDidEnter(() => {
    if (getProductName(parameters.transtype)) {
      fetchProduct();
    }
  });
  const GoPayment = (data: any) => {
    localStorage.setItem("PPOBPaymentData", JSON.stringify(data));
    history.push("/PPOBPayment");
  };
  const fetchProduct = () => {
    setShowLoading(true);
    if (isPlatform("cordova")) {
      HTTP.get(
        MainUrl + "PPOB/Product",
        { id: getProductName(parameters.transtype || "") },
        { AppId: AppId }
      )
        .then((res) => {
          if (res.status !== 200) {
            alert("Periksa Koneksi anda");
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          setShowLoading(false);
          if (res.Data && res.Data.length > 0) {
            setProducts(res.Data);
          } else {
            setProducts(null);
          }
        })
        .catch((err) => {
          failedAlert(JSON.stringify(err));
        });
    } else {
      fetch(
        MainUrl +
          `PPOB/Product?id=${encodeURIComponent(
            getProductName(parameters.transtype) || ""
          )}` +
          "",
        {
          method: "GET",
          headers: { AppId: AppId },
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            failedAlert("Periksa Koneksi Anda");
          }
        })
        .then((res) => {
          setShowLoading(false);
          if (res.Data && res.Data.length > 0) {
            setProducts(res.Data);
          } else {
            setProducts(null);
          }
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const SubmitForm = () => {
    setShowLoading(true);
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
      "Content-Type": "application/json",
    };
    const MyData = {
      product: Product,
      msisdn: MSISDN,
      idpelanggan: IDPelanggan,
      acctoken: UserData.accessToken,
    };
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("json");
      HTTP.setRequestTimeout(180);
      HTTP.post(MainUrl + "/ppob/Inquiry", MyData, MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              alert("Periksa Koneksi anda");
            }
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          SubmitFormSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    } else {
      fetch(MainUrl + "/ppob/Inquiry", {
        method: "POST",
        body: JSON.stringify(MyData),
        headers: MyHeaders,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            if (r.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              failedAlert(r.statusText);
            }
            return r.json();
          }
        })
        .then((res) => {
          SubmitFormSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const SubmitFormSuccess = async (res: any) => {
    if (res.StatusCode == 200 && res.Data) {
      setShowLoading(false);
      setInquiryData(res.Data);
      // localStorage.setItem("TopupPaymentData", JSON.stringify(res.Data));
      // history.push("/PPOBInquiry");
    } else {
      failedAlert(res.ErrorMessage || `Order Gagal`);
    }
  };
  if (InquiryData) {
    return (
      <PPOBInquiry
        GoPayment={(data) => {
          GoPayment(data);
        }}
        UserData={UserData}
        InquiryData={InquiryData}
        ProductLabel={getProductLabel(parameters.transtype)}
        failedAlert={(em) => {
          failedAlert(em);
        }}
        setShowLoading={(s) => {
          setShowLoading(s);
        }}
      ></PPOBInquiry>
    );
  } else {
    return (
      <IonPage>
        <IonHeader translucent>
          <DefaultToolbar
            title={getProductLabel(parameters.transtype)}
            color="primary"
            backButtonRoute="/main/index"
          />
          <PPOBWizard WizardIndex={1}></PPOBWizard>
        </IonHeader>
        <IonContent fullscreen={true}>
          <IonGrid className="ion-padding">
            <IonRow>
              <IonCol>
                <IonText color="medium">
                  {parameters.transtype.toLowerCase() === "pulsapascabayar" ||
                  parameters.transtype.toLowerCase() === "tvkabel"
                    ? "Operator"
                    : "Layanan"}
                </IonText>
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.toString() !== "unavailable") {
                      setProduct(e.toString());
                    } else {
                      failedAlert("Produk Tidak Tersedia");
                    }
                  }}
                  placeholder={
                    Products === undefined
                      ? "Sedang Memuat"
                      : Products === null
                      ? "Tidak Ditemukan"
                      : parameters.transtype.toLowerCase() ===
                          "pulsapascabayar" ||
                        parameters.transtype.toLowerCase() === "tvkabel"
                      ? "Pilih Operator"
                      : "Pilih Layanan"
                  }
                >
                  {Products
                    ? Products.map((item, index) => (
                        <Option
                          value={
                            item.Value.substr(0, item.Value.indexOf(" ")) ===
                            "[Tersedia]"
                              ? item.Key
                              : "unavailable"
                          }
                          key={index}
                        >
                          {item.Value.substr(item.Value.indexOf(" ") + 1)}
                        </Option>
                      ))
                    : ""}
                </Select>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="medium">
                  {parameters.transtype.toLowerCase() === "pulsapascabayar" ||
                  parameters.transtype.toLowerCase() === "tvkabel" ||
                  parameters.transtype.toLowerCase() === "pln"
                    ? "No. Telepon/No. Kartu"
                    : parameters.transtype.toLowerCase() === "telkom" ||
                      parameters.transtype.toLowerCase() === "pdam"
                    ? "No. Pelanggan"
                    : parameters.transtype.toLowerCase() === "pbb" ||
                      parameters.transtype.toLowerCase() === "bpjs" ||
                      parameters.transtype.toLowerCase() === "pgn"
                    ? "No. Objek Pajak"
                    : "No. Polis"}
                </IonText>
                <Input
                  type="number"
                  placeholder={`Masukkan Nomor`}
                  size="large"
                  value={IDPelanggan}
                  onChange={(e) => setIDPelanggan(e.target.value)}
                />
                <IonText
                  color="medium"
                  hidden={parameters.transtype.toLowerCase() !== "pbb"}
                >
                  <small>No. Objek Pajak harus 18 digit</small>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="medium">No. Telepon Notifikasi</IonText>
                <Input
                  type="number"
                  placeholder={`Masukkan Nomor`}
                  size="large"
                  value={MSISDN}
                  onChange={(e) => setMSISDN(e.target.value)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="ion-margin text-transform-none"
            expand="block"
            size="large"
            color={
              Product.length > 0 && IDPelanggan.length > 0 && MSISDN.length > 0
                ? "primary"
                : "light"
            }
            onClick={() => {
              if (
                Product.length > 0 &&
                IDPelanggan.length > 0 &&
                MSISDN.length > 0
              ) {
                SubmitForm();
              } else {
                failedAlert("Pilih produk & Inputkan ID/Nomor terlebih dahulu");
              }
            }}
          >
            BELI
          </IonButton>
          <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={headerAlert}
            message={messageAlert}
            buttons={["OK"]}
          />
        </IonContent>
      </IonPage>
    );
  }
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
  }),
  // mapDispatchToProps: {
  // },
  component: React.memo(withRouter(Form)),
});
