import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonAlert,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  arrowForward,
  chevronBackOutline,
  ellipse,
  filter,
  funnel,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import { AppId, MainUrl } from '../../AppConfig';
// import { HTTP } from '@ionic-native/http';
import BusSearchForm from "../../components/BusSearch/BusSearchForm";
import BusSearchUpdateButton from "../../components/BusSearch/BusSearchUpdateButton";
import { setAirlineFlightDeparture } from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { rupiah } from "../../helpers/currency";
import { stringDateConvert } from "../../helpers/datetime";
import "./SearchFirstBus.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface OwnProps {}
interface StateProps {
  AirlineBooking: any;
  UserData: any;
  AirlineFlightJourney: any;
}
interface DispatchProps {
  setAirlineFlightDeparture: typeof setAirlineFlightDeparture;
}
interface SearchFirstFlightProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const AirlineSearchFirstFlight: React.FC<SearchFirstFlightProps> = ({
  AirlineBooking,
  UserData,
  AirlineFlightJourney,
  setAirlineFlightDeparture,
  history,
}) => {
  const [BottomDrawerIsOpen, setBottomDrawerIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [BottomDrawerIsDestroy, setBottomDrawerIsDestroy] = useState(true);
  const [selected, setSelected] = useState<string>("biff");
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const [BottomDrawerOpacityStyle, setBottomDrawerOpacityStyle] = useState({
    opacity: "0",
    "z-index": "-9999",
    display: "none",
  });
  const [BottomDrawerCardStyle, setBottomDrawerCardStyle] = useState({
    bottom: "-100vh",
  });
  useEffect(() => {
    if (BottomDrawerIsDestroy) {
      // open
      setBottomDrawerCardStyle({ bottom: "-100vh" });
      setBottomDrawerOpacityStyle({
        opacity: "0",
        "z-index": "9999",
        display: "block",
      });

      setTimeout(() => {
        setBottomDrawerIsOpen(false);
        setBottomDrawerOpacityStyle({
          opacity: "0",
          "z-index": "-9999",
          display: "none",
        });
      }, 500);
    } else {
      // close
      setBottomDrawerIsOpen(true);
      setBottomDrawerOpacityStyle({
        opacity: "0",
        "z-index": "9999",
        display: "block",
      });
      setTimeout(() => {
        setBottomDrawerCardStyle({ bottom: "0" });
        setBottomDrawerOpacityStyle({
          opacity: "1",
          "z-index": "9999",
          display: "block",
        });
      }, 100);
    }
  }, [BottomDrawerIsDestroy]);
  // useEffect(() => {
  //   if (AirlineFlightJourney) {
  //     setBottomDrawerIsDestroy(true);
  //   }
  // }, [AirlineFlightJourney]);
  useIonViewDidEnter(() => {
    // if (!AirlineFlightJourney) {
    //   history.replace("/airlineSearch");
    // }
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/pelniSearch"
              icon={chevronBackOutline}
            ></IonBackButton>
          </IonButtons>
          <IonTitle className="ion-no-padding">
            <b>
              {/* {AirlineBooking.AirlineBookingOrigin} -{" "}
              {AirlineBooking.AirlineBookingDestination} */}
              {"Jakarta"} -{" "}
              {"Bandung"}
            </b>
          </IonTitle>
          <IonTitle className="ion-sub-title ion-no-padding">
            {stringDateConvert(AirlineBooking.AirlineBookingDepartureDate)}{" "}
            <IonIcon icon={ellipse}></IonIcon>{" "}
            {AirlineBooking.AirlineBookingNumberOfPaxTotal} Orang
          </IonTitle>
          <IonButtons slot="end" className="ion-margin-end">
            <IonButton
              className="btn-outline-light text-transform-none"
              onClick={() => {
                setBottomDrawerIsDestroy(false);
              }}
            >
              Ubah
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} class="airlineSearch gray-bg">
        {/* {AirlineFlightJourney && AirlineFlightJourney.Departure
          ? AirlineFlightJourney.Departure.map((item, index) => ( */}
              <IonCard
                className="ion-p-8"
                onClick={async () => {
                  // setAirlineFlightDeparture(item);
                  // if (AirlineBooking.AirlineBookingTripType === "OneWay") {
                    history.push("/busInformation");
                  // } else {
                  //   history.push("/airlineSearchSecondFlight");
                  // }
                }}
              >
                <IonGrid>
                  <IonRow>

                    <IonCol>
                    <IonLabel ><strong>Damri</strong></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="4">
                      {/* <p className="ion-text-center"> */}
                        <IonText>
                          {"Jakarta"}
                        </IonText>
                        <br />

                      {/* </p> */}
                    </IonCol>
                    <IonCol size="2">
                      <img
                        src={"assets/icon/airlinehub.svg"}
                        alt=""
                        width="100%"
                      />
                    </IonCol>
                    <IonCol size="4">
                      <p className="ion-text-center">
                        <IonText>
                          {/* {item.jiArrivalTime.substring(11, 16)} */}
                          {"Bandung"}
                        </IonText>
                        <br />

                      </p>
                    </IonCol>


                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>08 Feb 2022 | 10:22 - 10 Feb 2022 | 14:22</IonLabel>
                      <br />
                     
                    </IonCol>

                  </IonRow>

                  <IonRow>
                    <IonCol className="cb_label">
                      <IonText color="primary">
                        <b>
                          {/* {rupiah(item.sumPrice)} */}
                          {"Rp. 500.000 ,00"}
                          </b>
                      </IonText>
                    </IonCol>
                    <IonCol className="c_label">
                      <IonButton color="primary">Pilih Jadwal</IonButton>

                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            {/* )) */}
          {/* : ""} */}

        <div className="bottomDrawer" hidden={!BottomDrawerIsOpen}>
          <div
            className="bottomDrawerOpacity"
            onClick={() => {
              setBottomDrawerIsDestroy(true);
            }}
            style={BottomDrawerOpacityStyle}
          ></div>
          <div className="bottomDrawerCard" style={BottomDrawerCardStyle}>
            <div
              className="bottomDrawerDragPad"
              onClick={() => {
                setBottomDrawerIsDestroy(true);
              }}
            ></div>
            <BusSearchForm></BusSearchForm>
            <BusSearchUpdateButton></BusSearchUpdateButton>
          </div>
        </div>
        <IonCard className="tourSearchFilterSort" hidden={false} onClick={() => setShowModal(true)}>
          <IonCardContent>
            <IonRow>
              <IonCol size="6" className="ion-text-center">
                <IonIcon icon={filter} color="primary"></IonIcon>
                <IonText color="primary">&nbsp; Urutkan</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-center">
                <IonIcon icon={funnel} color="primary"></IonIcon>
                <IonText color="primary">&nbsp; Filter</IonText>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
        <IonModal isOpen={showModal}>
          <IonContent>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <b>Urutkan</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="MustNoPadding">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonRadioGroup
                        value={selected}
                        onIonChange={(e) => setSelected(e.detail.value)}
                      >
                        <IonItem>
                          <IonLabel>Harga Terendah</IonLabel>
                          <IonRadio slot="end" value="price" />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Berangkat Paling Awal</IonLabel>
                          <IonRadio slot="end" value="departuredesc" />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Berangkat Paling Akhir</IonLabel>
                          <IonRadio slot="end" value="departureasc" />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Tiba Paling Awal</IonLabel>
                          <IonRadio slot="end" value="arrivaldesc" />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Tiba Paling Akhir</IonLabel>
                          <IonRadio slot="end" value="arrivaldasc" />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Durasi Tersingkat</IonLabel>
                          <IonRadio slot="end" value="duration" />
                        </IonItem>
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Tipe Produk</Typography>
              </AccordionSummary>
              <AccordionDetails className="MustNoPadding">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Airplane.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">Pesawat</IonLabel>
                        <IonCheckbox slot="end" value="Airplane" />
                      </IonItem>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Hotel.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">Hotel</IonLabel>
                        <IonCheckbox slot="end" value="Hotel" />
                      </IonItem>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Tour.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">Tur</IonLabel>
                        <IonCheckbox slot="end" value="Tur" />
                      </IonItem>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Bus.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">Bus</IonLabel>
                        <IonCheckbox slot="end" value="Bus" />
                      </IonItem>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Ship.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">PELNI</IonLabel>
                        <IonCheckbox slot="end" value="PELNI" />
                      </IonItem>
                      <IonItem lines="full">
                        <img
                          slot="start"
                          src="assets/img/Services/Phone.svg"
                          alt=""
                          width="24px"
                        />
                        <IonLabel slot="start">Top up</IonLabel>
                        <IonCheckbox slot="end" value="Top up" />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </AccordionDetails>
            </Accordion>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass="alert"
              header={headerAlert}
              message={messageAlert}
              buttons={["OK"]}
            />
          </IonContent>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={() => setShowModal(false)}
                color="light"
                expand="block"
              >
                Reset
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                onClick={() => setShowModal(false)}
                color="primary"
                expand="block"
                className="text-transform-none"
              >
                Simpan
              </IonButton>
            </IonCol>
          </IonRow>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineBooking: selectors.getAirlineBooking(state),
    UserData: selectors.getUserData(state),
    AirlineFlightJourney: state.airline.AirlineFlightJourney,
  }),
  mapDispatchToProps: { setAirlineFlightDeparture },
  component: React.memo(withRouter(AirlineSearchFirstFlight)),
});
