import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user-data":
      return { ...state, ...action.data };
    case "set-username":
      return { ...state, username: action.username };
    case "set-email":
      return { ...state, email: action.email };
    case "set-access-token":
      return { ...state, accessToken: action.accessToken };
    case "set-request-verification-token":
      return {
        ...state,
        requestVerificationToken: action.requestVerificationToken,
      };
    case "set-member-id":
      return { ...state, memberId: action.memberId };
    case "set-name":
      return { ...state, name: action.name };
    case "set-balance":
      return { ...state, balance: action.balance };
    case "set-photo":
      return { ...state, photo: action.photo };
    case "set-has-seen-tutorial":
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-is-loggedin":
      return { ...state, isLoggedin: action.loggedIn };
    case "set-is-reseller":
      return { ...state, isReseller: action.isReseller };
    case "set-balanceNumber":
      return { ...state, balanceNumber: action.balanceNumber };  
  }
}
