import { HTTP } from "@ionic-native/http";
import { isPlatform, useIonViewWillEnter } from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import TopupFormGame from "./TopupFormGame";
import TopupFormPulsa from "./TopupFormPulsa";
import TopupFormTokenListrik from "./TopupFormTokenListrik";
import TopupFormEMoney from "./TopupFormEMoney";
import TopupFormWifiID from "./TopupFormWifiID";

interface OwnProps {
  ParameterData: any;
  setParameterData?: any;
  TransType: string;
  failedAlert?: any;
  setProducts?: any;
  setShowLoading?: any;
}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface TopupFormSwitcherProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const TopupFormSwitcher: React.FC<TopupFormSwitcherProps> = ({
  history,
  UserData,
  TransType,
  setProducts,
  setShowLoading,
  ParameterData,
  setParameterData,
  failedAlert,
}) => {
  const GetProduct = (value) => {
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
      "Content-Type": "application/json",
    };
    var MyData = JSON.stringify({
      Provider: value,
      accToken: UserData.accessToken,
    });
    if (TransType.toLowerCase() === "paketdata") {
      MyData = JSON.stringify({
        Provider: value,
        type: "data",
        accToken: UserData.accessToken,
      });
    }
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("json");
      HTTP.setRequestTimeout(180);
      HTTP.post(MainUrl + "TopUp/ProductV2", JSON.parse(MyData), MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              alert("Periksa Koneksi anda");
            }
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          GetProductSuccess(res, value);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    } else {
      fetch(MainUrl + "TopUp/ProductV2", {
        method: "POST",
        body: MyData,
        headers: MyHeaders,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            if (r.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              failedAlert(r.statusText);
            }
            return r.json();
          }
        })
        .then((res) => {
          GetProductSuccess(res, value);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const GetProductSuccess = async (res: any, value: string) => {
    if (res.StatusCode == 200 && res.Data !== null && res.Data.length > 0) {
      setShowLoading(false);
      setProducts(res.Data);
    } else {
      setProducts(null);
      failedAlert(`Produk ${value} Tidak Ditemukan`);
    }
  };
  useIonViewWillEnter(() => {
    if (TransType.toLowerCase() === "wifiid") {
      GetProduct("WIFI ID");
    }
  });
  if (TransType.toLocaleLowerCase() === "game") {
    return (
      <TopupFormGame
        ParameterData={ParameterData}
        setParameterData={(p) => {
          setParameterData(p);
        }}
        GetProduct={GetProduct}
        TransType={TransType}
        failedAlert={(em) => {
          failedAlert(em);
        }}
        setShowLoading={(s) => {
          setShowLoading(s);
        }}
      ></TopupFormGame>
    );
  } else if (
    TransType.toLocaleLowerCase() === "pulsa" ||
    TransType.toLocaleLowerCase() === "paketdata"
  ) {
    return (
      <TopupFormPulsa
        ParameterData={ParameterData}
        setParameterData={(p) => {
          setParameterData(p);
        }}
        GetProduct={GetProduct}
        TransType={TransType}
        failedAlert={(em) => {
          failedAlert(em);
        }}
        setShowLoading={(s) => {
          setShowLoading(s);
        }}
      ></TopupFormPulsa>
    );
  } else if (TransType.toLocaleLowerCase() === "tokenlistrik") {
    return (
      <TopupFormTokenListrik
        ParameterData={ParameterData}
        setParameterData={(p) => {
          setParameterData(p);
        }}
        GetProduct={GetProduct}
        TransType={TransType}
        failedAlert={(em) => {
          failedAlert(em);
        }}
        setShowLoading={(s) => {
          setShowLoading(s);
        }}
      ></TopupFormTokenListrik>
    );
  } else if (TransType.toLocaleLowerCase() === "wifiid") {
    return (
      <TopupFormWifiID
        ParameterData={ParameterData}
        setParameterData={(p) => {
          setParameterData(p);
        }}
      ></TopupFormWifiID>
    );
  } else {
    return (
      <TopupFormEMoney
        ParameterData={ParameterData}
        setParameterData={(p) => {
          setParameterData(p);
        }}
        GetProduct={GetProduct}
        TransType={TransType}
        failedAlert={(em) => {
          failedAlert(em);
        }}
        setShowLoading={(s) => {
          setShowLoading(s);
        }}
      ></TopupFormEMoney>
    );
  }
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ UserData: selectors.getUserData(state) }),
  mapDispatchToProps: {},
  component: React.memo(withRouter(TopupFormSwitcher)),
});
