import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  IonLabel,
  useIonViewDidEnter,
} from "@ionic/react";

import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { logoutUser } from "../../data/user/user.actions";
import "./Order.scss";
import { Collapse } from "antd";
import { DefaultAva } from "../../AppConfig";
import PelniWizard from "../../components/Bus/PelniWizard";

import PelniOrderBuyerData from "../../components/Pelni/PelniOrderBuyerData";
import AirlineOrderOrderPerson from "../../components/Airline/AirlineOrderOrderPerson";
// import AirlineOrderBaggage from "../../components/Airline/AirlineOrderBaggage";
import { rupiah } from "../../helpers/currency";
import {
  loadAirlineBookingDataBundleData,
  loadAirlineOrderPassengersBaggage,
} from "../../data/airline/airline.actions";
import { AppId, MainUrl } from "../../AppConfig";
import { HTTP } from "@ionic-native/http";
// import AirlineOrderMeal from "../../components/Airline/AirlineOrderMeal";

const { Panel } = Collapse;
interface OwnProps {}
interface StateProps {
  UserData: any;
  ABDB: any;
  AOPD: any;
  AOPB?: any;
}
interface DispatchProps {
  // setTourPaymentAllowStatus: typeof setTourPaymentAllowStatus;
  logoutUser: typeof logoutUser;
  loadAirlineBookingDataBundleData: typeof loadAirlineBookingDataBundleData;
  loadAirlineOrderPassengersBaggage: typeof loadAirlineOrderPassengersBaggage;
}
interface OrderProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Order: React.FC<OrderProps> = ({
  history,
  UserData,
  ABDB,
  AOPD,
  AOPB,
  logoutUser,
  loadAirlineBookingDataBundleData,
  loadAirlineOrderPassengersBaggage,
}) => {
  const [AddOnChecked, setAddOnChecked] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Ups! ada yang kurang");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  const [UseLionOrBatik, setUseLionOrBatik] = useState(
    (ABDB && ABDB.PreBookingData && ABDB.PreBookingData.AirlineID === "JT") ||
      (ABDB &&
        ABDB.PreBookingData &&
        ABDB.PreBookingData.AirlineID === "JTB") ||
      (ABDB && ABDB.PreBookingData && ABDB.PreBookingData.AirlineID === "ID")
      ? true
      : false
  );
  const [UseGaruda, setUseGaruda] = useState(
    ABDB && ABDB.PreBookingData && ABDB.PreBookingData.AirlineID === "GA"
      ? true
      : false
  );
  const [BaggageTotalPrice, setBaggageTotalPrice] = useState(null);
  const [hiddenDetailPrice, setHiddenDetailPrice] = useState(true);
  const [hiddenDetailPriceChevronUp, setHiddenDetailPriceChevronUp] =
    useState(false);
  const [hiddenDetailPriceChevronDown, setHiddenDetailPriceChevronDown] =
    useState(true);
  const seeDetailPrice = () => {
    setHiddenDetailPrice(false);
    setHiddenDetailPriceChevronUp(true);
    setHiddenDetailPriceChevronDown(false);
  };
  const hideDetailPrice = () => {
    setHiddenDetailPrice(true);
    setHiddenDetailPriceChevronUp(false);
    setHiddenDetailPriceChevronDown(true);
  };
  useIonViewDidEnter(() => {
    // loadAirlineBookingDataBundleData();
    // loadAirlineOrderPassengersBaggage();
  });
  // useEffect(() => {
  //   if (!UseLionOrBatik) {
  //     setUseLionOrBatik(
  //       (ABDB &&
  //         ABDB.AirlineFlightArrival &&
  //         ABDB.AirlineFlightArrival.airlineID === "JT") ||
  //         (ABDB &&
  //           ABDB.AirlineFlightArrival &&
  //           ABDB.AirlineFlightArrival.airlineID === "ID")
  //         ? true
  //         : false
  //     );
  //   }
  // }, [UseLionOrBatik]);
  // useEffect(() => {
  //   if (!UseGaruda) {
  //     setUseGaruda(
  //       ABDB &&
  //         ABDB.AirlineFlightDeparture &&
  //         ABDB.AirlineFlightDeparture.airlineID === "GA"
  //         ? true
  //         : false
  //     );
  //   }
  // }, [UseGaruda]);
  const calculateBaggageAirlineTotal = (t) => {
    setBaggageTotalPrice(t);
  };
  const AOPDCheck = () => {
    let count = 0;
    AOPD.forEach((i) => {
      if (i.PaxFirstName !== "") {
        count = count + 1;
      }
    });
    if (count >= AOPD.length) {
      return true;
    } else {
      return false;
    }
  };

  const logout = async () => {
    logoutUser();
  };
  const submitBooking = () => {
    history.push("/pelniPayment");
    // const AOBS = JSON.parse(localStorage.AirlineOrderBaggageSelected);
    failedAlert("Mohon Lengkapi Data terlebih dahulu");
  };
  const BookingSuccess = (res) => {
    if (res.Data && res.Data.RespStatus === "OK") {
      setShowLoading(false);
      localStorage.setItem("AirlineBookingId", res.Data.OrderID);
      localStorage.setItem(
        "AirlineBaggageTotalPrice",
        (BaggageTotalPrice || 0).toString()
      );
      // history.push("/airlinePayment");
    } else {
      failedAlert(res.ErrorMessage);
    }
  };
  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/pelniInformation"></IonBackButton>
          </IonButtons>
          <IonTitle>Data Pesanan</IonTitle>
        </IonToolbar>
        <PelniWizard WizardIndex={1}></PelniWizard>
      </IonHeader>
      <IonContent fullscreen={true} className="AirlineOrder">
        {/* Login As */}
        <IonGrid className="white-bg ion-padding ion-margin-bottom">
          <IonRow>
            <IonCol size="2" className="avatar">
              <img src={UserData.photo || DefaultAva} alt="" />
            </IonCol>
            <IonCol>
              <div>
                <IonText>
                  <p className="ion-no-margin">Login sebagai {UserData.name}</p>
                </IonText>
                <IonText color="medium">
                  <p className="ion-no-margin"> {UserData.email}</p>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <AirlineOrderOrderPerson
          name={UserData.name}
          AddOnChecked={AddOnChecked}
          email={UserData.email}
        ></AirlineOrderOrderPerson>

        <PelniOrderBuyerData
          
        ></PelniOrderBuyerData>

      </IonContent>

      <IonFooter>
        <IonCard className="ion-no-margin ion-no-padding footerPrice">
          <IonGrid>
            <IonRow class="priceCollapse">
              <IonCol size="6">
                <IonText color="medium">Harga yang harus dibayar</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText>
                  <h5 className="ion-no-margin">
                    {rupiah(9999)}
                    {/* {Price !== null ? rupiah(Price || 0) : "Rp 0"} */}
                    <IonIcon
                      icon={chevronUp}
                      hidden={hiddenDetailPriceChevronUp}
                      size="large"
                      color="primary"
                      onClick={() => seeDetailPrice()}
                    ></IonIcon>
                    <IonIcon
                      icon={chevronDown}
                      hidden={hiddenDetailPriceChevronDown}
                      size="large"
                      color="primary"
                      onClick={() => hideDetailPrice()}
                    ></IonIcon>
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid hidden={hiddenDetailPrice}>
            <IonRow>
              <IonCol size="12">
                <IonText color="dark">
                  {ABDB &&
                  ABDB.PriceData &&
                  ABDB.PriceData.TripType &&
                  ABDB.PriceData.TripType === "RoundTrip"
                    ? "Pergi - Pulang"
                    : "Pergi"}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow
              hidden={
                ABDB &&
                ABDB.PreBookingData &&
                ABDB.PreBookingData.PriceDetail &&
                ABDB.PreBookingData.PriceDetail.length > 0 &&
                ABDB.PreBookingData.PriceDetail[0] !== null
                  ? false
                  : true
              }
            >
              {/* Dewasa */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult) ||
                    0}
                  {"x "}
                  Dewasa
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[0] !== null &&
                      ABDB.PreBookingData.PriceDetail[0].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
              {/* Anak-anak */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild) ||
                    0}
                  {"x "}
                  Anak
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[1] !== null &&
                      ABDB.PreBookingData.PriceDetail[1].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
              {/* Anak-anak */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant) ||
                    0}
                  {"x "}
                  Bayi
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[2] !== null &&
                      ABDB.PreBookingData.PriceDetail[2].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow

            >
              <IonCol size="6">
                <IonText color="medium">4x Dewasa</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  {rupiah(
                    1000000000
                  )}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              {/* Baggage */}
              <IonCol size="6">
                <IonText color="medium">Pajak (1%)</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  Sudah termasuk
                  {/* {rupiah(BaggageTotalPrice || 0)} */}
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonText color="medium">Biaya Layanan Gratis</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  Gratis
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  className="text-transform-none"
                  size="large"
                  expand="block"
                  color="light"
                  onClick={() => submitBooking()}
                >
                  Pilih Kursi
                </IonButton>
                <IonButton
                  className="text-transform-none"
                  size="large"
                  expand="block"
                  onClick={() => submitBooking()}
                >
                  Bayar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonFooter>
      <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerAlert}
        message={messageAlert}
        buttons={["OK"]}
      />
    </IonPage>
  );
};
export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
    ABDB: state.airline.AirlineBookingDataBundle,
    AOPD: state.airline.AirlineOrderPassengersData,
    AOPB: state.airline.AirlineOrderPassengersBaggage,
  }),
  mapDispatchToProps: {
    logoutUser,
    loadAirlineBookingDataBundleData,
    loadAirlineOrderPassengersBaggage,
  },
  component: React.memo(withRouter(Order)),
});
