import { IonText, IonLabel } from "@ionic/react";
import { Collapse } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  setAirlineBookingDestination,
  setAirlineBookingDestinationDetail,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import { stringDateHoursConvert } from "../../helpers/datetime";

const { Panel } = Collapse;
interface OwnProps {
  Open: boolean;
}
interface StateProps {
  // AirlineFlightDeparture?: any;
  // AirlineFlightArrival?: any;
  // AirlineBookingTripType?: string;
  // AirlineBookingOrigin?: string;
  // AirlineBookingDestination?: string;
  // ABDB?: any;
}
interface DispatchProps {
  // setAirlineBookingDestination: typeof setAirlineBookingDestination;
  // setAirlineBookingDestinationDetail: typeof setAirlineBookingDestinationDetail;
}
interface PelniPanelProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const PelniPanel: React.FC<PelniPanelProps> = ({
  Open,
  // AirlineFlightDeparture,
  // AirlineFlightArrival,
  // AirlineBookingTripType,
  // AirlineBookingOrigin,
  // AirlineBookingDestination,
  // ABDB,
}) => {
    return (
      <Collapse
        expandIconPosition={"right"}
        defaultActiveKey={Open ? ["1"] : [""]}
        style={{ margin: "16px", borderRadius: "8px" }}
      >
        <Panel
          // header={
          //   AirlineBookingOrigin || "" + AirlineBookingTripType == "OneWay"
          //     ? " → "
          //     : " ⇆ " + AirlineBookingDestination || ""
          // }
          header={
            `${"Tanjung Perak"}` +
            `${" → "}` +
            `${"Yogyakarta"}`
          }
          key="1"
        >
          <div className="bb-lightgray-1 ion-pb-8">
            <IonLabel><strong>KM KELUD</strong></IonLabel>
            <IonText>
              <p className="ion-no-margin">
                Jadwal Berangkat

              </p>
            </IonText>
              <div >
                <IonText color="medium">
                  <p className="ion-no-margin">
                  Tanjung Perak
                  </p>
                </IonText>
                <IonText color="medium">
                  <p className="ion-no-margin">
                    Kam, 08 Feb 2022 | 10:22
                  </p>
                </IonText>
                <IonText color="medium">
                </IonText>
              </div>
          </div>
          <div
            className="bb-lightgray-1 ion-pb-8"
            // hidden={!ABDB.AirlineFlightArrival}
          >
            <IonText>
              <p className="ion-no-margin">
                Jadwal Tiba
              </p>
            </IonText>
              <div >
                <IonText color="medium">
                  <p className="ion-no-margin">
                  Yogyakarta
                  </p>
                </IonText>
                <IonText color="medium">
                  <p className="ion-no-margin">
                    Kam, 08 Feb 2022 | 10:22
                  </p>
                </IonText>
                <IonText color="medium">
                </IonText>
              </div>
          </div>

          
        </Panel>
      </Collapse>
    );
  // }
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    // AirlineFlightDeparture: state.airline.AirlineFlightDeparture,
    // AirlineFlightArrival: state.airline.AirlineFlightArrival,
    // AirlineBookingTripType: state.airline.AirlineBookingTripType,
    // AirlineBookingOrigin: state.airline.AirlineBookingOrigin,
    // AirlineBookingDestination: state.airline.AirlineBookingDestination,
    // ABDB: state.airline.AirlineBookingDataBundle,
  }),
  mapDispatchToProps: {
    // setAirlineBookingDestination,
    // setAirlineBookingDestinationDetail,
  },
  component: React.memo(withRouter(PelniPanel)),
});
