import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";

import { chevronDown, chevronUp, timeOutline } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { setTourPaymentAllowStatus } from "../../data/tour/tour.actions";
import "./Order.scss";
import PelniWizard from "../../components/Pelni/PelniWizard";
import AirlinePaymentChoosePayment from "../../components/Airline/AirlinePaymentChoosePayment";
import AirlinePaymentVoucherCode from "../../components/Airline/AirlinePaymentVoucherCode";
import { Collapse } from "antd";
import { AppId, MainUrl } from "../../AppConfig";
import { HTTP } from "@ionic-native/http";
import { loadAirlineBookingDataBundleData } from "../../data/airline/airline.actions";
import { rupiah } from "../../helpers/currency";
const { Panel } = Collapse;
interface OwnProps {}
interface StateProps {
  UserData: any;
  ABDB: any;
}
interface DispatchProps {
  loadAirlineBookingDataBundleData: typeof loadAirlineBookingDataBundleData;
}
interface OrderProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Order: React.FC<OrderProps> = ({
  history,
  UserData,
  ABDB,
  loadAirlineBookingDataBundleData,
}) => {
  const [hiddenDetailPrice, setHiddenDetailPrice] = useState(true);
  const [hiddenDetailPriceChevronUp, setHiddenDetailPriceChevronUp] =
    useState(false);
  const [hiddenDetailPriceChevronDown, setHiddenDetailPriceChevronDown] =
    useState(true);
  const [PaymentMethodSelected, setPaymentMethodSelected] = useState<any>(null);
  const [TimeLimit, setTimeLimit] = useState<string>("");
  const [BaggageTotalPrice, setBaggageTotalPrice] = useState<any>(null);

  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();

  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  const seeDetailPrice = () => {
    setHiddenDetailPrice(false);
    setHiddenDetailPriceChevronUp(true);
    setHiddenDetailPriceChevronDown(false);
  };
  const hideDetailPrice = () => {
    setHiddenDetailPrice(true);
    setHiddenDetailPriceChevronUp(false);
    setHiddenDetailPriceChevronDown(true);
  };

  const submitPayment = () => {
    // SubmitSuccess
    history.push("/pelniComplete");
  };

  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/pelniSearch"></IonBackButton>
          </IonButtons>
          <IonTitle>Konfirmasi Pembayaran</IonTitle>
        </IonToolbar>
        <PelniWizard WizardIndex={2}></PelniWizard>
      </IonHeader>
      <IonContent fullscreen={true} className="AirlineOrder">
        <IonGrid className="orange-bg ion-padding ion-margin-bottom timer">
          <IonRow>
            <IonCol size="2" className="avatar">
              <IonIcon icon={timeOutline} size="large" color="light"></IonIcon>
            </IonCol>
            <IonCol>
              <div>
                <IonText color="light">
                  <p>
                    <small>Selesaikan pembayaran dalam {TimeLimit}</small>
                    {/* <small>Selesaikan pembayaran dalam {"5 menit"}</small> */}
                  </p>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <AirlinePaymentChoosePayment
          UserData={UserData}
          setPaymentMethodSelected={(e: any) => {
            setPaymentMethodSelected(e);
          }}
        ></AirlinePaymentChoosePayment> */}
        {/* <AirlinePaymentVoucherCode></AirlinePaymentVoucherCode> */}
      </IonContent>

      <IonFooter>
        <IonCard className="ion-no-margin ion-no-padding footerPrice">
          <IonGrid>
            <IonRow class="priceCollapse">
              <IonCol size="6">
                <IonText color="medium">Harga yang harus dibayar</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText>
                  <h5 className="ion-no-margin">
                    {ABDB && ABDB.PriceData
                      ? rupiah(
                          ABDB.PriceData.SumFare +
                            BaggageTotalPrice +
                            ((PaymentMethodSelected &&
                              PaymentMethodSelected.Fee) ||
                              0)
                        )
                      : "Rp 0"}
                    {/* {Price !== null ? rupiah(Price || 0) : "Rp 0"} */}
                    <IonIcon
                      icon={chevronUp}
                      hidden={hiddenDetailPriceChevronUp}
                      size="large"
                      color="primary"
                      onClick={() => seeDetailPrice()}
                    ></IonIcon>
                    <IonIcon
                      icon={chevronDown}
                      hidden={hiddenDetailPriceChevronDown}
                      size="large"
                      color="primary"
                      onClick={() => hideDetailPrice()}
                    ></IonIcon>
                  </h5>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid hidden={hiddenDetailPrice}>
            <IonRow>
              <IonCol size="12">
                <IonText color="dark">
                  {ABDB &&
                  ABDB.PriceData &&
                  ABDB.PriceData.TripType &&
                  ABDB.PriceData.TripType === "RoundTrip"
                    ? "Pergi - Pulang"
                    : "Pergi"}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow
              hidden={
                ABDB &&
                ABDB.PreBookingData &&
                ABDB.PreBookingData.PriceDetail &&
                ABDB.PreBookingData.PriceDetail.length > 0 &&
                ABDB.PreBookingData.PriceDetail[0] !== null
                  ? false
                  : true
              }
            >
              {/* Dewasa */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult) ||
                    0}
                  {"x "}
                  Dewasa
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfAdult > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[0] !== null &&
                      ABDB.PreBookingData.PriceDetail[0].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
              {/* Anak-anak */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild) ||
                    0}
                  {"x "}
                  Anak
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfChild > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[1] !== null &&
                      ABDB.PreBookingData.PriceDetail[1].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
              {/* Anak-anak */}
              <IonCol
                size="6"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {(ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant) ||
                    0}
                  {"x "}
                  Bayi
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className="ion-text-right"
                hidden={
                  ABDB && ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0
                    ? false
                    : true
                }
              >
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.AirlineBooking.AirlineBookingNumberOfInfant > 0 &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.PriceDetail &&
                      ABDB.PreBookingData.PriceDetail.length > 0 &&
                      ABDB.PreBookingData.PriceDetail[2] !== null &&
                      ABDB.PreBookingData.PriceDetail[2].baseFare) ||
                      "0"
                  )}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow
              hidden={
                ABDB &&
                ABDB.PreBookingData &&
                ABDB.PreBookingData.PriceDetail &&
                ABDB.PreBookingData.PriceDetail.length > 0 &&
                ABDB.PreBookingData.PriceDetail[0] !== null
              }
            >
              <IonCol size="6">
                <IonText color="medium">Total Harga</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  {rupiah(
                    ABDB && ABDB.PreBookingData && ABDB.PreBookingData.SumFare
                      ? ABDB.PreBookingData.SumFare -
                          ABDB.PreBookingData.TotalTax || 0
                      : 0
                  )}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              {/* Baggage */}
              <IonCol size="6">
                <IonText color="medium">Bagasi</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  {rupiah(BaggageTotalPrice || 0)}
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonText color="medium">Payment Fee</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  {rupiah(
                    (PaymentMethodSelected && PaymentMethodSelected.Fee) || 0
                  )}
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonText color="medium">Pajak</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText color="medium">
                  {rupiah(
                    (ABDB &&
                      ABDB.PreBookingData &&
                      ABDB.PreBookingData.TotalTax) ||
                      0
                  )}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  className="text-transform-none"
                  size="large"
                  expand="block"
                  // onClick={() => Pay()}
                  onClick={() => submitPayment()}
                >
                  Bayar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonFooter>
      <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerAlert}
        message={messageAlert}
        buttons={["OK"]}
      />
    </IonPage>
  );
};
export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
    ABDB: state.airline.AirlineBookingDataBundle,
  }),
  mapDispatchToProps: {
    loadAirlineBookingDataBundleData,
  },
  component: React.memo(withRouter(Order)),
});
