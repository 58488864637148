import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { Input, Select, Tooltip } from "antd";
import { peopleCircle } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";

interface OwnProps {
  GetProduct: any;
  TransType: any;
  failedAlert?: any;
  setShowLoading?: any;
  ParameterData: any;
  setParameterData?: any;
}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface TopupFormEMoneyProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const TopupFormEMoney: React.FC<TopupFormEMoneyProps> = ({
  history,
  GetProduct,
  UserData,
  TransType,
  setShowLoading,
  failedAlert,
  ParameterData,
  setParameterData,
}) => {
  const { Option } = Select;
  const [Provider, setProvider] = useState<any>([
    "CASHMANDIRI",
    "E-MONEY",
    "EMONEY BRI",
    "SALDO DANA",
    "SALDO GOJEK",
    "SALDO GRAB",
    "SALDO LINK AJA",
    "SALDO M-TIK",
    "SALDO OVO",
    "SALDO SHOPEEPAY",
    "SALDOTIX",
    "TAPCASH BNI",
  ]);
  const sequenceChange = (value) => {
    let p = ParameterData;
    p.sequence = parseInt(value);
    setParameterData(p);
  };
  const msisdnChange = (v) => {
    let p = ParameterData;
    p.msisdn = v.target.value;
    setParameterData(p);
  };
  useIonViewDidEnter(() => {
    let p = ParameterData;
    p.productType =
      TransType.toLowerCase() === "emoney" ? "Uang Elektronik" : "";
    setParameterData(p);
  });
  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol>
          <IonText color="medium">Layanan</IonText>
          <Select
            size="large"
            style={{ width: "100%" }}
            onChange={GetProduct}
            placeholder="Pilih E-Money"
          >
            {Provider
              ? Provider.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))
              : ""}
          </Select>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="medium">No. Telepon/No. Kartu</IonText>
          <Input
            type="number"
            placeholder={`Masukkan Nomor`}
            size="large"
            onChange={msisdnChange}
            suffix={
              <Tooltip title="Inputkan">
                <IonIcon
                  icon={peopleCircle}
                  color="primary"
                  size="large"
                ></IonIcon>
              </Tooltip>
            }
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="medium">Pengisian ke</IonText>
          <Select
            value={ParameterData.sequence || ""}
            size="large"
            style={{ width: "100%" }}
            onChange={sequenceChange}
          >
            {[...Array(100)].map((item, index) => (
              <Option value={`${index + 1}`}>{index + 1}</Option>
            ))}
          </Select>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ UserData: selectors.getUserData(state) }),
  mapDispatchToProps: {},
  component: React.memo(withRouter(TopupFormEMoney)),
});
