import { HTTP } from "@ionic-native/http";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import { Alert, Input, Select, Tooltip } from "antd";
import { peopleCircle } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";

interface OwnProps {
  GetProduct: any;
  TransType: any;
  failedAlert?: any;
  setShowLoading?: any;
  ParameterData: any;
  setParameterData?: any;
}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface TopupFormTokenListrikProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const TopupFormTokenListrik: React.FC<TopupFormTokenListrikProps> = ({
  history,
  GetProduct,
  UserData,
  TransType,
  setShowLoading,
  failedAlert,
  ParameterData,
  setParameterData,
}) => {
  const { Option } = Select;
  const [Provider, setProvider] = useState<any>(undefined);

  const TokenListrikSubmit = () => {
    // if (!UserData.accessToken) {
    //   failedAlert("Login Terlebih Dahulu");
    //   history.push("/login");
    //   return;
    // }
    setShowLoading(true);
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
      "Content-Type": "application/json",
    };
    if (isPlatform("cordova")) {
      HTTP.get(
        MainUrl + "TopUp/Provider?id=tokenPLN&acctoken=" + UserData.accessToken,
        {},
        MyHeaders
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              alert("Periksa Koneksi anda");
            }
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          TokenListrikSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    } else {
      fetch(
        MainUrl + "TopUp/Provider?id=tokenPLN&acctoken=" + UserData.accessToken,
        {
          method: "GET",
          headers: MyHeaders,
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            if (r.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");

              history.push("/login");
            } else {
              failedAlert(r.statusText);
            }
            return r.json();
          }
        })
        .then((res) => {
          TokenListrikSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const TokenListrikSuccess = async (res: any) => {
    if (res.StatusCode == 200 && res.Data !== null) {
      setShowLoading(false);
      setProvider(res.Data);
    } else {
      setProvider(null);
      failedAlert("Hotel tidak ditemukan");
    }
  };
  const sequenceChange = (value) => {
    let p = ParameterData;
    p.sequence = parseInt(value);
    setParameterData(p);
  };
  const msisdnChange = (v) => {
    let p = ParameterData;
    p.msisdn = v.target.value;
    setParameterData(p);
  };
  useIonViewDidEnter(() => {
    let p = ParameterData;
    p.productType =
      TransType.toLowerCase() === "tokenlistrik" ? "Uang Elektronik" : "";
    setParameterData(p);
    TokenListrikSubmit();
  });
  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol>
          <IonText color="medium">Layanan</IonText>
          <Select
            size="large"
            style={{ width: "100%" }}
            onChange={GetProduct}
            placeholder="Pilih Token Listrik"
          >
            {Provider
              ? Provider.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))
              : ""}
          </Select>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="medium">Pengisian ke</IonText>
          <Select
            defaultValue="1"
            size="large"
            style={{ width: "100%" }}
            onChange={sequenceChange}
          >
            {[...Array(100)].map((item, index) => (
              <Option value={`${index + 1}`}>{index + 1}</Option>
            ))}
          </Select>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="medium">No. Telepon Notifikasi</IonText>
          <Input
            type="number"
            placeholder={`Masukkan Nomor`}
            size="large"
            onChange={msisdnChange}
            suffix={
              <Tooltip title="Inputkan">
                <IonIcon
                  icon={peopleCircle}
                  color="primary"
                  size="large"
                ></IonIcon>
              </Tooltip>
            }
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Alert
            message="Pembelian token prabayar yang dilakukan antara
pukul 23:00 - 00:59 WIB ajab diproses setelah pukul 01:00 WIB dikarenakan oleh jadwal offline server PLN."
            type="info"
            showIcon
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ UserData: selectors.getUserData(state) }),
  mapDispatchToProps: {},
  component: React.memo(withRouter(TopupFormTokenListrik)),
});
