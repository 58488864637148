import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { Input, Select, Tooltip } from "antd";
import { peopleCircle } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { getProviderName } from "../../helpers/TopUp";

interface OwnProps {
  GetProduct: any;
  TransType: any;
  ParameterData: any;
  setParameterData?: any;
  failedAlert?: any;
  setShowLoading?: any;
}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface TopupFormPulsaProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const TopupFormPulsa: React.FC<TopupFormPulsaProps> = ({
  history,
  GetProduct,
  UserData,
  TransType,
  setShowLoading,
  failedAlert,
  ParameterData,
  setParameterData,
}) => {
  const { Option } = Select;
  const [Provider, setProvider] = useState<any>(undefined);
  const sequenceChange = (value) => {
    let p = ParameterData;
    p.sequence = parseInt(value);
    setParameterData(p);
  };
  const msisdnChange = (value) => {
    let p = ParameterData;
    p.msisdn = value;
    setParameterData(p);
  };
  const getProviderByNumber = (v) => {
    msisdnChange(v.target.value);
    if (v.target.value.length === 4) {
      const p = getProviderName(v.target.value);
      if (p !== "notfound") {
        GetProduct(p);
      }
    }
  };
  useIonViewDidEnter(() => {
    let p = ParameterData;
    p.productType =
      TransType.toLowerCase() === "pulsa" ? "Pulsa" : "Paket Data";
    setParameterData(p);
  });
  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol>
          <IonText color="medium">No. Handphone</IonText>
          <Input
            placeholder={`Masukkan Nomor`}
            size="large"
            type="number"
            onChange={getProviderByNumber}
            suffix={
              <Tooltip title="Inputkan">
                <IonIcon
                  icon={peopleCircle}
                  color="primary"
                  size="large"
                ></IonIcon>
              </Tooltip>
            }
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="medium">Pengisian Ke</IonText>
          <Select
            defaultValue={ParameterData.sequence}
            size="large"
            style={{ width: "100%" }}
            onChange={sequenceChange}
          >
            {[...Array(100)].map((item, index) => (
              <Option value={`${index + 1}`} key={index}>
                {index + 1}
              </Option>
            ))}
          </Select>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ UserData: selectors.getUserData(state) }),
  mapDispatchToProps: {},
  component: React.memo(withRouter(TopupFormPulsa)),
});
