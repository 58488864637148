import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import TopupFormSwitcher from "../../components/Topup/TopupFormSwitcher";
import TopupWizard from "../../components/Topup/TopupWizard";
import { connect } from "../../data/connect";
import { setHotelSearchResults } from "../../data/hotel/hotel.actions";
import * as selectors from "../../data/selectors";
import { setLoading } from "../../data/user/user.actions";
import { rupiah } from "../../helpers/currency";

interface OwnProps {}
interface StateProps {
  HotelSearchData: any;
  UserData: any;
}
interface DispatchProps {
  setHotelSearchResults: typeof setHotelSearchResults;
}
interface FormProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Form: React.FC<FormProps> = ({
  HotelSearchData,
  UserData,
  setHotelSearchResults,
  history,
}) => {
  const parameters: any = useParams();
  const [ParameterData, setParameterData] = useState<any>({
    customerID: null,
    msisdn: "",
    price: null,
    product: "",
    productType: "",
    provider: "",
    sequence: 1,
    accToken: UserData.accessToken,
  });
  const [Products, setProducts] = useState<any>(undefined);
  const [ProductSelected, setProductSelected] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  useIonViewWillEnter(() => {
    if (
      (parameters.transtype && parameters.transtype.toLowerCase() === "game") ||
      parameters.transtype.toLowerCase() === "pulsa" ||
      parameters.transtype.toLowerCase() === "paketdata" ||
      parameters.transtype.toLowerCase() === "tokenlistrik" ||
      parameters.transtype.toLowerCase() === "wifiid" ||
      parameters.transtype.toLowerCase() === "emoney"
    ) {
    } else {
      history.replace("/");
    }
  });
  const SubmitForm = () => {
    setLoading(true);
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
      "Content-Type": "application/json",
    };
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("json");
      HTTP.setRequestTimeout(180);
      HTTP.post(MainUrl + "TopUp/Order", ParameterData, MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              alert("Periksa Koneksi anda");
            }
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          SubmitFormSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    } else {
      fetch(MainUrl + "TopUp/Order", {
        method: "POST",
        body: JSON.stringify(ParameterData),
        headers: MyHeaders,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            if (r.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              failedAlert(r.statusText);
            }
            return r.json();
          }
        })
        .then((res) => {
          SubmitFormSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const SubmitFormSuccess = async (res: any) => {
    if (res.StatusCode == 200 && res.Data) {
      localStorage.setItem("TopupPaymentData", JSON.stringify(res.Data));
      history.push("/topupPayment");
    } else {
      failedAlert(res.ErrorMessage || `Order Gagal`);
    }
  };
  return (
    <IonPage>
      <IonHeader translucent>
        <DefaultToolbar
          title={
            parameters.transtype.toLowerCase() === "paketdata"
              ? "Paket Data"
              : parameters.transtype.toLowerCase() === "emoney"
              ? "E-Money"
              : parameters.transtype.toLowerCase() === "tokenlistrik"
              ? "Token Listrik"
              : parameters.transtype.toLowerCase() === "wifiid"
              ? "Wifi ID"
              : parameters.transtype
          }
          color="primary"
          backButtonRoute="/main/index"
        />
        <TopupWizard WizardIndex={1}></TopupWizard>
      </IonHeader>
      <IonContent fullscreen={true}>
        <TopupFormSwitcher
          ParameterData={ParameterData}
          setParameterData={(p) => {
            setParameterData(p);
          }}
          setProducts={(p) => {
            setProducts(p);
          }}
          TransType={parameters.transtype}
          failedAlert={(em) => {
            failedAlert(em);
          }}
          setShowLoading={(s) => {
            setShowLoading(s);
          }}
        ></TopupFormSwitcher>
        <IonGrid className="ion-padding">
          <IonRow hidden={Products && Products.length > 0 ? false : true}>
            <IonCol>
              <IonText color="primary">
                <b>Pilih Nominal</b>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            {Products && Products.length > 0
              ? Products.map((productItem, productIndex) => (
                  <IonCol
                    key={productIndex}
                    hidden={productItem.Value.split(";")[0] !== "[Tersedia]"}
                    size="6"
                  >
                    <IonCard
                      color={
                        ProductSelected === productItem.Key ? "primary" : ""
                      }
                      onClick={() => {
                        let p = ParameterData;
                        p.price = productItem.Value.split(";")[2];
                        p.product = productItem.Key;
                        p.provider = productItem.Kode;
                        setParameterData(p);
                        setProductSelected(productItem.Key);
                      }}
                      className="ion-padding ion-text-center ion-no-margin"
                    >
                      <p className="ion-no-margin">
                        <b>{productItem.Value.split(";")[1]}</b>
                        <br />
                        Rp
                        {" " + productItem.Value.split(";")[2]}
                      </p>
                    </IonCard>
                  </IonCol>
                ))
              : ""}
          </IonRow>
        </IonGrid>

        <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={headerAlert}
          message={messageAlert}
          buttons={["OK"]}
        />
      </IonContent>
      <IonFooter>
        <IonCard className="ion-no-margin ion-no-padding footerPrice">
          <IonGrid>
            <IonRow class="priceCollapse" hidden={!ParameterData.price}>
              <IonCol size="6">
                <IonText color="medium">Harga yang harus dibayar</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-right">
                <IonText>
                  <h6 className="ion-no-margin ion-no-padding">
                    Rp
                    {" " + ParameterData.price}
                  </h6>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-margin text-transform-none"
                  expand="block"
                  size="large"
                  color={ProductSelected !== "" ? "primary" : "light"}
                  onClick={() => {
                    if (ProductSelected !== "") {
                      SubmitForm();
                    } else {
                      failedAlert("pilih produk terlebih dahulu");
                    }
                  }}
                >
                  BELI
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonFooter>
    </IonPage>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    HotelSearchData: selectors.getHotelSearchData(state),
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {
    setHotelSearchResults,
  },
  component: React.memo(withRouter(Form)),
});
