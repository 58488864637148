import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { chevronBackOutline, person } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { AppId, AppName, ContactUsLink, MainUrl } from "../../AppConfig";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import {
  getHistoryTransactionIcon,
  getHistoryTransactionStatusColor,
} from "../../helpers/HistoryTransaction";
import PPOBTransactionManage from "../../components/PPOB/PPOBTransactionManage";

import {
  cSharpDateHourConvert,
  stringDateHoursConvert,
} from "../../helpers/datetime";
import { rupiah } from "../../helpers/currency";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import QRCode from "react-qr-code";

interface OwnProps {}
interface StateProps {
  UserData: any;
  isReseller: boolean;
}
interface DispatchProps {}
interface TransactionHistoryDetailPPOBProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const TransactionHistoryDetailPPOB: React.FC<
  TransactionHistoryDetailPPOBProps
> = ({ history, UserData, isReseller }) => {
  const parameters: any = useParams();
  const [TransactionHistoryDetail, setTransactionHistoryDetail] =
    useState<any>(null);

  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();

  const getOrderDetail = () => {
    var MyData = new FormData();
    MyData.append("AccToken", UserData.accessToken);
    MyData.append("id_order", parameters.inv.replace(/-/g, "."));
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("multipart");
      HTTP.post(MainUrl + "Member/OrderDetail", MyData, {
        appid: AppId,
        RequestVerificationToken: UserData.requestVerificationToken,
      })
        .then((res) => {
          if (res.status !== 200) {
            failedAlert("Cek Koneksi Internet Anda");
            // history.push('/transactionHistoryList')
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          getOrderDetailSuccess(res);
        })
        .catch((e) => {
          failedAlert(e.error);
          // history.push('/transactionHistoryList')
        });
    } else {
      fetch(MainUrl + "Member/OrderDetail", {
        method: "POST",
        body: MyData,
        headers: {
          appid: AppId,
          RequestVerificationToken: UserData.requestVerificationToken,
        },
      })
        // Check Connection
        .then((res) => {
          if (!res.ok) {
            failedAlert("Periksa Koneksi anda");
            // history.push("/transactionHistoryList");
          }
          return res.json();
        })
        .then((res) => {
          getOrderDetailSuccess(res);
        })
        .catch((e) => {
          failedAlert("Data Histori Transaksi tidak ditemukan");
          // history.push("/transactionHistoryList");
        });
    }
  };
  const getOrderDetailSuccess = (res: any) => {
    if (res.StatusCode === 200) {
      setTransactionHistoryDetail(res.Data);
    } else {
      failedAlert("Data Histori Transaksi tidak ditemukan");
      history.push("/transactionHistoryList");
    }
  };
  useEffect(() => {
    setTransactionHistoryDetail(null);
    getOrderDetail();
  },[]);
  const failedAlert = (errorMessage: string) => {
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  if (TransactionHistoryDetail) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary" className="">
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/transactionHistoryList"
                icon={chevronBackOutline}
              ></IonBackButton>
            </IonButtons>
            <IonTitle className="ion-no-padding">Detail Pesanan</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="gray-bg">
          <Accordion
            style={{ margin: "16px" }}
            hidden={
              TransactionHistoryDetail.BPJSDetail &&
              TransactionHistoryDetail.BPJSDetail.length > 0
                ? false
                : true
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <IonText>
                <b>Detail Anggota BPJS</b>
              </IonText>
            </AccordionSummary>
            {TransactionHistoryDetail.BPJSDetail
              ? TransactionHistoryDetail.BPJSDetail.map((item, index) => (
                  <AccordionDetails
                    style={{
                      borderTop: "1px solid lightgray",
                      paddingTop: "16px",
                    }}
                    key={index}
                  >
                    <p className="ion-no-margin">
                      ID : {item.id || ""} <br />
                      Nama : {item.nama || ""}
                      <br />
                      Tagihan : {item.tagihan || ""}
                    </p>
                  </AccordionDetails>
                ))
              : ""}
          </Accordion>
          <IonGrid className="ion-padding  white-bg">
            <IonRow>
              <IonCol size="5">
                <IonText
                  color={getHistoryTransactionStatusColor(
                    TransactionHistoryDetail.status,
                    "ppobvoucher"
                  )}
                >
                  Status
                </IonText>
              </IonCol>
              <IonCol size="7">
                <IonText
                  color={getHistoryTransactionStatusColor(
                    TransactionHistoryDetail.status,
                    "ppobvoucher"
                  )}
                >
                  : {TransactionHistoryDetail.status || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Billing Reff</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.billingReferenceID || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">ID Pelanggan</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.customerID || ""}</IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Kode Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.productCode || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Nama Pelanggan</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.customerName || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Tanggal Transaksi</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  :{" "}
                  {stringDateHoursConvert(
                    TransactionHistoryDetail.transactionDate || ""
                  ) || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <div className="bb-lightgray-1 ion-margin"></div>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">No. Telepon</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.customerMSISDN || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.productGroup || ""} -{" "}
                  {TransactionHistoryDetail.productCode || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow hidden={!TransactionHistoryDetail.period}>
              <IonCol size="5">
                <IonText color="medium">Periode Pembayaran</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.period || ""}</IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="5">
                <IonText color="medium">VSN</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.message || ""}</IonText>
              </IonCol>
            </IonRow>
            <div className="bb-lightgray-1 ion-margin"></div>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Total Tagihan</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {rupiah(TransactionHistoryDetail.payment || "") || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow hidden={isReseller?false:true}>
              <IonCol size="5">
                <IonText color="medium">Tagihan Agen</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {rupiah(TransactionHistoryDetail.sellPrice || "") || ""}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <PPOBTransactionManage
            THD={TransactionHistoryDetail}
          ></PPOBTransactionManage>
          <br />
          <IonGrid hidden={
              TransactionHistoryDetail.status === "success"
            }>
            <IonRow className="ion-align-items-center">
              <IonCol
                size="12"
                hidden={
                  TransactionHistoryDetail.paymentDetail &&
                  TransactionHistoryDetail.paymentDetail.paymentMethod.toLowerCase() ===
                    "qris"
                    ? false
                    : true
                }
                class="ion-text-center"
              >
                <img
                  src={`assets/img/payment/${
                    TransactionHistoryDetail.paymentDetail &&
                    TransactionHistoryDetail.paymentDetail.paymentMethod||''
                  }.png`}
                />
                <br />
                {
                  TransactionHistoryDetail.paymentDetail && TransactionHistoryDetail.paymentDetail != null ? 
                  <QRCode
                    value={
                      TransactionHistoryDetail.paymentDetail &&
                      TransactionHistoryDetail.paymentDetail.paymentCode||null
                    }
                  />
                  :""
                }
              </IonCol>
            </IonRow>
          </IonGrid>
          <br />
          <IonText className="ion-margin">
            <small>
              <b>Hubungi {AppName}</b>
            </small>
          </IonText>
          <IonGrid className="ion-margin-top white-bg ion-padding ion-margin-bottom">
            <IonRow className="ion-align-items-center">
              <IonCol size="12">
                <IonText>
                  <small>
                    Billing Reference :{" "}
                    {TransactionHistoryDetail.billingReferenceID || ""}
                  </small>
                </IonText>
              </IonCol>
              <IonCol size="12">
                <p color="medium">
                  <small>
                    Customer service kami akan menanyakan Kode Booking tersebut
                    saat Anda menghubungi kami
                  </small>
                </p>
              </IonCol>
              <IonCol size="12">
                <IonText color="primary">
                  <a href={ContactUsLink}>
                    <b>HUBUNGI KAMI</b>
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="text-transform-none ion-margin"
            size="large"
            expand="block"
            routerLink="/"
          >
            Beli Lagi
          </IonButton>
          <p>
            <br />
          </p>

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="alert"
            header={headerAlert}
            message={messageAlert}
            buttons={["OK"]}
          />
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
        <IonContent>
          <div className="loadingData">
            <img src="assets/icon/loading.svg" width="80px" />
            <br />
            Memuat Detail Transaksi
          </div>
        </IonContent>
      </IonPage>
    );
  }
};
export default connect<TransactionHistoryDetailPPOBProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
    isReseller: state.user.isReseller
  }),
  mapDispatchToProps: {},
  component: withRouter(TransactionHistoryDetailPPOB),
});
