import { combineReducers } from "./combineReducers";
import { appReducer } from "./app/app.reducer";
import { userReducer } from "./user/user.reducer";
import { profileReducer } from "./profile/profile.reducer";
import { tourReducer } from "./tour/tour.reducer";
import { airlineReducer } from "./airline/airline.reducer";
import { hotelReducer } from "./hotel/hotel.reducer";
import { pelniReducer } from "./pelni/pelni.reducer";
var datenow = new Date();
var datetomorrow = new Date();
var datetAirlineArrival = new Date();
datetomorrow.setDate(datetomorrow.getDate() + 1);
datetAirlineArrival.setDate(datetAirlineArrival.getDate() + 3);
export const initialState: AppState = {
  data: {
    news: [],
    newTourProductList: [],
    newEcommerceProductList: [],
  },
  user: {
    isReseller: false,
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loading: false,
  },
  profile: {},
  tour: {
    TourPaymentAllowStatus: false,
  },
  airline: {
    AirlinePaymentAllowStatus: false,
    AirlineBookingDepartureDate: datenow,
    AirlineBookingArrivalDate: datetAirlineArrival,
    AirlineBookingTripType: "OneWay",
    AirlineBookingNumberOfAdult: 1,
    AirlineBookingNumberOfChild: 0,
    AirlineBookingNumberOfInfant: 0,
    AirlineBookingNumberOfPaxTotal: 1,
  },
  hotel: {
    HotelSearchRoomType: [{ roomType: "Double" }],
    HotelSearchCheckInDate: datenow,
    HotelSearchCheckOutDate: datetomorrow,
  },
  pelni: {
    PelniBookingSearch: { DateDeparture: datetomorrow,Destination:null,Origin:null,PaxFemale:0,PaxMale:1,PaxInfant:0 }
  }
};

export const reducers = combineReducers({
  data: appReducer,
  user: userReducer,
  profile: profileReducer,
  tour: tourReducer,
  airline: airlineReducer,
  hotel: hotelReducer,
  pelni: pelniReducer,
});

export type AppState = ReturnType<typeof reducers>;
