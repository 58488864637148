import { IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { Input, Tooltip } from "antd";
import { peopleCircle } from "ionicons/icons";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";

interface OwnProps {
  ParameterData: any;
  setParameterData?: any;
}
interface DispatchProps {}
interface TopupFormWifiIDProps
  extends OwnProps,
    DispatchProps,
    RouteComponentProps {}
const TopupFormWifiID: React.FC<TopupFormWifiIDProps> = ({
  ParameterData,
  setParameterData,
}) => {
  const msisdnChange = (v) => {
    let p = ParameterData;
    p.msisdn = v.target.value;
    setParameterData(p);
  };
  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol>
          <IonText color="medium">No. Handphone</IonText>
          <Input
            placeholder={`Masukkan Nomor`}
            size="large"
            type="number"
            onChange={msisdnChange}
            suffix={
              <Tooltip title="Inputkan">
                <IonIcon
                  icon={peopleCircle}
                  color="primary"
                  size="large"
                ></IonIcon>
              </Tooltip>
            }
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default connect<OwnProps, DispatchProps>({
  component: React.memo(withRouter(TopupFormWifiID)),
});
