import { IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import React from "react";
import QRCode from "react-qr-code";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
interface OwnProps {
  THD: any;
}
interface StateProps {}
interface DispatchProps {}
interface TopupTransactionManageProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const TopupTransactionManage: React.FC<TopupTransactionManageProps> = ({
  history,
  THD,
}) => {
  return (
    <>
    <div
      hidden={
        THD.status === "success" ||
        (THD.productType === "TokenPLN" && THD.status === "success")
          ? false
          : true
      }
    >
      <p>
        <br />
      </p>
      <IonText className="ion-margin">
        <small>
          <b>Atur Pesanan</b>
        </small>
      </IonText>
      <IonGrid className="ion-margin-top white-bg">
        <IonRow
          className="ion-align-items-center ion-ml-8"
          hidden={
            THD.productType === "TokenPLN" && THD.status === "success"
              ? false
              : true
          }
        >
          <IonCol>
            <IonText color="dark">
              <small>Cetak</small>
            </IonText>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <IonIcon
              icon={chevronForwardOutline}
              color="medium"
              size="large"
            ></IonIcon>
          </IonCol>
        </IonRow>
        <IonRow
          className="ion-align-items-center  ion-ml-8"
          hidden={THD.status !== "success"}
        >
          <IonCol>
            <IonText color="dark">
              <small>Cetak Kecil</small>
            </IonText>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <IonIcon
              icon={chevronForwardOutline}
              color="medium"
              size="large"
            ></IonIcon>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
    <IonGrid hidden={
        THD.status === "success"
      }>
      <IonRow className="ion-align-items-center">
              <IonCol
                size="12"
                hidden={
                  THD.paymentDetail &&
                  THD.paymentDetail.paymentMethod.toLowerCase() ===
                    "qris"
                    ? false
                    : true
                }
                class="ion-text-center"
              >
                <img
                  src={`assets/img/payment/${
                    THD.paymentDetail &&
                    THD.paymentDetail.paymentMethod
                  }.png`}
                />
                <br />
                {
                  THD.paymentDetail && THD.paymentDetail != null ? 
                  <QRCode
                    value={
                      THD.paymentDetail &&
                      THD.paymentDetail.paymentCode||null
                    }
                  />
                  :""
                }
              </IonCol>
      </IonRow>
    </IonGrid>
    </>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  component: React.memo(withRouter(TopupTransactionManage)),
});
