import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonText,
  IonTextarea,
  IonToggle,
  isPlatform,
  useIonViewWillEnter,
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React, {  useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppId, MainUrl } from "../../AppConfig";
import {
  loadAirlineBookingDataBundleData,
  loadAirlineOrderPassengersData,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import HotelOrderBuyerDataItem from "./HotelOrderBuyerDataItem";

interface OwnProps {
  GuestRooms?: any;
  setHotelOrderGuest: any;
}
interface StateProps {}
interface DispatchProps {}
interface HotelOrderBuyerDataProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const HotelOrderBuyerData: React.FC<HotelOrderBuyerDataProps> = ({
  history,
  GuestRooms,
  setHotelOrderGuest,
}) => {
  const SmokingRoomChange = (indexRoom, checkstatus) => {
    let HOG: any = localStorage.getItem("HotelOrderGuest") || null;
    HOG = JSON.parse(HOG || "");
    HOG.Prebook.rooms[indexRoom].isSmokingRoom = checkstatus;
    localStorage.setItem("HotelOrderGuest", JSON.stringify(HOG));
    setHotelOrderGuest(HOG);
  };
  const SpecialRequestArrayChange = (indexRoom, indexRequest, checkstatus) => {
    let HOG: any = localStorage.getItem("HotelOrderGuest") || null;
    HOG = JSON.parse(HOG || "");
    HOG.Prebook.rooms[indexRoom].specialRequestArray[indexRequest].isChecked =
      checkstatus;
    localStorage.setItem("HotelOrderGuest", JSON.stringify(HOG));
    setHotelOrderGuest(HOG);
  };
  // const SpecialRequestChange = (indexRoom, text) => {
  //   let HOG: any = localStorage.getItem("HotelOrderGuest") || null;
  //   HOG = JSON.parse(HOG || "");
  //   HOG.Prebook.rooms[indexRoom].requestDescription = text;
  //   localStorage.setItem("HotelOrderGuest", JSON.stringify(HOG));
  //   setHotelOrderGuest(HOG);
  // };
  return (
    <div>
      {GuestRooms !== null ? (
        GuestRooms.map((room, indexRoom) => (
          <div>
            <IonText class="ion-padding" color="dark">
              <small>Data Tamu Kamar {indexRoom + 1}</small>
            </IonText>
            {room.paxes && room.paxes.length > 0
              ? room.paxes.map((pax, indexPax) => (
                  <HotelOrderBuyerDataItem
                    indexRoom={indexRoom}
                    indexPax={indexPax}
                    pax={pax}
                    key={indexPax}
                    setHotelOrderGuest={(data) => {
                      setHotelOrderGuest(data);
                    }}
                  ></HotelOrderBuyerDataItem>
                ))
              : ""}
            <IonCard hidden>
              <IonItem>
                <IonLabel>
                  Smoking Room
                  {/* {JSON.stringify(checked)} */}
                </IonLabel>
                <IonToggle
                  checked={room.isSmokingRoom || false}
                  onIonChange={(e) =>
                    SmokingRoomChange(indexRoom, e.detail.checked)
                  }
                />
              </IonItem>
            </IonCard>
            <div
              className="ion-margin-top"
              // hidden={room.specialRequestArray === null}
              hidden
            >
              <IonLabel color="dark" className="ion-margin-start">
                Special Request
              </IonLabel>
              {room.specialRequestArray
                ? room.specialRequestArray.map((request, indexRequest) => (
                    <IonCard>
                      <IonItem>
                        <IonLabel>
                          {request.Description}
                          {/* {JSON.stringify(checked)} */}
                        </IonLabel>
                        <IonToggle
                          checked={request.isChecked}
                          onIonChange={(e) =>
                            SpecialRequestArrayChange(
                              indexRoom,
                              indexRequest,
                              e.detail.checked
                            )
                          }
                        />
                      </IonItem>
                    </IonCard>
                  ))
                : ""}
            </div>
          </div>
        ))
      ) : (
        <>
          <p></p>
          <IonText color="danger" className="ion-margin-start">
            ⚠️ Lengkapi Data Pemesanan Terlebih Dahulu
          </IonText>
        </>
      )}

    </div>

  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(withRouter(HotelOrderBuyerData)),
});
