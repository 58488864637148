import { IonText } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from '../data/connect';
import { Collapse } from "antd";
const { Panel } = Collapse;

interface OwnProps {
  isHidden:any
  Method:string
};
interface StateProps { };
interface DispatchProps {}
interface TransactionManageProps extends OwnProps, StateProps, DispatchProps,RouteComponentProps { };
const PaymentGuide: React.FC<TransactionManageProps> = ({
  isHidden,Method}) => {
  return (
    <div hidden={isHidden}>
      <IonText
            className="ion-margin"
            
          >
            <small>
              <b>Panduan Pembayaran</b>
            </small>
          </IonText>
          {/* Mandiri */}
          <div
            hidden={Method === "Mandiri VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via Mandiri ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih menu <IonText color="primary"><strong>Bayar/Beli</strong></IonText></li>
                    <li>Pilih menu <IonText color="primary"><strong>Multipayment</strong></IonText></li>
                    <li>Input kode perusahaan <IonText color="primary"><strong>89022</strong></IonText> <strong>(DOKU Merchant)</strong> tekan Benar</li>
                    <li>Input nomor virtual account kemudian tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>Input Nominal transaksi sesuai tagihan</li>
                    <li>Pilih <IonText color="primary"><strong>Nomor 1</strong></IonText> atau sesuai tagihan yang akan dibayar lalu tekan <IonText color="primary"><strong>YA</strong></IonText></li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                  <li>Input User ID & Password</li>
                  <li>Pilih menu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                  <li>Pilih menu <IonText color="primary"><strong>Multipayment</strong></IonText></li>
                  <li>Pada Kolom Penyedia Jasa, pilih <strong>DOKU Merchant</strong> <IonText color="primary"><strong>89022</strong></IonText></li>
                  <li>Input nomor virtual account kemudian pilih <IonText color="primary"><strong>Lanjut</strong></IonText></li>
                  <li>Input Nominal transaksi sesuai tagihan, kemudian pilih Lanjut</li>
                </ul>
                <strong>Web:</strong>
                <ol type="a">
                    <li>Centang pada bagian Total Tagihan</li>
                    <li>Input Pin Mandiri Appli 1 dari Token lalu Kirim</li>
                </ol>
                <strong>Apps:</strong>
                <ol type="a">
                    <li>Setelah muncul konfirmasi transaksi input MPIN (6digit)</li>
                    <li>OK</li>
                </ol>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via Teller Mandiri`
              }
              key="1"
              
            >
              <div>
                <ul>
                  <li>Pilih form setoran/transfer/kliring/inkaso/Multipayment</li>
                  <li>Beri tanda pada kolom:</li>
                  <li>a. “Setoran” ke rekening sendiri (jika tunai)</li>
                  <li>b. “Transfer” (jika dari rekening)</li>
                  <li>Beri tanda pada kolom “Penduduk” jika WNI</li>
                  <li>Isi nama pengirim sesuai nama penyetor</li>
                  <li>Isi nama penerima / nama Penyedia Jasa (Jika di form Multipayment) dengan <strong>DOKU Merchant</strong> <IonText color="primary"><strong>(89022)</strong></IonText></li>
                  <li>Isi nama Bank dengan Bank Mandiri</li>
                  <li>Pada kolom rekening diisi dengan nomor virtual account</li>
                  <li>Beri tanda pada sumber dana (tunai/debet rekening)</li>
                  <li>Isi nominal pembayaran sesuai tagihan</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>008</strong></IonText> (Bank Mandiri)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* CIMB VA */}
          <div
            hidden={Method === "CIMB VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via CIMB NIAGA ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Antar Rekening CIMB NIAGA</strong></IonText></li>
                    <li>Input nomor virtual account di kolom rekening tujuan</li>
                    <li>Input nominal transfer sesuai tagihan</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via CIMB CLICKS`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Input nominal transfer</li>
                    <li>Pilih <IonText color="primary"><strong>Other Account – Bank CIMB Niaga / Rekening Ponsel</strong></IonText></li>
                    <li>Rekening penerima diisi dengan nomor virtual account</li>
                    <li>Input nominal transfer sesuai tagihan</li>
                    <li>Input mPin</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via GO MOBILE`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Transfer to Other CIMB Niaga Account</strong></IonText></li>
                    <li>Input nomor virtual account sebagai rekening tujuan dan nominal pembayaran sesuai tagihan</li>
                    <li>Input PIN</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>002</strong></IonText> (CIMB Niaga)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* DANAMON VA */}
          <div
            hidden={Method === "Danamon VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih menu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Virtual Account</strong></IonText></li>
                    <li>Input nomor virtual account lalu pilih Benar</li>
                    <li>Input nominal yang akan dibayarkan sesuai tagihan</li>
                    <li>Apabila data telah sesuai pilih Ya</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via INTERNET BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Masukkan <IonText color="primary"><strong>User ID & Password</strong></IonText></li>
                    <li>Pilih menu <IonText color="primary"><strong>Virtual Account</strong></IonText></li>
                    <li>Input nomor virtual account</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Input PIN dan Klik Konfirmasi</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>011</strong></IonText> (DANAMON)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* Arta Jasa VA */}
          <div
            hidden={Method === "Arta Jasa VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Masukkan Kartu debit berlogo ATM Bersama</li>
                    <li>Pilih menu <IonText color="primary"><strong>Transfer ke Bank Lain</strong></IonText></li>
                    <li>Masukkan kode bank <IonText color="primary"><strong>987</strong></IonText> (ATMBPLUS) <IonText color="primary"><strong>dan nomor virtual account</strong></IonText></li>
                    <li>Masukkan <IonText color="primary"><strong>nominal pembayaran</strong></IonText> sesuai tagihan</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via INTERNET BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Masuk ke aplikasi Bank Anggota ATM Bersama</li>
                    <li>Pilih menu <IonText color="primary"><strong>Transfer ke Bank Lain</strong></IonText></li>
                    <li>Masukkan kode bank <IonText color="primary"><strong>987</strong></IonText> (ATMBPLUS) <IonText color="primary"><strong>dan nomor virtual account</strong></IonText></li>
                    <li>Masukkan <IonText color="primary"><strong>nominal pembayaran</strong></IonText> sesuai tagihan</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* Maybank VA */}
          <div
            hidden={Method === "Maybank VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Virtual Account</strong></IonText></li>
                    <li>Input nomor virtual account</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Lalu tekan Benar</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via MOBILE BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                  <li>Kirim SMS ke 69811 dengan format TRANSFER &lt;nomor rekening sumber dana&gt; &lt;Input nomor virtual account&gt; &lt;Input Nominal yang akan dibayarkan sesuai tagihan&gt;</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via INTERNET BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih menu fund transfer</li>
                    <li>Pilih <IonText color="primary"><strong>Virtual Account</strong></IonText></li>
                    <li><IonText color="primary"><strong>Pilih sumber rekening</strong></IonText></li>
                    <li>Input nomor virtual account</li>
                    <li>Input nominal Pembayaran sesuai tagihan</li>
                    <li>Input nomor Token (TAC)</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>016</strong></IonText> (MAYBANK)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* BNI VA */}
          <div
            hidden={Method === "BNI VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                  <li>Menu <IonText color="primary"><strong>Lainnya</strong></IonText></li>
                  <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                  <li>Pilih <IonText color="primary"><strong>Rekening Tabungan</strong></IonText></li>
                  <li>Pilih <IonText color="primary"><strong>Ke Rekening BNI</strong></IonText></li>
                  <li>Input nomor virtual account</li>
                  <li>Input nominal transfer</li>
                  <li>Konfirmasi pemindahbukuan</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via MOBILE BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                  <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                  <li>Pilih <IonText color="primary"><strong>Antar Rekening BNI</strong></IonText> dan <IonText color="primary"><strong>Input Rekening Baru</strong></IonText></li>
                  <li>Input rekening debet, nomor virtual account di rekening tujuan, dan nominal transfer</li>
                  <li>Input password dan konfirmasi transaksi</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via INTERNET BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Rekening Bank Lain</strong></IonText></li>
                    <li>Input nomor virtual account pada rekening tujuan</li>
                    <li>Input nominal pembayaran</li>
                    <li>Input bank penerima dengan BNI</li>
                    <li>Jenis transfer yang digunakan adalah online/realtime</li>
                    <li>Konfirmasi dan proses pembayaran</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>009</strong></IonText> (BNI)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via TELLER BNI`
              }
              key="1"
              
            >
              <div>
                <strong>SETORAN TUNAI</strong>
                <p>Isi <IonText color="primary"><strong>Formulir Setoran Rekening</strong></IonText></p>
                <ul>
                    <li>Pilih setoran tunai</li>
                    <li>Input nominal setoran, nama pemilik rekening dan nomor virtual account</li>
                    <li>Input nama dan tanda tangan penyetor</li>
                </ul>
                <strong>PEMINDAHBUKUAN</strong>
                <p>Isi <IonText color="primary"><strong>Formulir Pemindahbukuan Rekening</strong></IonText></p>
                <ul>
                    <li>Input nama penerima, nomor virtual account, cabang penerima, dan nominal pembayaran</li>
                    <li>Input nama, nomor rekening dan cabang penyetor</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via SMS BANKING BNI`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Daftar Transfer - Tambah</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Jenis Tujuan ke BNI Virtual Account</strong></IonText></li>
                    <li>Input nomor virtual account dan nominal transfer</li>
                    <li>Klik YES dan SEND</li>
                    <li>Reply sms dengan ketik pin digit ke 2 dan 6</li>
                </ul>
                <strong>Atau</strong>
                <p>Ketik SMS ke 3346 dengan format:</p>
                <p>TRF (spasi) Nomor VA BNI (spasi) Nominal</p>
              </div>
              </Panel>
          </Collapse>
          </div>
          {/* BRI VA */}
          <div
            hidden={Method === "BRI VA" ? false : true}
            >
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Menu <IonText color="primary"><strong>Transaksi Lainnya</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>Lainnya</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>BRIVA</strong></IonText></li>
                    <li>Input nomor virtual account</li>
                    <li>Input nominal yang harus dibayar</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>YA</strong></IonText></li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via MOBILE BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih <IonText color="primary"><strong>Mobile Banking BRI</strong></IonText></li>
                    <li>Pilih menu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih submenu <IonText color="primary"><strong>BRIVA</strong></IonText></li>
                    <li>Input nomor virtual account</li>
                    <li>Input nominal yang harus dibayar</li>
                    <li>Input PIN</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via INTERNET BANKING`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih menu <IonText color="primary"><strong>Pembayaran Tagihan</strong></IonText></li>
                    <li>Pilih submenu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih <IonText color="primary"><strong>BRIVA</strong></IonText></li>
                    <li>Input nomor virtual account pada kolom kode bayar lalu kirim</li>
                    <li>Input nominal Pembayaran pada kolom <IonText color="primary"><strong>Jumlah</strong></IonText> (untuk open payment) lalu klik <IonText color="primary"><strong>kirim</strong></IonText></li>
                    <li>Apabila data sudah sesuai masukkan <IonText color="primary"><strong>Password</strong></IonText> dan <IonText color="primary"><strong>mToken</strong></IonText> lalu kirim</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via ATM BANK LAIN / Mobile Banking / Online Banking`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih Transaksi Lainnya</li>
                    <li>Pilih <IonText color="primary"><strong>Transfer</strong></IonText></li>
                    <li>Pilih ke <IonText color="primary"><strong>Rek Bank Lain</strong></IonText></li>
                    <li>Masukkan kode Bank <IonText color="primary"><strong>002</strong></IonText> (BRI)</li>
                    <li>Input nomor virtual account sebagai rekening tujuan</li>
                    <li>Input nominal pembayaran sesuai tagihan</li>
                    <li>Pilih sumber rekening pembayaran</li>
                    <li>Apabila data sudah sesuai lalu tekan <IonText color="primary"><strong>Benar</strong></IonText></li>
                    <li>
                        <IonText color="primary"><strong>NOTE:</strong></IonText> Jika melalui M-BCA / klikBCA, maka nomor rekening harus di
                        daftarkan terlebih dahulu sesuai informasi diatas. (Bukan melalui menu
                          <IonText color="primary"><strong> BCA VIRTUAL ACCOUNT</strong></IonText>)
                    </li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via TELLER BRI`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Isi slip setoran Ops-01/Ops-02</li>
                    <li>Nasabah menyerahkan uang kepada Teller BRI</li>
                    <li>Teller akan melakukan pembukaan transaksi virtual account</li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={[""]}
            style={{}}
            // key={AOSIItem}
          >
            <Panel
            className="white-bg"
              header={
                `Via EDC BRI`
              }
              key="1"
              
            >
              <div>
                <ul>
                    <li>Pilih menu <IonText color="primary"><strong>Mini ATM</strong></IonText></li>
                    <li>Pilih menu <IonText color="primary"><strong>Pembayaran</strong></IonText></li>
                    <li>Pilih menu <IonText color="primary"><strong>BRIVA</strong></IonText></li>
                    <li>Swipe kartu ATM</li>
                    <li>Input nomor virtual account, apabila sudah sesuai tekan <IonText color="primary"><strong>Lanjut</strong></IonText></li>
                    <li>Input nominal pembayaran</li>
                    <li>Konfirmasi jumlah pembayaran, dipastikan data sudah sesuai tekan <IonText color="primary"><strong>Lanjut</strong></IonText></li>
                </ul>
              </div>
              </Panel>
          </Collapse>
          </div>
    </div>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
  }),
  component: React.memo(withRouter(PaymentGuide))
});
