import React, { useEffect, useState } from "react";
import {
    IonBackButton,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonRow,
    IonCol,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonText,
    IonLoading,
    IonAlert,
    isPlatform,
    IonCard,
    IonCardContent,
    IonGrid,
    IonIcon,
    IonSkeletonText,
    IonListHeader,
    IonThumbnail,

  } from "@ionic/react";
  import {
    musicalNotes,
    print
  } from "ionicons/icons";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { BluetoothSerial } from "@awesome-cordova-plugins/bluetooth-serial"; 
import {commands} from "../helpers/printer-commands";
import EscPosEncoder from 'esc-pos-encoder-ionic';
import { setpairedPrintDevice } from "../data/app/app.actions";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  photo?: string;
  pairedPrintDevice?: string;
}

interface DispatchProps {
  setpairedPrintDevice: typeof setpairedPrintDevice;
}
interface PrinterSettingProps extends OwnProps,pairedList, DispatchProps, StateProps {}

interface pairedList{
  "class":number,
  "id":string,
  "address":string,
  "name":number,
}

const PrinterSetting: React.FC<PrinterSettingProps> = ({
  photo,
  pairedPrintDevice,
  setpairedPrintDevice
}) => {
  const [pairedDevices, setPairedDevices] = useState<any>(undefined);
  const [bluetoothEnabled, setbluetoothEnabled] = useState(false);
  const [deviceConnected, setdeviceConnected] = useState(false);
  const [pairedDeviceId, setpairedDeviceId] = useState<string>(pairedPrintDevice||"");
  const [loaded, setLoaded] = useState(false);
  
  const [imagePath, setImagePath] = useState<string>(
    photo || "assets/img/defaultava.jpg"
  );

  const TurnOnBluetooth = () => {
    BluetoothSerial.enable().then(success => {
      isBluetoothEnable()
    }, error => {
      setbluetoothEnabled(false)
    })
  };

  const isBluetoothEnable = () => {
    BluetoothSerial.isEnabled().then(success => {
      listPairedDevices()
      // listDiscoverUnpairedDevices()
      setbluetoothEnabled(true)
    }, error => {
      setbluetoothEnabled(false)
      TurnOnBluetooth()
    });
  };

  const listPairedDevices = () => {
    BluetoothSerial.list().then(success => {
      setPairedDevices(success)
      setLoaded(true)
    }, error => {
      setbluetoothEnabled(false)
    })
  };

  const listDiscoverUnpairedDevices = () => {
    BluetoothSerial.discoverUnpaired().then(success => {
      setPairedDevices(success)
      setLoaded(true)
    }, error => {
      setbluetoothEnabled(false)
    })
  };

  const selectDevice = (id) => {
    setpairedDeviceId(id)
    setpairedPrintDevice(id)
    printTest()
  };

  const printTest = () => {
    const encoder = new EscPosEncoder();
    const result = encoder.initialize();

    // let img = new Image();
    // img.src = imagePath;
    // img.onload = function() {
    // }
    result
      .codepage('cp936')
      .align('center')
      .raw(commands.TEXT_FORMAT.TXT_4SQUARE)
      // .image(img, 300, 300, 'atkinson')
      // .line(imagePath)
      .raw(commands.TEXT_FORMAT.TXT_NORMAL)
      .newline()
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("TRXID")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("030646113")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Tanggal Transaksi")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("01 August 2022 13:15")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Kode Voucher")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("2|HSDI5")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Nama Voucher")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("AS 5000")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("MSISDN")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("as 5000")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("VSN")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("")
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Harga Jual")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
      .newline()
      .text("9000.00")
      .raw(commands.TEXT_FORMAT.TXT_NORMAL)
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Status Transaksi")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_2HEIGHT)
      .text("success")
      .raw(commands.TEXT_FORMAT.TXT_NORMAL)
      .newline()
      .raw(commands.TEXT_FORMAT.TXT_BOLD_ON)
      .text("Message")
      .raw(commands.TEXT_FORMAT.TXT_BOLD_OFF)
      .newline()
      .text("Trx 2|HSDI5 5000 berhasil ditambahkan. TrxID: 030646113")
      .newline()
      .newline()
      .size("small")
      .text("Tanggal Cetak : 13 Oktober 2022 14.45")
      .newline()
      .newline()
      .newline()

      
      BluetoothSerial.connect(pairedDeviceId).subscribe((success) => {
        console.log(success)
        BluetoothSerial.write(result.encode()).then((printStatus) => {
          console.log(printStatus)
          BluetoothSerial.disconnect()
        }, error => {
          alert("Error: Print to Device")
        })
      }, error => {
        alert("Error: Connecting to Device")
      })

  };
  
  useEffect(() => {
    
    TurnOnBluetooth()

  }, [pairedDevices,BluetoothSerial]);
    return (
        <IonPage id="forgotpassword-page">
          <IonToolbar className="center-title-toolbar">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Printer</IonTitle>
          </IonToolbar>
          
          {loaded && 
            <IonList>
              {
                pairedDevices ?
                pairedDevices.map((pd, pdIndex) =>
                <IonItem
                onClick={() => selectDevice(pd.id)}
                color={pairedDeviceId && pairedDeviceId === pd.id ? "success": "" }
                >
                  <IonThumbnail slot="start">
                    <IonIcon style={{ 'width': '100%', 'height': '100%' }} icon={print}></IonIcon>
                  </IonThumbnail>
                  <IonLabel>
                    <h3>{pd.name}</h3>
                    <p>{pd.address}</p>
                  </IonLabel>
                  <IonLabel className="ion-text-right">
                    <h3></h3>
                    {pairedDeviceId && pairedDeviceId === pd.id ? 
                    <IonText color="primary"><p>Tes Printer</p></IonText>
                    :
                    <IonText color="primary"><p>Hubungkan</p></IonText>
                    }
                  </IonLabel>
                </IonItem>
                )
                : "" 
              }
            </IonList>
          }
          {!loaded &&
            <IonList>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                  </p>
                </IonLabel>
                <IonLabel className="ion-text-right">
                  <h3></h3>
                  <p><IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText></p>
                </IonLabel>
              </IonItem>
            </IonList>
          }
          {
            <IonAlert
              isOpen={!bluetoothEnabled}
              onDidDismiss={() => BluetoothSerial.enable()}
              header="Bluetooth belum aktif"
              message="Aktifkan bluetooth untuk terhubung dengan printer"
              buttons={["Baik"]}
            />
          }
        </IonPage>
      );
  }

  export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
      photo: state.user.photo,
      pairedPrintDevice: state.data.pairedPrintDevice,
    }),
    mapDispatchToProps: {
      setpairedPrintDevice
    },
    component: PrinterSetting,
  });
  