import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  isPlatform,
  IonLoading,
  IonAlert,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
// import { AppId, MainUrl } from '../../AppConfig';
// import { rupiah } from '../../helpers/currency';
// import { HTTP } from '@ionic-native/http';
import AirlineSearchFrom from "../../components/AirlineSearch/AirlineSearchForm";
import { HTTP } from "@ionic-native/http";
import { AppId, MainUrl } from "../../AppConfig";
import { setAirlineFlightJourney } from "../../data/airline/airline.actions";
import { stringDateConvertDashSeparate } from "../../helpers/datetime";

interface OwnProps {
  SearchUpdate:any;
}
interface StateProps {
  AirlineBooking: any;
  UserData: any;
}
interface DispatchProps {
  setAirlineFlightJourney: typeof setAirlineFlightJourney;
}
interface SearchUpdateButtonProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const SearchUpdateButton: React.FC<SearchUpdateButtonProps> = ({
  AirlineBooking,
  UserData,
  history,SearchUpdate,
  setAirlineFlightJourney,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  const AirlineBookingSubmit = () => {
    setAirlineFlightJourney(undefined);
    setShowLoading(true);
    SearchUpdate();
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
    };
    var MyData = new FormData();
    MyData.append("airlineAccessCode", "null");
    MyData.append("airlineDetail", "Semua Maskapai");
    // MyData.append("airlineFlightClass", "null");
    MyData.append("airlineID", "ALL");
    MyData.append(
      "departDate",
      stringDateConvertDashSeparate(AirlineBooking.AirlineBookingDepartureDate)
    );
    MyData.append("destination", AirlineBooking.AirlineBookingDestination);
    MyData.append(
      "destinationDetail",
      AirlineBooking.AirlineBookingDestinationDetail
    );
    MyData.append("origin", AirlineBooking.AirlineBookingOrigin);
    MyData.append("originDetail", AirlineBooking.AirlineBookingOriginDetail);
    MyData.append("paxAdult", AirlineBooking.AirlineBookingNumberOfAdult);
    MyData.append("paxChild", AirlineBooking.AirlineBookingNumberOfChild);
    MyData.append("paxInfant", AirlineBooking.AirlineBookingNumberOfInfant);
    MyData.append("progressPercent", "0");
    MyData.append("promoCode", "null");
    MyData.append(
      "returnDate",
      AirlineBooking.AirlineBookingTripType === "RoundTrip"
        ? stringDateConvertDashSeparate(
            AirlineBooking.AirlineBookingArrivalDate
          )
        : "null"
    );
    MyData.append("tripType", AirlineBooking.AirlineBookingTripType);
    MyData.append("accToken", UserData.accessToken);
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("multipart");
      HTTP.post(MainUrl + "Airline/ScheduleAll", MyData, MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            alert("Periksa Koneksi anda");
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          AirlineBookingSubmitSuccess(res);
        })
        .catch((err) => {
          failedAlert(JSON.stringify(err));
        });
    } else {
      fetch(MainUrl + "Airline/ScheduleAll", {
        method: "POST",
        headers: MyHeaders,
        body: MyData,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            failedAlert("Periksa Koneksi Anda");
          }
        })
        .then((res) => {
          AirlineBookingSubmitSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const AirlineBookingSubmitSuccess = async (res: any) => {
    if (
      (res.StatusCode == 200 &&
        res.Data &&
        res.Data.totalAirline &&
        res.Data.totalAirline > 0)
    ) {
      localStorage.setItem('StoredFlightData','null');
      localStorage.setItem('AirlineSearchPercentage','0');
      for (let i = 0; i < res.Data.totalAirline; i++) {
        getAirline(res.Data.XTKN);
      }
      localStorage.removeItem("AirlineOrderBaggageSelected");
      localStorage.removeItem("AirlineOrderOrderPerson");
      setShowLoading(false);
      history.push("/airlineSearchFirstFlight");
    } else {
      failedAlert("Penerbangan tidak ditemukan");
    }
  };
  const getAirline = (XTKN) => {
    if (!UserData.accessToken) {
      failedAlert("Login Terlebih Dahulu");
      history.push("/login");
      return;
    }
    var MyHeaders = {
      appid: AppId,
      RequestVerificationToken: UserData.requestVerificationToken,
    };
    var MyData = new FormData();
    MyData.append("airlineAccessCode", "null");
    MyData.append("airlineDetail", "Semua Maskapai");
    // MyData.append("airlineFlightClass", "null");
    MyData.append("airlineID", "ALL");
    MyData.append(
      "departDate",
      stringDateConvertDashSeparate(AirlineBooking.AirlineBookingDepartureDate)
    );
    MyData.append("destination", AirlineBooking.AirlineBookingDestination);
    MyData.append(
      "destinationDetail",
      AirlineBooking.AirlineBookingDestinationDetail
    );
    MyData.append("origin", AirlineBooking.AirlineBookingOrigin);
    MyData.append("originDetail", AirlineBooking.AirlineBookingOriginDetail);
    MyData.append("paxAdult", AirlineBooking.AirlineBookingNumberOfAdult);
    MyData.append("paxChild", AirlineBooking.AirlineBookingNumberOfChild);
    MyData.append("paxInfant", AirlineBooking.AirlineBookingNumberOfInfant);
    MyData.append("progressPercent", "0");
    MyData.append("promoCode", "null");
    MyData.append(
      "returnDate",
      AirlineBooking.AirlineBookingTripType === "RoundTrip"
        ? stringDateConvertDashSeparate(
            AirlineBooking.AirlineBookingArrivalDate
          )
        : "null"
    );
    MyData.append("tripType", AirlineBooking.AirlineBookingTripType);
    MyData.append("accToken", UserData.accessToken);
    MyData.append("xTKN", XTKN);
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("multipart");
      HTTP.setRequestTimeout(180);
      HTTP.post(MainUrl + "Airline/ScheduleAll", MyData, MyHeaders)
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              alert("Periksa Koneksi anda");
            }
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          getAirlineSuccess(res);
        })
        .catch((err) => {
          failedAlert("Session telah habis, silahkan login ulang");
          history.push("/login");
        });
    } else {
      fetch(MainUrl + "Airline/ScheduleAll", {
        method: "POST",
        headers: MyHeaders,
        body: MyData,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            if (r.status === 401) {
              failedAlert("Session telah habis, silahkan login ulang");
              history.push("/login");
            } else {
              failedAlert(r.statusText);
            }
            return r.json();
          }
        })
        .then((res) => {
          getAirlineSuccess(res);
        })
        .catch((err) => {
          failedAlert("Periksa Koneksi Internet");
        });
    }
  };
  const getAirlineSuccess = async (res: any) => {


    if (
      (res.StatusCode == 200 &&
        res.Data &&
        res.Data.LowFareJourneyDepart &&
        res.Data.LowFareJourneyDepart.length > 0) ||
      (AirlineBooking.AirlineBookingTripType === "RoundTrip" &&
        res.StatusCode == 200 &&
        res.Data &&
        res.Data.LowFareJourneyReturn &&
        res.Data.LowFareJourneyReturn.length > 0)
    ) {
        let SF:any = null;
        let StoredFlightData:any = JSON.parse(localStorage.getItem('StoredFlightData')||'');

        if(StoredFlightData){
          SF = StoredFlightData;
          const SFD = SF.Departure;
          const SFR = SF.Return;
          if(res.Data.LowFareJourneyDepart){
            SF.Departure = SFD.concat(res.Data.LowFareJourneyDepart);
          }
          if(res.Data.LowFareJourneyReturn){
            SF.Return = SFR.concat(res.Data.LowFareJourneyReturn);
          }
        }else{
          SF = {
            Departure: res.Data.LowFareJourneyDepart,
            Return: res.Data.LowFareJourneyReturn,
          };
        }
        localStorage.setItem('StoredFlightData',JSON.stringify(SF))
        await setAirlineFlightJourney(SF);
    }
    const asp = parseFloat(localStorage.getItem('AirlineSearchPercentage')||'0');
        const pp = asp+(1/res.Data.totalAirline);
        localStorage.setItem('AirlineSearchPercentage',pp.toString());
  };
  return (
    <IonGrid className="ion-padding">
      <IonButton
        className="ion-margin-top text-transform-none"
        expand="block"
        size="large"
        // routerLink="/airlineSearchFirstFlight"
        onClick={() => AirlineBookingSubmit()}
      >
        Cari Tiket Pesawat
      </IonButton>
      <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerAlert}
        message={messageAlert}
        buttons={["OK"]}
      />
    </IonGrid>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineBooking: selectors.getAirlineBooking(state),
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {
    setAirlineFlightJourney,
  },
  component: React.memo(withRouter(SearchUpdateButton)),
});
