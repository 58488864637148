import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  arrowForward,
  chevronBackOutline,
  ellipse,
  filter,
  funnel,
} from "ionicons/icons";
import Lottie from "lottie-react";
import dataNotFound from "../../Lotties/data-not-found-primary.json";
import loading from "../../Lotties/loading_4463.json";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import { AppId, MainUrl } from '../../AppConfig';
// import { HTTP } from '@ionic-native/http';
import AirlineSearchFrom from "../../components/AirlineSearch/AirlineSearchForm";
import AirlineSearchUpdateButton from "../../components/AirlineSearch/AirlineSearchUpdateButton";
import { setAirlineFlightDeparture } from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { rupiah } from "../../helpers/currency";
import { stringDateConvert } from "../../helpers/datetime";
import "./SearchFirstFlight.scss";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import { Slider } from "antd";
interface OwnProps {}
interface StateProps {
  AirlineBooking: any;
  UserData: any;
  AirlineFlightJourney: any;
  AirlineBookingAirlineAccessCode?: string;
}
interface DispatchProps {
  setAirlineFlightDeparture: typeof setAirlineFlightDeparture;
}
interface SearchFirstFlightProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const AirlineSearchFirstFlight: React.FC<SearchFirstFlightProps> = ({
  AirlineBooking,
  UserData,
  AirlineFlightJourney,
  setAirlineFlightDeparture,
  history,
  AirlineBookingAirlineAccessCode
}) => {
  //Filter
  const [ModalFilterIsOpen, setModalFilterIsOpen] = useState(false);
  const [FilterSortBy, setFilterSortBy] = useState<string>(`AscPrice`);
  const [FilterAirlineBy, setFilterAirlineBy] = useState<string>(`All`);
  const [isFilterActive, setisFilterActive] = useState(true);
  const [FilterAirlineDepartureList, setFilterAirlineDepartureList] = useState<any>([]);
  const [FilterTransit, setFilterTransit] = useState<string>(``);
  const [FilterDepartTimeTrip, setFilterDepartTimeTrip] = useState('');
  const [FilterArriveTimeTrip, setFilterArriveTimeTrip] = useState('');
  const [FilterPriceMin, setFilterPriceMin] = useState(0);
  const [FilterPriceMax, setFilterPriceMax] = useState(10000000);
  const [AirlineAccessCode, setAirlineAccessCode] = useState<string>(AirlineBookingAirlineAccessCode || "null");
  const [showAlertAirlineAccessCode, setshowAlertAirlineAccessCode] = useState(false);

  //End Filter
  const [airlineList, setairlineList] = useState<any>([]);
  const [airlineDepartureList, setairlineDepartureList] = useState<any>([]);

  const [AirlineSearchpercentage, setAirlineSearchpercentage] = useState(0);
  const [BottomDrawerIsOpen, setBottomDrawerIsOpen] = useState(false);
  const [BottomDrawerIsDestroy, setBottomDrawerIsDestroy] = useState(true);
  const [LoadingFinish, setLoadingFinish] = useState(false);
  const [BottomDrawerOpacityStyle, setBottomDrawerOpacityStyle] = useState({
    opacity: "0",
    "z-index": "-9999",
    display: "none",
  });
  const [BottomDrawerCardStyle, setBottomDrawerCardStyle] = useState({
    bottom: "-100vh",
  });
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };

  interface TimeFilter {
    id: number;
    name: string;
  }

  const timeFilters: TimeFilter[] = [
    {
      id: 1,
      name: '00:00 - 06:00',
    },
    {
      id: 2,
      name: '06:00 - 12:00',
    },
    {
      id: 3,
      name: '12:00 - 18:00',
    },
    {
      id: 4,
      name: '18:00 - 00:00',
    },
  ];

  const compareWithDepart = (o1: TimeFilter, o2: TimeFilter) => {
    if (!o1 || !o2) {
      if(FilterDepartTimeTrip !== ``){
        return JSON.parse(FilterDepartTimeTrip).some((o) => o.id === o1.id);
      }
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      if(FilterDepartTimeTrip !== ``){
        return JSON.parse(FilterDepartTimeTrip).some((o) => o.id === o1.id);
      }else{
        return o2.some((o) => o === o1);
      }
    }
    return o1 === o2;
  };
  const compareWithArrive = (o1: TimeFilter, o2: TimeFilter) => {
    if (!o1 || !o2) {
      if(FilterArriveTimeTrip !== ``){
        return JSON.parse(FilterArriveTimeTrip).some((o) => o.id === o1.id);
      }
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      if(FilterArriveTimeTrip !== ``){
        return JSON.parse(FilterArriveTimeTrip).some((o) => o.id === o1.id);
      }else{
        return o2.some((o) => o === o1);
      }
    }
    return o1 === o2;
  };

  useEffect(() => {
    if (BottomDrawerIsDestroy) {
      // open
      setBottomDrawerCardStyle({ bottom: "-100vh" });
      setBottomDrawerOpacityStyle({
        opacity: "0",
        "z-index": "9999",
        display: "block",
      });

      setTimeout(() => {
        setBottomDrawerIsOpen(false);
        setBottomDrawerOpacityStyle({
          opacity: "0",
          "z-index": "-9999",
          display: "none",
        });
      }, 500);
    } else {
      // close
      setBottomDrawerIsOpen(true);
      setBottomDrawerOpacityStyle({
        opacity: "0",
        "z-index": "9999",
        display: "block",
      });
      setTimeout(() => {
        setBottomDrawerCardStyle({ bottom: "0" });
        setBottomDrawerOpacityStyle({
          opacity: "1",
          "z-index": "9999",
          display: "block",
        });
      }, 100);
    }
    if(AirlineAccessCode !== "null"){
      setHeaderAlert("Masukkan kode verifikasi");
      setMessageAlert(`<img class="w-100" src="data:image/png;base64,${AirlineAccessCode}" alt="g-maps" style="border-radius: 2px">`);
      setshowAlertAirlineAccessCode(true);
    }
  }, [BottomDrawerIsDestroy,AirlineAccessCode]);
  useEffect(() => {
    if (AirlineFlightJourney) {
      setBottomDrawerIsDestroy(true);
    }
    if(AirlineFlightJourney && AirlineFlightJourney.Departure){
      setFilterAirlineDepartureList(AirlineFlightJourney.Departure)
      const airline = new Array();
      let tempAirline = "";
      AirlineFlightJourney.Departure.map((afj,afjIndex)=> {
        if(tempAirline != (afj.airlineName+`~`+afj.airlineID)){
          tempAirline = afj.airlineName+`~`+afj.airlineID;
          airline.push((afj.airlineName+`~`+afj.airlineID))
        }
      })
      setairlineList([...airline])
    }

    if(isFilterActive && FilterAirlineDepartureList && FilterAirlineDepartureList.length > 0 && (AirlineFlightJourney && AirlineFlightJourney.Departure)){
      let tempFilterAirlineDepartureList = AirlineFlightJourney.Departure
      //Filtering Transit
      if(FilterTransit === "direct"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter((x) => parseInt(x.segment.length) === 1)
      }
      else if(FilterTransit === "transit1"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter((x) => parseInt(x.segment.length) === 2)
      }
      else if(FilterTransit === "transit+2"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter((x) => parseInt(x.segment.length) > 2)
      }

      //Filtering Airline
      if(FilterAirlineBy != "All"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter((x) => x.airlineID.toLowerCase().includes(FilterAirlineBy.toLowerCase()))
      }

      //Filtering price range
      if(FilterPriceMin != 0 || FilterPriceMax != 10000000){
      tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter((x) => parseFloat(x.sumPrice) >= FilterPriceMin && parseFloat(x.sumPrice) <= FilterPriceMax)
      }

      //Filtering Time
      if(FilterArriveTimeTrip || FilterDepartTimeTrip){
        if(FilterDepartTimeTrip && FilterDepartTimeTrip.length > 0 && FilterDepartTimeTrip != "[]"){
          tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter(function(x){
            let validTime = new Date(x.jiDepartTime).getHours();
            let isShow = false
            let departTime = JSON.parse(FilterDepartTimeTrip)
            console.log(`departTime:`+JSON.stringify(departTime))
            for(let ii=0; ii < departTime.length;ii++){
              let value = JSON.stringify(departTime[ii].name).split(` - `)
              let min = parseInt(value[0].split(`:`)[0].replace(`"`,``));
              let max = parseInt(value[1].split(`:`)[0].replace(`"`,``));
              if (validTime >= min && validTime <= max) {
                isShow = true;
              }
            }
            return isShow
          })
        }
        if(FilterArriveTimeTrip && FilterArriveTimeTrip.length > 0 && FilterArriveTimeTrip != "[]"){
          tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.filter(function(x){
            let validTime = new Date(x.jiArrivalTime).getHours();
            let isShow = false
            let arriveTime = JSON.parse(FilterArriveTimeTrip)
            for(let ii=0; ii < arriveTime.length;ii++){
              let value = JSON.stringify(arriveTime[ii].name).split(` - `)
              let min = parseInt(value[0].split(`:`)[0].replace(`"`,``));
              let max = parseInt(value[1].split(`:`)[0].replace(`"`,``));
              if (validTime >= min && validTime <= max) {
                isShow = true;
              }
            }
            return isShow
          })
        }
      }
      //Filtering Sort
      if(FilterSortBy === "AscPrice"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return parseFloat(a.sumPrice) - parseFloat(b.sumPrice)})
      }else if(FilterSortBy === "DescPrice"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return parseFloat(b.sumPrice) - parseFloat(a.sumPrice)})
      }else if(FilterSortBy === "FirstDepart"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return (``+a.jiDepartTime).localeCompare(b.jiDepartTime)})
      }else if(FilterSortBy === "LastDepart"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return (``+b.jiDepartTime).localeCompare(a.jiDepartTime)})
      }else if(FilterSortBy === "FirstArrive"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return (``+a.jiArrivalTime).localeCompare(b.jiArrivalTime)})
      }else if(FilterSortBy === "LastArrive"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){return (``+b.jiArrivalTime).localeCompare(a.jiArrivalTime)})
      }else if(FilterSortBy === "Duration"){
        tempFilterAirlineDepartureList = tempFilterAirlineDepartureList.sort(function(a,b){
          let atimeStart = new Date(a.jiDepartTime).getTime();
          let atimeEnd = new Date(a.jiArrivalTime).getTime();
          let btimeStart = new Date(b.jiDepartTime).getTime();
          let btimeEnd = new Date(b.jiArrivalTime).getTime();
          let ahourDiff = atimeEnd - atimeStart;
          let bhourDiff = btimeEnd - btimeStart;
          return ahourDiff - bhourDiff
        })
      }

      setairlineDepartureList(tempFilterAirlineDepartureList)
    }
  }, [AirlineFlightJourney,FilterSortBy,isFilterActive,FilterTransit,FilterAirlineBy,FilterPriceMin,FilterPriceMax,FilterArriveTimeTrip,FilterDepartTimeTrip]);
  useIonViewDidEnter(() => {

    // if (localStorage.getItem("AirlineSearchPercentage") === null) {
    //   history.replace("/airlineSearch");
    // }
    if (!AirlineBooking.AirlineBookingOrigin) {
      history.replace("/airlineSearch");
    }
    else{
      SearchProgress();
    }

  });
  const SearchProgress = () =>{
    const ProgressInterval =  setInterval(()=>{
      const asp = parseFloat(localStorage.getItem('AirlineSearchPercentage')||'0');
      setAirlineSearchpercentage(asp);
      if(asp>=1){
        clearInterval(ProgressInterval);
        // localStorage.removeItem('AirlineSearchPercentage');
        setLoadingFinish(true);
      }
    },100)
  }
  const SearchUpdate = () =>{
    setLoadingFinish(false);
    setAirlineSearchpercentage(0);
    SearchProgress();
    setBottomDrawerIsDestroy(true)
  }
  // setFilterAirlineDepartureList(AirlineFlightJourney)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" className="">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/airlineSearch"
              icon={chevronBackOutline}
            ></IonBackButton>
          </IonButtons>
          <IonTitle className="ion-no-padding">
            <b>
              {AirlineBooking.AirlineBookingOrigin} -{" "}
              {AirlineBooking.AirlineBookingDestination}
            </b>
          </IonTitle>
          <IonTitle className="ion-sub-title ion-no-padding">
            {stringDateConvert(AirlineBooking.AirlineBookingDepartureDate)}{" "}
            <IonIcon icon={ellipse}></IonIcon>{" "}
            {AirlineBooking.AirlineBookingNumberOfPaxTotal} Orang
          </IonTitle>
          <IonButtons slot="end" className="ion-margin-end">
            <IonButton
              className="btn-outline-light text-transform-none"
              onClick={() => {
                setBottomDrawerIsDestroy(false);
              }}
            >
              Ubah
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="primary">
        <IonCard className="ion-p-8">
          <IonProgressBar color={AirlineSearchpercentage==1?"success":"primary"} value={AirlineSearchpercentage} buffer={AirlineSearchpercentage}></IonProgressBar>
        </IonCard>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} class="airlineSearch gray-bg">
        {airlineDepartureList && airlineDepartureList
          ?
          airlineDepartureList.map((item, index) => (
              <IonCard
                className="ion-p-8"
                key={index}
                onClick={async () => {
                  setAirlineFlightDeparture(item);
                  if (AirlineBooking.AirlineBookingTripType === "OneWay") {
                    history.push("/airlineFlightInformation");
                  } else {
                    history.push("/airlineSearchSecondFlight");
                  }
                }}
              >
                <IonGrid>
                  <IonRow>
                    <IonCol size="6">
                      {item.segment.map((segmentItem, segmentIndex) => (
                        <IonRow key={segmentIndex}>
                          <IonCol size="4">
                            {/* <img
                        src={
                          "assets/img/domestic-airlines/ic_maskapai_lion.png"
                        }
                        alt=""
                      /> */}
                            <img
                              src={
                                "assets/img/Airlines/" +
                                item.airlineID.toLowerCase() +
                                "/" +
                                segmentItem.flightDetail[0].airlineCode.toLowerCase() +
                                ".png"
                              }
                              alt=""
                            />
                          </IonCol>
                          <IonCol size="8" className="ion-align-self-center">
                            {segmentItem.flightDetail[0].airlineCode}&nbsp;
                            {segmentItem.flightDetail[0].flightNumber}
                          </IonCol>
                          <IonCol size="4">
                            <p className="ion-text-center">
                              <IonText>
                                {segmentItem.flightDetail[0].fdDepartTime.substring(
                                  11,
                                  16
                                )}
                              </IonText>
                              <br />
                              <IonBadge color="light">
                                <IonText color="medium">
                                  {segmentItem.flightDetail[0].fdOrigin}
                                </IonText>
                              </IonBadge>
                            </p>
                          </IonCol>
                          <IonCol size="4">
                            <img
                              src={"assets/icon/airlinehub.svg"}
                              alt=""
                              width="100%"
                            />
                          </IonCol>
                          <IonCol size="4">
                            <p className="ion-text-center">
                              <IonText>
                                {segmentItem.flightDetail[0].fdArrivalTime.substring(
                                  11,
                                  16
                                )}
                              </IonText>
                              <br />
                              <IonBadge color="light">
                                <IonText color="medium">
                                  {segmentItem.flightDetail[0].fdDestination}
                                </IonText>
                              </IonBadge>
                            </p>
                          </IonCol>
                        </IonRow>
                      ))}
                      <IonText color="primary">
                        <b>{rupiah(item.sumPrice)}</b>
                      </IonText>
                    </IonCol>
                    <IonCol className="ion-text-end ion-align-self-center">
                      <IonIcon
                        size="large"
                        color="primary"
                        icon={arrowForward}
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))
          :         <div>
            <Lottie animationData={dataNotFound} hidden={!LoadingFinish}/>
            <Lottie animationData={loading} hidden={LoadingFinish}/>
          </div>
        }
        <div className="bottomDrawer" hidden={!BottomDrawerIsOpen}>
          <div
            className="bottomDrawerOpacity"
            onClick={() => {
              setBottomDrawerIsDestroy(true);
            }}
            style={BottomDrawerOpacityStyle}
          ></div>
          <div className="bottomDrawerCard" style={BottomDrawerCardStyle}>
            <div
              className="bottomDrawerDragPad"
              onClick={() => {
                setBottomDrawerIsDestroy(true);
              }}
            ></div>
            <AirlineSearchFrom></AirlineSearchFrom>
            <AirlineSearchUpdateButton SearchUpdate={()=>{SearchUpdate()}}></AirlineSearchUpdateButton>
          </div>
        </div>
        <IonModal isOpen={ModalFilterIsOpen} backdropDismiss={false}>
          <IonContent>
          <DefaultToolbar
              title="Filter & Urutkan"
              color="primary"
              backButtonRoute={() => {
                setModalFilterIsOpen(false);
              }}
            />
            <IonItem>
              <IonLabel>Urutkan Berdasarkan</IonLabel>
              <IonSelect interface="action-sheet" value={FilterSortBy} onIonChange={(e) => setFilterSortBy(e.detail.value!)}>
                <IonSelectOption value="AscPrice">Harga Terendah</IonSelectOption>
                <IonSelectOption value="DescPrice">Harga Tertinggi</IonSelectOption>
                <IonSelectOption value="FirstDepart">Berangkat Paling Awal</IonSelectOption>
                <IonSelectOption value="LastDepart">Berangkat Paling Akhir</IonSelectOption>
                <IonSelectOption value="FirstArrive">Tiba Paling Awal</IonSelectOption>
                <IonSelectOption value="LastArrive">Tiba Paling Akhir</IonSelectOption>
                <IonSelectOption value="Duration">Durasi Tersingkat</IonSelectOption>
              </IonSelect>
            </IonItem>
            <div className="ion-padding">
              <IonLabel>Filter</IonLabel>
            </div>
            <IonItem>
              <IonLabel>Transit</IonLabel>
              <IonSelect interface="action-sheet" value={FilterTransit} onIonChange={(e) => setFilterTransit(e.detail.value!)}>
                <IonSelectOption value="direct">Langsung</IonSelectOption>
                <IonSelectOption value="transit1">1 Transit</IonSelectOption>
                <IonSelectOption value="transit+2">2+ Transit</IonSelectOption>
              </IonSelect>
            </IonItem>
            {/* <Select
            mode="multiple"
            placeholder="Berangkat"
            defaultValue={FilterDepartTimeTrip.length>0?FilterDepartTimeTrip:[]}
            style={{ width: "100%", marginBottom: "8px" }}
            optionLabelProp="label"
            onChange={(e) => {
              setFilterDepartTimeTrip(JSON.stringify(e.detail.value))
              }
            }
            >
              <Select.Option value="00:00 - 06:00" label="00:00 - 06:00">00:00 - 06:00</Select.Option>
              <Select.Option value="06:00 - 12:00" label="06:00 - 12:00">06:00 - 12:00</Select.Option>
              <Select.Option value="12:00 - 18:00" label="12:00 - 18:00">12:00 - 18:00</Select.Option>
              <Select.Option value="18:00 - 00:00" label="18:00 - 00:00">18:00 - 00:00</Select.Option>
            </Select> */}
            <IonItem>
              <IonLabel>Waktu</IonLabel>
              <IonSelect placeholder="Berangkat" compareWith={compareWithDepart}  multiple={true} onIonChange={(e) => setFilterDepartTimeTrip(JSON.stringify(e.detail.value))}>
                {
                  timeFilters.map((time) => (
                    <IonSelectOption value={time} key={time.id}>{time.name}</IonSelectOption>
                  ))
                }
              </IonSelect>
              <IonSelect placeholder="Tiba" multiple={true} compareWith={compareWithArrive} onIonChange={(e) => setFilterArriveTimeTrip(JSON.stringify(e.detail.value))}>
                {
                  timeFilters.map((time) => (
                    <IonSelectOption value={time} key={time.id}>{time.name}</IonSelectOption>
                  ))
                }
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>Maskapai</IonLabel>
              <IonSelect interface="action-sheet" value={FilterAirlineBy} onIonChange={(e) => setFilterAirlineBy(e.detail.value!)}>
                <IonSelectOption value="All">Pilih Semua</IonSelectOption>
                {
                  airlineList && airlineList.length > 0 ?
                  airlineList.map((AL,ALIndex) => (
                    <IonSelectOption key={AL+ALIndex} value={AL.split("~")[1]}>{AL.split("~")[0]}</IonSelectOption>
                  ))
                  : ""
                }
              </IonSelect>
            </IonItem>
            <IonRow>
              <IonCol size="12">
                <IonLabel position="stacked">Kisaran Harga</IonLabel>
              </IonCol>
              <IonCol>
                <IonInput value={rupiah(FilterPriceMin)} disabled></IonInput>
              </IonCol>
              <IonCol>
                <IonInput value={rupiah(FilterPriceMax)} disabled></IonInput>
              </IonCol>
              <IonCol size="12">
                <Slider
                  className="dt-slider"
                  range
                  step={1000}
                  min={0}
                  max={10000000}
                  value={[FilterPriceMin, FilterPriceMax]}
                  onChange={(v) => {
                    setFilterPriceMin(v[0]);
                    setFilterPriceMax(v[1]);
                  }}
                />
              </IonCol>
            </IonRow>
          </IonContent>
          <IonFooter>
            <IonRow>
              <IonCol size="6" className="ion-text-center">
                <IonButton
                className="ion-margin-top text-transform-none"
                color="light"
                expand="block"
                size="large"
                onClick={() => {
                  setFilterSortBy("AscPrice")
                  setisFilterActive(true)
                  setFilterTransit(``)
                  setFilterArriveTimeTrip('')
                  setFilterDepartTimeTrip('')
                }}
              >
                Reset
              </IonButton>
              </IonCol>
              <IonCol size="6" className="ion-text-center">
                <IonButton
                  className="ion-margin-top text-transform-none"
                  expand="block"
                  size="large"
                  onClick={() => {
                    setisFilterActive(true)
                    setModalFilterIsOpen(false);
                  }}
                >
                  Simpan
                </IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
        </IonModal>
        <IonCard className="tourSearchFilterSort" hidden={false} onClick={() => setModalFilterIsOpen(true)}>
          <IonCardContent>
            <IonRow>
              <IonCol size="6" className="ion-text-center">
                <IonIcon icon={filter} color="primary"></IonIcon>
                <IonText color="primary">&nbsp; Urutkan</IonText>
              </IonCol>
              <IonCol size="6" className="ion-text-center" >
                <IonIcon icon={funnel} color="primary"></IonIcon>
                <IonText color="primary">&nbsp; Filter</IonText>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
        <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={headerAlert}
          message={messageAlert}
          buttons={["OK"]}
        />
        <IonAlert
          isOpen={showAlertAirlineAccessCode}
          onDidDismiss={() => setShowAlert(false)}
          header="Masukkan kode verifikasi"
          message={`<img class="w-100" src="data:image/png;base64,${AirlineAccessCode}" alt="g-maps" style="border-radius: 2px">`}
          inputs={[{
            placeholder: 'Kode Verifikasi',
            name: 'accessCode'
          }]}
          buttons={[{
            text:"Lanjutkan",
            cssClass:"button-solid",
            handler: (alertData) => {
              // getAirlineWithAccessCode(alertData.accessCode);
            }
        }]}
        />
      </IonContent>
    </IonPage>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineBooking: selectors.getAirlineBooking(state),
    UserData: selectors.getUserData(state),
    AirlineFlightJourney: state.airline.AirlineFlightJourney,
    AirlineBookingAirlineAccessCode:state.airline.AirlineBookingAirlineAccessCode
  }),
  mapDispatchToProps: { setAirlineFlightDeparture},
  component: React.memo(withRouter(AirlineSearchFirstFlight)),
});
