import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { chevronBackOutline, person } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { AppId, AppName, ContactUsLink, MainUrl } from "../../AppConfig";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import {
  getHistoryTransactionIcon,
  getHistoryTransactionStatusColor,
} from "../../helpers/HistoryTransaction";
import {
  cSharpDateHourConvert,
  stringDateHoursConvert,
} from "../../helpers/datetime";
import { rupiah } from "../../helpers/currency";
import TopupTransactionManage from "../../components/Topup/TopupTransactionManage";

interface OwnProps {}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface TransactionHistoryDetailTopupProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const TransactionHistoryDetailTopup: React.FC<
  TransactionHistoryDetailTopupProps
> = ({ history, UserData }) => {
  const parameters: any = useParams();

  const [TransactionHistoryDetail, setTransactionHistoryDetail] =
    useState<any>(undefined);

  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const getOrderDetail = () => {
    var MyData = new FormData();
    MyData.append("AccToken", UserData.accessToken);
    MyData.append("id_order", parameters.inv.replace(/-/g, "."));
    if (isPlatform("cordova")) {
      HTTP.setDataSerializer("multipart");
      HTTP.post(MainUrl + "Member/OrderDetail", MyData, {
        appid: AppId,
        RequestVerificationToken: UserData.requestVerificationToken,
      })
        .then((res) => {
          if (res.status !== 200) {
            failedAlert("Cek Koneksi Internet Anda");
            // history.push('/transactionHistoryList')
          }
          return JSON.parse(res.data);
        })
        .then((res) => {
          getOrderDetailSuccess(res);
        })
        .catch((e) => {
          failedAlert(e.error);
          // history.push('/transactionHistoryList')
        });
    } else {
      fetch(MainUrl + "Member/OrderDetail", {
        method: "POST",
        body: MyData,
        headers: {
          appid: AppId,
          RequestVerificationToken: UserData.requestVerificationToken,
        },
      })
        // Check Connection
        .then((res) => {
          if (!res.ok) {
            failedAlert("Periksa Koneksi anda");
            // history.push("/transactionHistoryList");
          }
          return res.json();
        })
        .then((res) => {
          getOrderDetailSuccess(res);
        })
        .catch((e) => {
          failedAlert("Data Histori Transaksi tidak ditemukan");
          // history.push("/transactionHistoryList");
        });
    }
  };
  const getOrderDetailSuccess = (res: any) => {
    if (res.StatusCode == 200) {
      console.log(res);
      setTransactionHistoryDetail(res.Data);
    } else {
      failedAlert("Data Histori Transaksi tidak ditemukan");
      // history.push("/transactionHistoryList");
    }
  };
  useEffect(() => {
    setTransactionHistoryDetail(null);
    getOrderDetail();
  },[]);
  const failedAlert = (errorMessage: string) => {
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };
  if (TransactionHistoryDetail) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary" className="">
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/transactionHistoryList"
                icon={chevronBackOutline}
              ></IonBackButton>
            </IonButtons>
            <IonTitle className="ion-no-padding">Detail Pesanan</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="gray-bg">
          <IonGrid className="ion-padding  white-bg">
            <IonRow>
              <IonCol size="5">
                <IonText
                  color={getHistoryTransactionStatusColor(
                    TransactionHistoryDetail.status || "",
                    "ordertopup"
                  )}
                >
                  Status
                </IonText>
              </IonCol>
              <IonCol size="7">
                <IonText
                  color={getHistoryTransactionStatusColor(
                    TransactionHistoryDetail.status || "",
                    "ordertopup"
                  )}
                >
                  : {TransactionHistoryDetail.status || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Kode Transaksi</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.transactionID || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Ref ID</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.refID || ""}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Kode Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.productCode || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Serial Number</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.sn || ""}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Tanggal Transaksi</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  :{" "}
                  {stringDateHoursConvert(
                    TransactionHistoryDetail.orderDate || ""
                  ) || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <div className="bb-lightgray-1 ion-margin"></div>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Provider</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.provider || ""}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Nama Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.productDesc || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Tipe Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {TransactionHistoryDetail.productType || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Status Produk</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.status || ""}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Tanggal Sukses</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  :{" "}
                  {stringDateHoursConvert(
                    TransactionHistoryDetail.successDate || ""
                  ) || ""}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">VSN</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>: {TransactionHistoryDetail.message || ""}</IonText>
              </IonCol>
            </IonRow>
            <div className="bb-lightgray-1 ion-margin"></div>
            <IonRow>
              <IonCol size="5">
                <IonText color="medium">Total Tagihan</IonText>
              </IonCol>
              <IonCol size="7">
                <IonText>
                  : {rupiah(TransactionHistoryDetail.paymentDetail != null && TransactionHistoryDetail.paymentDetail.paymentAmount > 0 ? TransactionHistoryDetail.paymentDetail.paymentAmount : TransactionHistoryDetail.sellPrice || "") || ""}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <TopupTransactionManage
            THD={TransactionHistoryDetail}
          ></TopupTransactionManage>
          <br />
          
          <IonText className="ion-margin">
            <small>
              <b>Hubungi {AppName}</b>
            </small>
          </IonText>
          <IonGrid className="ion-margin-top white-bg ion-padding ion-margin-bottom">
            <IonRow className="ion-align-items-center">
              <IonCol size="12">
                <IonText>
                  <small>
                    Kode Transaksi :{" "}
                    {TransactionHistoryDetail.transactionID || ""}
                  </small>
                </IonText>
              </IonCol>
              <IonCol size="12">
                <p color="medium">
                  <small>
                    Customer service kami akan menanyakan Kode Booking tersebut
                    saat Anda menghubungi kami
                  </small>
                </p>
              </IonCol>
              <IonCol size="12">
                <IonText color="primary">
                  <a href={ContactUsLink}>
                    <b>HUBUNGI KAMI</b>
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="text-transform-none ion-margin"
            size="large"
            expand="block"
            routerLink="/"
          >
            Beli Lagi
          </IonButton>
          <p>
            <br />
          </p>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="alert"
            header={headerAlert}
            message={messageAlert}
            buttons={["OK"]}
          />
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
        <IonContent>
          <div className="loadingData">
            <img src="assets/icon/loading.svg" width="80px" />
            <br />
            Memuat Detail Transaksi
          </div>
        </IonContent>
      </IonPage>
    );
  }
};
export default connect<TransactionHistoryDetailTopupProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {},
  component: withRouter(TransactionHistoryDetailTopup),
});
