import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { chevronBackOutline, person } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import TourDetail from "../../components/TourTransactionHistoryDetail/TourDetail";
import TransactionManage from "../../components/TourTransactionHistoryDetail/TransactionManage";
import DetailCardPelniticket from "../../components/TourTransactionHistoryDetail/DetailCardPelniticket";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { AppId, MainUrl } from "../../AppConfig";
import "./HistoryDetailFlightticket.scss";
import { getHistoryTransactionIcon } from "../../helpers/HistoryTransaction";
import { stringDateHoursConvert } from "../../helpers/datetime";
import { AppName, ContactUsLink } from "../../AppConfig";
interface OwnProps {}
interface StateProps {
  UserData: any;
}
interface DispatchProps {}
interface HistoryDetailFlightticketProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const HistoryDetailFlightticket: React.FC<HistoryDetailFlightticketProps> = ({
  history,
  UserData,
}) => {
  const parameters: any = useParams();

  const [TransactionHistoryDetail, setTransactionHistoryDetail] =
    useState<any>(null);

  const [AdultPaxTotal, setAdultPaxTotal] = useState<number>(0);
  const [ChildPaxTotal, setChildPaxTotal] = useState<number>(0);
  const [InfantPaxTotal, setInfantPaxTotal] = useState<number>(0);
  const [AdultPrice, setAdultPrice] = useState<number>(0);
  const [ChildPrice, setChildPrice] = useState<number>(0);
  const [InfantPrice, setInfantPrice] = useState<number>(0);

  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();



    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary" className="">
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/pelniSearch"
                icon={chevronBackOutline}
              ></IonBackButton>
            </IonButtons>
            <IonTitle className="ion-no-padding">
              <b>
                {/* {AirlineBooking.AirlineBookingOrigin} -{" "}
                {AirlineBooking.AirlineBookingDestination} */}
                {"Tanjung Priok"} -{" "}
                {"Perak"}
              </b>
            </IonTitle>
            <IonTitle className="ion-sub-title ion-no-padding">
              {"No. Pesanan ADASDSA"}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="gray-bg">
          <DetailCardPelniticket
            TransactionHistoryDetail={TransactionHistoryDetail}
            AdultPaxTotal={AdultPaxTotal}
            ChildPaxTotal={ChildPaxTotal}
            InfantPaxTotal={InfantPaxTotal}
            AdultPrice={AdultPrice}
            ChildPrice={ChildPrice}
            InfantPrice={InfantPrice}
          ></DetailCardPelniticket>
          <IonText className="ion-margin">
            <small>
              <b>Data Perjalanan</b>
            </small>
          </IonText>
          <IonGrid className="ion-margin-top white-bg ion-padding ion-margin-bottom">
            <IonRow className="ion-align-items-center">
              <IonCol size="2">
                <img
                  src={getHistoryTransactionIcon("ship")}
                  alt=""
                  width="50%"
                  className="ion-mt-16 ion-mb-16"
                />
              </IonCol>
              <IonCol size="10">
                <IonText>
                  {"Tanjung Priok → Perak"}

                </IonText>
                <br />
                <IonText color="medium">
                  <small>{"KM KELUD | Kam, 14 Jan 2022 10:22"}</small>
                </IonText>

              </IonCol>
            </IonRow>
          </IonGrid>

          <IonText className="ion-margin">
            <small>
              <b>Data Penumpang </b>
            </small>
          </IonText>

            <IonGrid
              className="ion-margin-top white-bg ion-padding ion-margin-bottom"
            >
              <IonRow className="ion-align-items-center">
                <IonCol size="1">
                  <IonIcon icon={person} color="medium"></IonIcon>
                </IonCol>
                <IonCol size="11">
                  <IonText>
                    {"Mr"}. {"Been"}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>


          {/* <IonText
            className="ion-margin"
            hidden={TransactionHistoryDetail.paymentDetai === null}
          >
            <small>
              <b>Pembayaran</b>
            </small>
          </IonText>
          <IonGrid
            className="ion-margin-top white-bg ion-padding ion-margin-bottom"
            hidden={TransactionHistoryDetail.paymentDetai === null}
          >
            <IonRow className="ion-align-items-center">
              <IonCol size="9">
                <IonText>
                  <small>
                    {TransactionHistoryDetail.paymentDetail &&
                      TransactionHistoryDetail.paymentDetail.paymentMethod}{" "}
                    :{" "}
                    {TransactionHistoryDetail.paymentDetail &&
                      TransactionHistoryDetail.paymentDetail.paymentCode}
                  </small>
                </IonText>
              </IonCol>
              <IonCol size="3" className="ion-text-right">
                <CopyToClipboard
                  text={
                    TransactionHistoryDetail.paymentDetail &&
                    TransactionHistoryDetail.paymentDetail.paymentCode
                  }
                  onCopy={() => alert("Berhasil menyalin kode")}
                >
                  <IonText color="primary">
                    <small>Salin Kode</small>
                  </IonText>
                </CopyToClipboard>
              </IonCol>
            </IonRow>
          </IonGrid> */}

          <IonText className="ion-margin">
            <small>
              <b>Hubungi {AppName}</b>
            </small>
          </IonText>
          <IonGrid className="ion-margin-top white-bg ion-padding ion-margin-bottom">
            <IonRow className="ion-align-items-center">
              <IonCol size="12">
                <IonText>
                  <small>
                    Kode Booking {"ASDASD"}
                  </small>
                </IonText>
              </IonCol>
              <IonCol size="12">
                <p color="medium">
                  <small>
                    Customer service kami akan menanyakan Kode Booking tersebut
                    saat Anda menghubungi kami
                  </small>
                </p>
              </IonCol>
              <IonCol size="12">
                <IonText color="primary">
                  <a href={ContactUsLink}>
                    <b>HUBUNGI KAMI</b>
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <p>
            <br />
          </p>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="alert"
            header={headerAlert}
            message={messageAlert}
            buttons={["OK"]}
          />
        </IonContent>
      </IonPage>
    );
   
};
export default connect<HistoryDetailFlightticketProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {},
  component: withRouter(HistoryDetailFlightticket),
});
