import { IonText } from "@ionic/react";
import { Collapse } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  setAirlineBookingDestination,
  setAirlineBookingDestinationDetail,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import { stringDateHoursConvert } from "../../helpers/datetime";

const { Panel } = Collapse;
interface OwnProps {
  Open: boolean;
}
interface StateProps {
  AirlineFlightDeparture?: any;
  AirlineFlightArrival?: any;
  AirlineBookingTripType?: string;
  AirlineBookingOrigin?: string;
  AirlineBookingDestination?: string;
  ABDB?: any;
}
interface DispatchProps {
  setAirlineBookingDestination: typeof setAirlineBookingDestination;
  setAirlineBookingDestinationDetail: typeof setAirlineBookingDestinationDetail;
}
interface AirlineFlightPanelProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const AirlineFlightPanel: React.FC<AirlineFlightPanelProps> = ({
  Open,
  AirlineFlightDeparture,
  AirlineFlightArrival,
  AirlineBookingTripType,
  AirlineBookingOrigin,
  AirlineBookingDestination,
  ABDB,
}) => {
  if (ABDB) {
    return (
      <Collapse
        expandIconPosition={"right"}
        defaultActiveKey={Open ? ["1"] : [""]}
        style={{ margin: "16px", borderRadius: "8px" }}
      >
        <Panel
          // header={
          //   AirlineBookingOrigin || "" + AirlineBookingTripType == "OneWay"
          //     ? " → "
          //     : " ⇆ " + AirlineBookingDestination || ""
          // }
          header={
            `${ABDB.PreBookingData.Origin || ""}` +
            `${ABDB.PreBookingData.TripType === "OneWay" ? " → " : " ⇆ "}` +
            `${ABDB.PreBookingData.Destination || ""}`
          }
          key="1"
        >
          <div className="bb-lightgray-1 ion-pb-8">
            <IonText>
              <p className="ion-no-margin">
                Penerbangan Pergi
                {/* ({ABDB.PreBookingData.Origin || ""} */}
                {/* {AirlineFlightDeparture
                ? AirlineFlightDeparture.jiOrigin || ""
                : ""} */}
                {/* &nbsp;- {ABDB.PreBookingData.Destination || ""}) */}
              </p>
            </IonText>
            {/* {ABDB.AirlineFlightDeparture} */}
            {ABDB &&
            ABDB.AirlineFlightDeparture &&
            ABDB.AirlineFlightDeparture.segment
              ? ABDB.AirlineFlightDeparture.segment.map(
                  (segmentItem, segmentIndex) => (
                    <div key={segmentIndex}>
                      <IonText>
                        <p className="ion-no-margin">
                          <img
                            src={
                              "assets/img/Airlines/" +
                              ABDB.AirlineFlightDeparture.airlineID.toLowerCase() +
                              "/" +
                              segmentItem.flightDetail[0].airlineCode.toLowerCase() +
                              ".png"
                            }
                            alt=""
                            height="16px"
                          />
                          &nbsp;{segmentItem.flightDetail[0].airlineCode}&nbsp;
                          {segmentItem.flightDetail[0].flightNumber} (
                          {segmentItem.flightDetail[0].fdOrigin} {" - "}
                          {segmentItem.flightDetail[0].fdDestination})
                        </p>
                      </IonText>
                      <IonText color="medium">
                        <p className="ion-no-margin">
                          {stringDateHoursConvert(
                            segmentItem.flightDetail[0].fdDepartTime || ""
                          )}
                        </p>
                      </IonText>
                      <IonText color="medium">
                        <p className="ion-no-margin">
                          {stringDateHoursConvert(
                            segmentItem.flightDetail[0].fdArrivalTime || ""
                          )}
                        </p>
                      </IonText>
                    </div>
                  )
                )
              : ""}
          </div>
          <div
            className="ion-pb-8 ion-pt-8"
            hidden={!ABDB.AirlineFlightArrival}
          >
            <IonText>
              <p className="ion-no-margin">
                Penerbangan Pulang
                {/* (
                {(ABDB.AirlineFlightArrival &&
                  ABDB.AirlineFlightArrival.jiOrigin) ||
                  ""}
                &nbsp;-{" "}
                {(ABDB.AirlineFlightArrival &&
                  ABDB.AirlineFlightArrival.jiDestination) ||
                  ""}
                ) */}
              </p>
            </IonText>
            {ABDB &&
            ABDB.AirlineFlightArrival &&
            ABDB.AirlineFlightArrival.segment
              ? ABDB.AirlineFlightArrival.segment.map(
                  (segmentItem, segmentIndex) => (
                    <div key={segmentIndex}>
                      <IonText>
                        <p className="ion-no-margin">
                          <img
                            src={
                              "assets/img/Airlines/" +
                              ABDB.AirlineFlightArrival.airlineID.toLowerCase() +
                              "/" +
                              segmentItem.flightDetail[0].airlineCode.toLowerCase() +
                              ".png"
                            }
                            alt=""
                            height="16px"
                          />
                          &nbsp;{segmentItem.flightDetail[0].airlineCode}&nbsp;
                          {segmentItem.flightDetail[0].flightNumber} (
                          {segmentItem.flightDetail[0].fdOrigin} {" - "}
                          {segmentItem.flightDetail[0].fdDestination})
                        </p>
                      </IonText>
                      <IonText color="medium">
                        <p className="ion-no-margin">
                          {stringDateHoursConvert(
                            segmentItem.flightDetail[0].fdDepartTime || ""
                          )}
                        </p>
                      </IonText>
                      <IonText color="medium">
                        <p className="ion-no-margin">
                          {stringDateHoursConvert(
                            segmentItem.flightDetail[0].fdArrivalTime || ""
                          )}
                        </p>
                      </IonText>
                    </div>
                  )
                )
              : ""}
          </div>
        </Panel>
      </Collapse>
    );
  } else {
    return <div></div>;
  }
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineFlightDeparture: state.airline.AirlineFlightDeparture,
    AirlineFlightArrival: state.airline.AirlineFlightArrival,
    AirlineBookingTripType: state.airline.AirlineBookingTripType,
    AirlineBookingOrigin: state.airline.AirlineBookingOrigin,
    AirlineBookingDestination: state.airline.AirlineBookingDestination,
    ABDB: state.airline.AirlineBookingDataBundle,
  }),
  mapDispatchToProps: {
    setAirlineBookingDestination,
    setAirlineBookingDestinationDetail,
  },
  component: React.memo(withRouter(AirlineFlightPanel)),
});
