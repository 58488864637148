export const getProductName = (p: string) => {
  let r: any = undefined;
  if (p === "PulsaPascaBayar") {
    r = "PULSA PASCABAYAR";
  } else if (p === "TVKabel") {
    r = "TAGIHAN TV (KABEL & SATELIT)";
  } else if (p === "PLN") {
    r = "PLN";
  } else if (p === "Telkom") {
    r = "TELKOM";
  } else if (p === "PDAM") {
    r = "PDAM";
  } else if (p === "PBB") {
    r = "PAJAK DAERAH";
  } else if (p === "BPJS") {
    r = "BPJS";
  } else if (p === "PGN") {
    r = "PGN";
  } else if (p === "Asuransi") {
    r = "ASURANSI";
  } else if (p === "AsuransiKredit") {
    r = "KARTU KREDIT";
  } else if (p === "MultiFinance") {
    r = "MULTI FINANCE";
  }
  return r;
};
export const getProductLabel = (p: string) => {
  let r: any = undefined;
  if (p === "PulsaPascaBayar") {
    r = "Pulsa Pasca Bayar";
  } else if (p === "TVKabel") {
    r = "TV (Kabel & Satelit)";
  } else if (p === "PLN") {
    r = "Listrik";
  } else if (p === "Telkom") {
    r = "Telkom";
  } else if (p === "PDAM") {
    r = "PDAM";
  } else if (p === "PBB") {
    r = "PBB";
  } else if (p === "BPJS") {
    r = "BPJS";
  } else if (p === "PGN") {
    r = "PGN";
  } else if (p === "Asuransi") {
    r = "Asuransi";
  } else if (p === "AsuransiKredit") {
    r = "Angsuran Kredit";
  } else if (p === "MultiFinance") {
    r = "Multi Finance";
  }
  return r;
};
