import { HTTP } from "@ionic-native/http";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import { AppId, MainUrl } from '../../AppConfig';
// import { rupiah } from '../../helpers/currency';
// import { HTTP } from '@ionic-native/http';
import PelniSearchFrom from "../../components/PelniSearch/PelniSearchForm";
import DefaultToolbar from "../../components/shared/DefaultToolbar";
import {
  setAirlineBookingDataBundle,
  setAirlineFlightArrival,
  setAirlineFlightDeparture,
  setAirlineFlightJourney,
  setAirlineOrderPassengersBaggage,
  setAirlineOrderPassengersData,

  setAirlineBookingArrivalDate,
} from "../../data/airline/airline.actions";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { AppId, MainUrl } from "../../AppConfig";
import { stringDateConvertDashSeparate } from "../../helpers/datetime";
import "./Search.scss";

interface OwnProps {}
interface StateProps {
  AirlineBooking: any;
  UserData: any;
}
interface DispatchProps {
  setAirlineFlightJourney: typeof setAirlineFlightJourney;
  setAirlineFlightDeparture: typeof setAirlineFlightDeparture;
  setAirlineFlightArrival: typeof setAirlineFlightArrival;
  setAirlineBookingDataBundle: typeof setAirlineBookingDataBundle;
  setAirlineOrderPassengersBaggage: typeof setAirlineOrderPassengersBaggage;
  setAirlineOrderPassengersData: typeof setAirlineOrderPassengersData;
  setAirlineBookingArrivalDate: typeof setAirlineBookingArrivalDate;
}
interface SearchProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Search: React.FC<SearchProps> = ({
  AirlineBooking,
  UserData,
  history,
  setAirlineFlightJourney,
  setAirlineFlightDeparture,
  setAirlineFlightArrival,
  setAirlineBookingDataBundle,
  setAirlineOrderPassengersBaggage,
  setAirlineOrderPassengersData,
  setAirlineBookingArrivalDate,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();

  const testAlert = (dataMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Data");
    setMessageAlert(dataMessage);
    setShowAlert(true);
  };
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };

  useIonViewDidEnter(() => {
    var datetomorrow = new Date();
    datetomorrow.setDate(datetomorrow.getDate() + 1);
    console.log(AirlineBooking.AirlineBookingArrivalDate);

    setAirlineBookingArrivalDate(datetomorrow);

		// setSearch("");
	});

  const PelniBookingSubmit = () => {
    setAirlineFlightJourney(undefined);
    setAirlineFlightDeparture(undefined);
    setAirlineFlightArrival(undefined);
    setAirlineBookingDataBundle(undefined);
    setAirlineOrderPassengersBaggage(undefined);
    setAirlineOrderPassengersData(undefined);
    localStorage.removeItem("AirlineBaggageTotalPrice");
    localStorage.removeItem("AirlineBookingId");
    localStorage.removeItem("AirlineLastIdOrder");
    localStorage.removeItem("AirlineTransactionID");
    // if (!UserData.accessToken) {



    //   // failedAlert("Login Terlebih Dahulu");
    //   // history.push("/login");
    //   return;
    // }
    setShowLoading(true);
    // var MyHeaders = {
    //   appid: AppId,
    //   RequestVerificationToken: UserData.requestVerificationToken,
    // };
    var MyData = new FormData();
    // MyData.append("airlineAccessCode", "null");
    // MyData.append("airlineDetail", "Semua Maskapai");
    // // MyData.append("airlineFlightClass", "null");
    // MyData.append("airlineID", "ALL");
    MyData.append(
      "departDate",
      stringDateConvertDashSeparate(AirlineBooking.AirlineBookingArrivalDate)
    );
    MyData.append("destination", AirlineBooking.AirlineBookingDestination);
    MyData.append(
      "destinationDetail",
      AirlineBooking.AirlineBookingDestinationDetail
    );
    // MyData.append("origin", AirlineBooking.AirlineBookingOrigin);
    // MyData.append("originDetail", AirlineBooking.AirlineBookingOriginDetail);
    MyData.append("paxAdult", AirlineBooking.AirlineBookingNumberOfAdult);
    // MyData.append("paxChild", AirlineBooking.AirlineBookingNumberOfChild);
    MyData.append("paxInfant", AirlineBooking.AirlineBookingNumberOfInfant);
    // MyData.append("progressPercent", "0");
    // MyData.append("promoCode", "null");
    // MyData.append(
    //   "returnDate",
    //   AirlineBooking.AirlineBookingTripType === "RoundTrip"
    //     ? stringDateConvertDashSeparate(
    //         AirlineBooking.AirlineBookingArrivalDate
    //       )
    //     : "null"
    // );
    // MyData.append("tripType", AirlineBooking.AirlineBookingTripType);
    // MyData.append("accToken", UserData.accessToken);
    setShowLoading(false);
    // testAlert(JSON.stringify(MyData))

    // for (var value of MyData) {
      // testAlert('asdasdasdas')
      for (var value of MyData.values()) {
        // testAlert(value)
        console.log(value);


     }
     history.push("/SearchFirstPelni");
  };
  // const AirlineBookingSubmitSuccess = async (res: any) => {
  //   if (
  //     (res.StatusCode == 200 &&
  //       res.Data &&
  //       res.Data.LowFareJourneyDepart &&
  //       res.Data.LowFareJourneyDepart.length > 0) ||
  //     (AirlineBooking.AirlineBookingTripType === "RoundTrip" &&
  //       res.StatusCode == 200 &&
  //       res.Data &&
  //       res.Data.LowFareJourneyReturn &&
  //       res.Data.LowFareJourneyReturn.length > 0)
  //   ) {
  //     await setAirlineFlightJourney({
  //       Departure: res.Data.LowFareJourneyDepart,
  //       Return: res.Data.LowFareJourneyReturn,
  //     });
  //     localStorage.removeItem("AirlineOrderBaggageSelected");
  //     localStorage.removeItem("AirlineOrderOrderPerson");
  //     setShowLoading(false);
  //     history.push("/airlineSearchFirstFlight");
  //   } else {
  //     failedAlert("Penerbangan tidak ditemukan");
  //   }
  // };
  return (
    <IonPage>
      <IonHeader translucent>
        <DefaultToolbar
          title={"Tiket Kapal Laut"}
          color="primary"
          backButtonRoute="/main/index"
        />
      </IonHeader>
      <IonContent fullscreen={true} class="pelniSearch">
        <IonGrid className="ion-padding">
          <PelniSearchFrom></PelniSearchFrom>
          <IonButton
            className="ion-margin-top text-transform-none"
            expand="block"
            size="large"
            // routerLink="/airlineSearchFirstFlight"
            onClick={() => PelniBookingSubmit()}
          >
            Cari Tiket Kapal Laut
          </IonButton>
        </IonGrid>
        <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={headerAlert}
          message={messageAlert}
          buttons={["OK"]}
        />
      </IonContent>
    </IonPage>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    AirlineBooking: selectors.getAirlineBooking(state),
    UserData: selectors.getUserData(state),
  }),
  mapDispatchToProps: {
    setAirlineFlightJourney,
    setAirlineFlightDeparture,
    setAirlineFlightArrival,
    setAirlineBookingDataBundle,
    setAirlineOrderPassengersBaggage,
    setAirlineOrderPassengersData,

    setAirlineBookingArrivalDate,
  },
  component: React.memo(withRouter(Search)),
});
