import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import loadingLottie from "../../Lotties/loading_4463.json";
import { chevronDown, chevronUp, timeOutline, chevronBackOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { setTourPaymentAllowStatus } from "../../data/tour/tour.actions";
import "./Order.scss";
import PelniWizard from "../../components/Pelni/PelniWizard";
import { Collapse } from "antd";
import { loadAirlineBookingDataBundleData } from "../../data/airline/airline.actions";
import Lottie from "lottie-react";
import { AppId, MainUrl } from "../../AppConfig";
import { HTTP } from "@ionic-native/http";
import { rupiah } from "../../helpers/currency";
import { stringDateConvert } from "../../helpers/datetime";
const { Panel } = Collapse;
interface OwnProps {}
interface StateProps {
  UserData: any;
  ABDB: any;
}
interface DispatchProps {
  loadAirlineBookingDataBundleData: typeof loadAirlineBookingDataBundleData;
}
interface OrderProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const Order: React.FC<OrderProps> = ({
  history,
  UserData,
  ABDB,
  loadAirlineBookingDataBundleData,
}) => {
  const [hiddenDetailPrice, setHiddenDetailPrice] = useState(true);
  const [hiddenDetailPriceChevronUp, setHiddenDetailPriceChevronUp] =
    useState(false);
  const [hiddenDetailPriceChevronDown, setHiddenDetailPriceChevronDown] =
    useState(true);
  const [IdOrder, setIdOrder] = useState(
    localStorage.getItem("AirlineLastIdOrder") || null
  );
  const [dataOrder, setDataOrder] = useState<any>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    setShowLoading(false);
    setHeaderAlert("Gagal");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };

  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/airlineSearch"></IonBackButton>
          </IonButtons>
          <IonTitle>Selesai</IonTitle>
        </IonToolbar>
        <PelniWizard WizardIndex={3}></PelniWizard>
        
      </IonHeader>
      <IonContent fullscreen={true} className="gray-bg">
        <IonGrid
          className="white-bg ion-padding timer"
          
        >
          <IonRow>
            <IonCol size="6">
              <IonText>Kode Booking</IonText>
            </IonCol>
            <IonCol size="6" className="ion-text-right">
              <IonText color="primary">
                {"DASDAS"}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="white-bg ion-padding timer"
          
        >
          <IonRow>
            <IonCol size="6">
              <IonText>Total Harga</IonText>
            </IonCol>
            <IonCol size="6" className="ion-text-right">
              <IonText>
                {rupiah(
                  100000
                )}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* <Lottie animationData={loadingLottie} hidden={dataOrder !== null} /> */}
        <IonButton
          routerLink={
            
               "/transactionHistoryDetailPelniticket" 
               
          }
          expand="block"
          className="text-transform-none ion-margin"
          size="large"
          
        >
          Cek Status Pembayaran
        </IonButton>
        <IonButton
          routerLink="/main/index"
          color="white"
          expand="block"
          className="text-transform-none ion-margin btn-outline-primary"
          size="large"
        >
          Kembali ke Beranda
        </IonButton>
      </IonContent>
      <IonLoading isOpen={showLoading} message={"Mohon Tunggu..."} />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerAlert}
        message={messageAlert}
        buttons={["OK"]}
      />
    </IonPage>
  );
};
export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    UserData: selectors.getUserData(state),
    ABDB: state.airline.AirlineBookingDataBundle,
  }),
  mapDispatchToProps: {
    loadAirlineBookingDataBundleData,
  },
  component: React.memo(withRouter(Order)),
});
