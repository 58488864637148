import { PelniAction } from "./pelni.actions";
import { PelniState } from "./pelni.state";

export const pelniReducer = (
  state: PelniState,
  action: PelniAction
): PelniState => {
  switch (action.type) {
    case "set-pelni-data": {
      return { ...state, ...action.data };
    }
    case "set-pelni-origin-routes": {
      return {
        ...state,
        PelniOriginRoutes: action.PelniOriginRoutes,
      };
    }
    case "set-pelni-booking-destionationlist": {
      return {
        ...state,
        PelniDestinationList: action.PelniDestinationList,
      };
    }
    case "set-pelni-booking-search": {
      return {
        ...state,
        PelniBookingSearch: action.PelniBookingSearch,
      };
    }
    case "set-pelni-booking-schedule-selected": {
      return {
        ...state,
        PelniBookingScheduleSelected: action.PelniBookingScheduleSelected,
      };
    }
    case "set-pelni-booking-fare-selected": {
      return {
        ...state,
        PelniBookingFareSelected: action.PelniBookingFareSelected,
      };
    }
    case "set-pelni-booking-available-data": {
      return {
        ...state,
        PelniBookingAvailableData: action.PelniBookingAvailableData,
      };
    }
    case "set-pelni-booking-pax-deck": {
      return {
        ...state,
        PelniBookingPaxDeck: action.PelniBookingPaxDeck,
      };
    }
    case "set-pelni-booking-info": {
      return {
        ...state,
        PelniBookingInfo: action.PelniBookingInfo,
      };
    }
  }
};
