import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonRippleEffect,
  IonModal,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonDatetime,
  IonButton,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonAlert,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import { chevronForward, person } from "ionicons/icons";
import { close, chevronBackOutline } from "ionicons/icons";
import {
  loadAirlineOrderPassengersData,
  setAirlineOrderPassengersData,
} from "../../data/airline/airline.actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
interface OwnProps {
  indexItem: number;
  // item: any;
  // AOPD?: any;
  // PassengerTitle?: any;
  // UseLionOrBatik: boolean;
  // UseGaruda: boolean;
  // Nationalities: any;
  // AddOnChecked: boolean;
}
interface StateProps {}
interface DispatchProps {
  setAirlineOrderPassengersData: typeof setAirlineOrderPassengersData;
  loadAirlineOrderPassengersData: typeof loadAirlineOrderPassengersData;
}
interface PelniOrderBuyerDataItemProps
  extends OwnProps,

    StateProps,
    DispatchProps,
    RouteComponentProps {}
const PelniOrderBuyerDataItem: React.FC<PelniOrderBuyerDataItemProps> = ({
  indexItem,
  // item,
  // AddOnChecked,
  // UseLionOrBatik,
  // UseGaruda,
  // PassengerTitle,
  // setAirlineOrderPassengersData,
  // loadAirlineOrderPassengersData,
  // AOPD,
  // Nationalities,
}) => {
  const dateNow = new Date();
  const [modal, setModal] = useState(false);
  // const [PaxBatikMilesNo, setPaxBatikMilesNo] = useState(item.PaxBatikMilesNo);
  const [PaxBirthCountry, setPaxBirthCountry] = useState("ID");
  // const [PaxBirthDate, setPaxBirthDate] = useState(
  //   item.PaxBirthDate || item.PaxType === "Infant"
  //     ? new Date(
  //         dateNow.getFullYear(),
  //         dateNow.getMonth() - 4,
  //         dateNow.getDate()
  //       ).toISOString()
  //     : item.PaxType === "Child"
  //     ? new Date(
  //         dateNow.getFullYear() - 2,
  //         dateNow.getMonth(),
  //         dateNow.getDate()
  //       ).toISOString()
  //     : new Date(
  //         dateNow.getFullYear() - 12,
  //         dateNow.getMonth(),
  //         dateNow.getDate()
  //       ).toISOString()
  // );
  // const [PaxFirstName, setPaxFirstName] = useState(item.PaxFirstName);
  // const [PaxGender, setPaxGender] = useState(item.PaxGender);
  // const [PaxLastName, setPaxLastName] = useState(item.PaxLastName);
  const [PaxNationality, setPaxNationality] = useState("ID");
  // const [PaxDoctype, setPaxDoctype] = useState(item.PaxDoctype);
  // const [PaxPassportExpiredDate, setPaxPassportExpiredDate] = useState(
  //   item.PaxPassportExpiredDate
  // );
  // const [PaxPassportIssuedCountry, setPaxPassportIssuedCountry] =
  //   useState("ID");
  // const [PaxPassportIssuedDate, setPaxPassportIssuedDate] = useState(
  //   item.PaxPassportIssuedDate
  // );
  // const [PaxPassportNumber, setPaxPassportNumber] = useState(
  //   item.PaxPassportNumber
  // );
  // const [PaxGarudaFrequentFlyer, setPaxGarudaFrequentFlyer] = useState(
  //   item.PaxGarudaFrequentFlyer
  // );
  // const [PaxParent, setPaxParent] = useState(item.PaxParent);
  // const [PaxTitle, setPaxTitle] = useState(item.PaxTitle);
  // const [CountryList, setCountryList] = useState<any>(null);
  // useIonViewDidEnter(() => {
  //   setPaxBatikMilesNo(item.PaxBatikMilesNo);
  //   setPaxBirthCountry(item.PaxBirthCountry);
  //   setPaxBirthDate(item.PaxBirthDate);
  //   setPaxFirstName(item.PaxFirstName);
  //   setPaxGender(item.PaxGender);
  //   setPaxLastName(item.PaxLastName);
  //   setPaxNationality(item.PaxNationality);
  //   setPaxParent(item.PaxParent);
  //   setPaxDoctype(item.PaxDoctype);
  //   setPaxPassportExpiredDate(item.PaxPassportExpiredDate);
  //   setPaxPassportIssuedCountry(item.PaxPassportIssuedCountry);
  //   setPaxPassportIssuedDate(item.PaxPassportIssuedDate);
  //   setPaxPassportNumber(item.PaxPassportNumber);
  //   setPaxGarudaFrequentFlyer(item.PaxGarudaFrequentFlyer);
  //   setPaxTitle(item.PaxTitle);
  // });

  const PaxDataSubmit = () => {
    // if (!PaxTitle || PaxTitle === "") {
    //   failedAlert("Titel Harus Dipilih");
    //   return;
    // }
    // if (!PaxFirstName || PaxFirstName === "") {
    //   failedAlert("Nama Depan Wajib Diisi");
    //   return;
    // }
    // if (!PaxLastName || PaxLastName === "") {
    //   failedAlert("Nama Belakang Wajib Diisi");
    //   return;
    // }
    // if (!PaxNationality || PaxNationality === "") {
    //   failedAlert("Kewarganegaraan Wajib dipilih");
    //   return;
    // }
    // if (!PaxBirthDate || PaxBirthDate === "") {
    //   failedAlert("Tanggal Lahir Wajib Diisi");
    //   return;
    // }
    // if (!PaxBirthCountry || PaxBirthCountry === "") {
    //   failedAlert("Negara Kelahiran Wajib Diisi");
    //   return;
    // }
    // if (!PaxGender || PaxGender === "") {
    //   failedAlert("Jenis Kelamin Wajib dipilih");
    //   return;
    // }
    // if (item.PaxType === "Infant" && PaxParent === null) {
    //   failedAlert("Penumpang bayi wajib memilih orang tua");
    //   return;
    // }
    // if (UseLionOrBatik && PaxPassportNumber === "") {
    //   failedAlert(PaxDoctype + " Wajib Diisi");
    //   return;
    // }
    // if (
    //   UseLionOrBatik &&
    //   PaxDoctype === "KTP" &&
    //   PaxPassportNumber.length !== 16
    // ) {
    //   failedAlert("Nomor KTP wajib 16 digit");
    //   return;
    // }
    // if (
    //   UseLionOrBatik &&
    //   PaxDoctype === "Passport" &&
    //   PaxPassportNumber.length !== 7
    // ) {
    //   failedAlert("Nomor Passport wajib 7 digit");
    //   return;
    // }

    // if (
    //   UseLionOrBatik &&
    //   PaxDoctype === "Passport" &&
    //   PaxPassportIssuedDate === ""
    // ) {
    //   failedAlert("Tanggal Dikeluarkan Passport Wajib dipilih");
    //   return;
    // }
    // if (
    //   UseLionOrBatik &&
    //   PaxDoctype === "Passport" &&
    //   PaxPassportIssuedCountry === ""
    // ) {
    //   failedAlert("Negara Penerbit Passport Wajib dipilih");
    //   return;
    // }
    // if (
    //   UseLionOrBatik &&
    //   PaxDoctype === "Passport" &&
    //   PaxPassportExpiredDate === ""
    // ) {
    //   failedAlert("Tanggal Habis Berlaku Passport Wajib dipilih");
    //   return;
    // }
    // PaxDataSaveToLocal();
    setModal(false);
  };

  const closeModal = () => {
    // setPaxBatikMilesNo(item.PaxBatikMilesNo);
    // setPaxBirthCountry(item.PaxBirthCountry);
    // setPaxBirthDate(item.PaxBirthDate);
    // setPaxFirstName(item.PaxFirstName);
    // setPaxGender(item.PaxGender);
    // setPaxLastName(item.PaxLastName);
    // setPaxNationality(item.PaxNationality);
    // setPaxDoctype(item.PaxDoctype);
    // setPaxPassportExpiredDate(item.PaxPassportExpiredDate);
    // setPaxPassportIssuedCountry(item.PaxPassportIssuedCountry);
    // setPaxPassportIssuedDate(item.PaxPassportIssuedDate);
    // setPaxPassportNumber(item.PaxPassportNumber);
    // setPaxGarudaFrequentFlyer(item.PaxGarudaFrequentFlyer);
    // setPaxTitle(item.PaxTitle);
    setModal(false);
  };
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [headerAlert, setHeaderAlert] = useState<string>();
  const [messageAlert, setMessageAlert] = useState<string>();
  const failedAlert = (errorMessage: string) => {
    // setShowLoading(false);
    setHeaderAlert("Ups! ada yang kurang");
    setMessageAlert(errorMessage);
    setShowAlert(true);
  };

  // console.log(PassengerTitle[item.PaxType.split(" ")[0] || "Adult"]);
  // console.log(AOPD);
  const AutoFillOrderPerson = (AOOP) => {
    if (AOOP.OrderPersonMethod === "0") {
      // setPaxTitle(AOOP.OrderPersonTitel);
      // setPaxFirstName(AOOP.OrderPersonFirstName);
      // setPaxLastName(AOOP.OrderPersonLastName);
      const Gender =
        AOOP.OrderPersonTitel === "MR"
          ? "Male"
          : AOOP.OrderPersonTitel === "MS"
          ? "Female"
          : AOOP.OrderPersonTitel === "MISS"
          ? "Female"
          : AOOP.OrderPersonTitel === "MSTR"
          ? "Male"
          : "Male";
      // setPaxGender(Gender);
    } else {
      // setPaxTitle("");
      // setPaxFirstName("");
      // setPaxLastName("");
      // setPaxGender("Male");
    }
  };
  return (
    <div className="ion-no-padding">
      <IonCard
        className="ion-activatable ripple-parent ion-margin-bottom"
        onClick={() => {setModal(true)}}
      >
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="10">
                <IonIcon icon={person} className="ion-margin-end"></IonIcon>
                <IonText >
                  Penumpang Dewasa {indexItem}
                </IonText>
                {/* <IonText hidden={AOPD[indexItem].PaxFirstName === ""}>
                  {AOPD[indexItem].PaxFirstName}
                </IonText> */}
                <IonText color="danger">*</IonText>
              </IonCol>
              <IonCol className="ion-text-right">
                <IonIcon icon={chevronForward} color="primary"></IonIcon>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonRippleEffect></IonRippleEffect>
        </IonCardContent>
      </IonCard>
      {/* Modal Order Passengers */}
      <IonModal isOpen={modal}>
        <IonContent>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={() => closeModal()}>
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
            Data Pesanan {indexItem}
          </IonToolbar>
          <IonGrid>
            <IonRow>
              {/* <IonCol size="12">
                <IonItem>
                  <IonLabel position="floating">
                    <small>Nama Pemesan</small>
                  </IonLabel>
                  <IonInput
                    // value={PaxFirstName}
                    // onIonChange={(e) => setPaxFirstName(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol> */}
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="floating">
                    <small>Nama Pemesan</small>
                  </IonLabel>
                  <IonInput
                    // value={PaxFirstName}
                    // onIonChange={(e) => setPaxFirstName(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">
                    <small>Alamat Pemesan</small>
                  </IonLabel>
                  <IonInput
                    // value={PaxFirstName}
                    // onIonChange={(e) => setPaxFirstName(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem>
                  <IonLabel position="stacked">
                    <small>Kode Negara</small>
                  </IonLabel>
                  <IonInput
                    // value={OrderPersonNationCode}
                    // onIonChange={(e) =>
                    //   setOrderPersonNationCode(e.detail.value!)
                    // }
                  >+62</IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="8">
                <IonItem>
                  <IonLabel position="floating">
                    <small>No. Handphone</small>
                  </IonLabel>
                  <IonInput
                    type="number"
                    // value={OrderPersonPhoneNumber}
                    // onIonChange={(e) =>
                    //   setOrderPersonPhoneNumber(e.detail.value!)
                    // }
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonButton
                  className="text-transform-none"
                  expand="block"
                  // hidden={AddOnChecked}
                  onClick={() => PaxDataSubmit()}
                >
                  Simpan
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={headerAlert}
        message={messageAlert}
        buttons={["OK"]}
      />
    </div>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  // mapStateToProps: (state) => ({
  //   ABDB: state.airline.AirlineBookingDataBundle,
  //   AOPD: state.airline.AirlineOrderPassengersData,
  //   UserData: selectors.getUserData(state),
  // }),
  mapDispatchToProps: {
    setAirlineOrderPassengersData,
    loadAirlineOrderPassengersData,
  },
  component: React.memo(withRouter(PelniOrderBuyerDataItem)),
});
