import {  getNewTourProductList,getNews, getNewEcommerceProductList, setpairedPrintDeviceData } from '../dataApi';
import { ActionType } from '../../util/types';
import { AppState } from './app.state';
export const loadNewsData = () => async (dispatch: React.Dispatch<object>) => {
  const data = await getNews();
  dispatch(setData(data));
}
export const loadNewTourProdctListData = () => async (dispatch: React.Dispatch<object>) => {
  const data = await getNewTourProductList();
  dispatch(setData(data));
}
export const loadNewEcommerceProdctListData = () => async (dispatch: React.Dispatch<object>) => {
  const data = await getNewEcommerceProductList();
  // console.log(data);
  // console.log('asdasdasdasdasdasd');

  dispatch(setData(data));
}
export const setData = (data: Partial<AppState>) => ({
  type: 'set-conf-data',
  data
} as const);

export const setpairedPrintDevice =
  (pairedPrintDevice?: string) => async (dispatch: React.Dispatch<string>) => {
    await setpairedPrintDeviceData(pairedPrintDevice);
    return {
      type: "set-pairedprintdevice",
      pairedPrintDevice,
    } as const;
  };

export type AppActions =
  | ActionType<typeof setpairedPrintDevice>
  | ActionType<typeof setData>
