import { IonCard, IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import { setTourPaymentAllowStatus } from "../../data/tour/tour.actions";
import { rupiah } from "../../helpers/currency";
import { stringDateHoursConvert } from "../../helpers/datetime";
import {
  getHistoryTransactionStatusColor,
  getHistoryTransactionStatusName,
} from "../../helpers/HistoryTransaction";
interface OwnProps {
  TransactionHistoryDetail: any;
  AdultPaxTotal: number;
  ChildPaxTotal: number;
  InfantPaxTotal: number;
  AdultPrice: number;
  ChildPrice: number;
  InfantPrice: number;
}
interface StateProps {}
interface DispatchProps {
  setTourPaymentAllowStatus: typeof setTourPaymentAllowStatus;
}
interface DetailCardFlightticketProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps {}
const DetailCardFlightticket: React.FC<DetailCardFlightticketProps> = ({
  history,
  setTourPaymentAllowStatus,
  TransactionHistoryDetail,
  AdultPaxTotal,
  ChildPaxTotal,
  InfantPaxTotal,
  AdultPrice,
  ChildPrice,
  InfantPrice,
}) => {
  const [hiddenCollapse, setHiddenCollapse] = useState<boolean>(true);
  const [iconCollapse, setIconCollapse] = useState<string>(chevronDown);
  const [RepaymentButtonText, setRepaymentButtonText] = useState<string>(
    "Lanjutkan Pelunasan"
  );
  const [RepaymentButtonDisableStatus, setRepaymentButtonDisableStatus] =
    useState<boolean>(false);
  const toggleCollapse = () => {
    if (hiddenCollapse === false) {
      setHiddenCollapse(true);
      setIconCollapse(chevronDown);
    } else {
      setHiddenCollapse(false);
      setIconCollapse(chevronUp);
    }
  };
  const Repayment = () => {
    setTourPaymentAllowStatus(true);
    setRepaymentButtonText("Menuju halaman pelunasan...");
    setRepaymentButtonDisableStatus(true);
    setTimeout(() => {
      localStorage.setItem("RepaymentStatus", "1");
      localStorage.setItem(
        "TourOrderBookingCode",
        TransactionHistoryDetail.TourBookingCode
      );
      setRepaymentButtonText("Lanjutkan Pelunasan");
      setRepaymentButtonDisableStatus(false);
      history.push("/tourPayment");
    }, 2000);
  };
  return (
    <IonCard className="ion-p-8 ion-margin-bottom ion-margin">
      <IonGrid>
        <IonRow className="ion-mb-8">
          <IonCol>
            <IonText
              color="success"
            >
              Status:{" "}
              Issued
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark">
              <small>Kode Booking</small>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="dark">
              <small>ASDASDAS</small>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark">
              <small>Tanggal Transaksi</small>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="dark">
              <small>
                13 Januari 2022
              </small>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark">
              <small>Batas Waktu Pembayaran</small>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="dark">
              <small>
                14 Januari 2022 15:00
              </small>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark">
              <small>Batas Waktu Kapal Laut</small>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="dark">
              <small>
                14 Januari 2022 15:00
              </small>
            </IonText>
          </IonCol>
        </IonRow>
        {/* <IonRow className="ion-mb-8">
          <IonCol>
            <IonText color="medium">Metode pelunasan</IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="medium">
              Bayar {TransactionHistoryDetail.payment_type}
            </IonText>
          </IonCol>
        </IonRow> */}
        <IonRow
        // onClick={() => toggleCollapse()}
        >
          <IonCol>
            <IonText color="dark">
              <small>Total pembelian</small>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <IonText color="dark">
              <small>
                {rupiah(1000000)
                }
              </small>
            </IonText>
            {/* <IonIcon
              icon={iconCollapse}
              color="primary"
              className="ion-margin-start"
            ></IonIcon> */}
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* <IonGrid hidden={hiddenCollapse} className="bt-lightgray-1">
        {TransactionHistoryDetail.Passengers !== null
          ? TransactionHistoryDetail.Passengers.map((item, index) => (
              <IonRow key={index}>
                <IonCol>
                  <IonRow className="ion-mb-8">
                    <IonCol>
                      <IonText color="medium">
                        {item.Title + " " + item.Name}
                      </IonText>
                    </IonCol>
                    <IonCol className="ion-text-right">
                      <IonText color="medium">
                   
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            ))
          : ""}


      </IonGrid> */}

    </IonCard>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setTourPaymentAllowStatus,
  },
  component: React.memo(withRouter(DetailCardFlightticket)),
});
